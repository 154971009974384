.services {

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;

    @include small-tablet {
      gap: 12px;
    }

    &-item {
      grid-column: 6 span;

      &--small {
        grid-column: 3 span;
      }

      @include ser-frst {

        &--1 {
          grid-column: 4 span;
        }

        &--2 {
          grid-column: 8 span;
        }

        &--3 {
          grid-column: 4 span;
          order: 10;
        }

        &--4 {
          grid-column: 12 span;
        }

        &--5 {
          grid-column: 8 span;
        }
      }

      @include small-tablet {
        &--1 {
          grid-column: 6 span;
          order: 1;
        }

        &--2 {
          grid-column: 12 span;
          order: 3;

        }

        &--3 {
          grid-column: 6 span;
          order: 2;
        }

        &--4 {
          grid-column: 12 span;
          order: 4;
        }

        &--5 {
          grid-column: 12 span;
          order: 5;
        }
      }

      &--tail {
        position: relative;
        z-index: 10;
        transition: animation 1s ease-in-out;

        @include hover {

          & .services__card-search {
            transform: scale(1.1) rotate(7deg);
          }

          & .services__card-display {
            transform: scale(1.1) rotate(-7deg);
          }

          & .services__card-fox-tail-main {
            animation: tail-shaking 1s ease infinite;
          }
        }
      }
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 35px;
    // padding: 11%;
    height: 100%;

    @include mini-mobile {
      padding: 15px;
    }

    &--rocket {

      @include hover {

        & .services__card-rocket {
          animation: rocket-shaking .5s ease infinite;
        }

        & .services__card-fire {
          animation: fire-shaking .5s ease infinite;
        }
      }
    }

    &--tail {
      backdrop-filter: blur(80px);
    }

    &--accom {

      @include hover {

        & .services__card-services-1 {
          animation: frst 3s ease infinite;
        }

        & .services__card-services-2 {
          animation: scnd 3s ease infinite;
        }

        & .services__card-services-3 {
          animation: thrd 3s ease infinite;
        }
      }
    }

    &--p0 {
      // padding-right: 0;
      padding-bottom: 20px;
      overflow: hidden;

      @include hover {

        & .services__card-direct {
          animation: move 10s ease infinite;
        }
      }
    }

    &-title {
      margin-bottom: 10px;
      font-weight: 500;
      // font-size: 64px;
      // font-size: clamp(1.75rem, 1.107rem + 3.214vw, 4rem);
      font-size: clamp(1.5rem, 0.786rem + 3.571vw, 4rem);
      letter-spacing: 0.05em;
      color: var(--fd-orange);

      &--small {
        font-weight: 400;
        // font-size: 36px;
        // font-size: clamp(1.375rem, 1.125rem + 1.25vw, 2.25rem);
        font-size: clamp(1.125rem, 0.804rem + 1.607vw, 2.25rem);
        letter-spacing: 0.05em;
        color: var(--fd-pale);
      }
    }

    &-picture {

      &-search {
        display: flex;
        justify-content: flex-end;
      }
    }

    &-img {

      &-wrap {
        display: flex;
        justify-content: center;
      }
    }



    &-rocket {
      transform: rotate(-45deg);

      &-wrap {
        position: absolute;
        bottom: 0%;
        right: 13%;

        @include small-tablet {
          max-width: 240px;
        }
      }
    }

    &-fire {
      position: absolute;
      bottom: -67%;
      right: -59%;
      transform: rotate(-45deg) scale(0.3);
      z-index: -1;
      opacity: 0;
    }

    &-direct {
      position: relative;
      z-index: -1;
    }

    &-fox-tail-main {
      position: absolute;
      top: 63%;
      right: 8%;
      z-index: -1;
      transform: rotate(42deg);

      @include small-tablet {
        max-width: 190px;
      }

      @include small-mobile {
        max-width: 140px;
      }

      @include mini-mobile {
        max-width: 115px;
      }

      @include last {
        max-width: 105px;
      }
    }

    &-search {
      transition: transform .3s ease-in-out;

      @include mini-mobile {
        position: relative;
        max-width: 130px;
        right: -5%;
      }
    }

    &-display {
      position: absolute;
      bottom: 6%;
      left: 9%;
      transition: transform .3s ease-in-out;

      @include mini-mobile {
        bottom: -2%;
        left: 2%;
      }
    }

    &-services-1 {
      position: relative;
      left: 30px;
    }

    &-services-2 {
      position: relative;
    }

    &-services-3 {
      position: relative;
      left: -30px;
    }

    &-descr {
      display: flex;
      align-items: center;
      font-weight: 400;
      // font-size: 36px;
      font-size: clamp(1.375rem, 1.125rem + 1.25vw, 2.25rem);
      letter-spacing: 0.05em;
      color: var(--fd-pale);

      @include mini-mobile {
        flex-wrap: wrap;
      }
    }

    &-text {
      display: flex;
      align-items: center;
      padding-left: 5px;
      font-weight: 400;
      // font-size: 36px;
      font-size: clamp(1.375rem, 1.125rem + 1.25vw, 2.25rem);
      letter-spacing: 0.05em;
      color: var(--fd-pale);

      &--orange {
        font-weight: 500;
        // font-size: 73px;
        font-size: clamp(2.313rem, 1.67rem + 3.214vw, 4.563rem);
        letter-spacing: 0.05em;
        color: var(--fd-orange);
      }
    }

    &-btn {
      position: relative;
      bottom: -4%;
      padding: 15px 30px;
      align-self: flex-end;
      font-family: var(--font-family);
      font-weight: 500;
      // font-size: 48px;
      font-size: clamp(1.5rem, 1.071rem + 2.143vw, 3rem);
      letter-spacing: 0.05em;
      color: var(--fd-orange);
      border: 1px solid var(--fd-pale-orange);
      border-radius: 25px;
      box-shadow: inset 5px 5px 13px 0 rgba(72, 72, 72, 0.75), 8px 8px 8px 0 rgba(0, 0, 0, 0.75);
      transform: rotate(5deg);
      transition: transform .2s ease-in-out;

      @include hover {
        box-shadow: inset 5px 5px 13px 0 rgba(72, 72, 72, 0.75), 8px 8px 8px 0 rgba(255, 151, 55, 1);
        transform: rotate(0deg);
      }

      @include last {
        padding: 10px 20px;
        bottom: -2%;
      }
    }

    &-list {
      display: flex;
      // flex-wrap: wrap;
      justify-content: center;
      gap: 24px;

      &--1 {
        margin-bottom: 20px;
      }

      &-item {
        position: relative;

        &--1 {
          position: relative;
          z-index: 1;

          @include hover {
            & .services__card-ya1-2 {
              opacity: 1;
              z-index: 2;
            }
          }
        }

        &--2 {
          position: relative;
          z-index: 1;

          @include hover {
            & .services__card-ya2-2 {
              opacity: 1;
              z-index: 2;
            }
          }
        }

        &--3 {
          position: relative;
          z-index: 1;

          @include hover {
            & .services__card-ya3-2 {
              opacity: 1;
              z-index: 2;
            }
          }
        }

        &--4 {
          position: relative;
          z-index: 1;

          @include hover {
            & .services__card-ya4-2 {
              opacity: 1;
              z-index: 2;
            }
          }
        }

        &--5 {
          position: relative;
          z-index: 1;

          @include hover {
            & .services__card-ya5-2 {
              opacity: 1;
              z-index: 2;
            }
          }
        }

        &--6 {
          position: relative;
          z-index: 1;

          @include hover {
            & .services__card-ya6-2 {
              opacity: 1;
              z-index: 2;
            }
          }
        }

        &--7 {
          position: relative;
          z-index: 1;

          @include hover {
            & .services__card-ya7-2 {
              opacity: 1;
              z-index: 2;
            }
          }
        }

        &--8 {
          position: relative;
          z-index: 1;

          @include hover {
            & .services__card-ya8-2 {
              opacity: 1;
              z-index: 2;
            }
          }
        }
      }
    }

    &-ya1-2,
    &-ya2-2,
    &-ya3-2,
    &-ya4-2,
    &-ya5-2,
    &-ya6-2,
    &-ya7-2,
    &-ya8-2 {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      box-shadow: -6px 6px 14px 0 var(--fd-orange);
      box-shadow: 0px 0px 14px 8px var(--fd-orange);
      border-radius: 25px;
      z-index: -1;
      transition: opacity .3s ease-in-out;
    }

  }
}

@keyframes rocket-shaking {
  0% {
    transform: translate(0, 0) rotate(-45deg);
  }

  25% {
    transform: translate(-5px, -5px) rotate(-47deg);
  }

  50% {
    transform: translate(-10px, -10px) rotate(-45deg);
  }

  75% {
    transform: translate(-5px, -5px) rotate(-43deg);
  }

  100% {
    transform: translate(0, 0) rotate(-45deg);
  }
}

@keyframes fire-shaking {
  0% {
    opacity: 0.8;
    transform: translate(0, 0) rotate(-43deg) scale(0.3);
  }

  25% {
    opacity: 1;
    transform: translate(-5px, -5px) rotate(-41deg) scale(0.25);
  }

  50% {
    opacity: .7;
    transform: translate(-10px, -10px) rotate(-43deg) scale(0.3);
  }

  75% {
    opacity: 1;
    transform: translate(-5px, -5px) rotate(-41deg) scale(0.25);
  }

  100% {
    opacity: .9;
    transform: translate(0, 0) rotate(-43deg) scale(0.3);
  }
}

@keyframes tail-shaking {
  0% {
    transform: rotate(42deg) translateY(2.5px);
  }

  12% {
    transform: rotate(46deg) translateY(2.5px);
  }

  25% {
    transform: rotate(50deg) translateY(2.5px);
  }

  37% {
    transform: rotate(54deg) translateY(2.5px);
  }

  50% {
    transform: rotate(58deg) translateY(2.5px);
  }

  62% {
    transform: rotate(54deg) translateY(2.5px);
  }

  75% {
    transform: rotate(50deg) translateY(2.5px);
  }

  88% {
    transform: rotate(46deg) translateY(2.5px);
  }

  100% {
    transform: rotate(42deg) translateY(2.5px);
  }
}

@keyframes frst {
  25% {
    transform: rotate(-5deg) translateY(5px) scale(1.1);
    // z-index: 2;
  }
}

@keyframes scnd {
  50% {
    transform: rotate(3deg) translateY(5px) scale(1.1);
    // z-index: 2;
  }
}

@keyframes thrd {
  75% {
    transform: rotate(-10deg) translateY(5px) scale(1.1);
    // z-index: 2;
  }
}

@keyframes move {
  0% {
    transform-origin: bottom left;
    transform: scale(1.0);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1.0);
  }
}
