.cases-big {


  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;

    @include semi-tablet {
      gap: 12px;
    }

    &-item {
      border-radius: 25px;
      overflow: hidden;

      @include small-tablet {
        border-radius: 25px;
      }

      &--horizontal {
        grid-column: 12 span;
      }

      &--normal {
        grid-column: 6 span;

        &:nth-child(6),
        &:nth-child(7) {
          @include semi-tablet {
            grid-column: 12 span;
          }
        }

        @include mobile {
          grid-column: 12 span;
        }
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;

    @include frst {
      padding-bottom: 0;
    }

    &--pb0 {
      padding-bottom: 0;
    }

    &--1 {
      background-image: image-set(url('/img/cases-bg-1.webp') 1x,
          url('/img/cases-bg-1_2x.webp') 2x);
    }

    &--2 {
      background-image: image-set(url('/img/cases-bg-2.webp') 1x,
          url('/img/cases-bg-2_2x.webp') 2x);
    }

    &--3 {
      background-image: image-set(url('/img/cases-bg-3.webp') 1x,
          url('/img/cases-bg-3_2x.webp') 2x);
    }

    &--4 {
      background-image: image-set(url('/img/cases-bg-4.webp') 1x,
          url('/img/cases-bg-4_2x.webp') 2x);
    }

    &--5 {
      background-image: image-set(url('/img/cases-bg-5.webp') 1x,
          url('/img/cases-bg-5_2x.webp') 2x);
    }

    &--6 {
      background-image: image-set(url('/img/cases-bg-6.webp') 1x,
          url('/img/cases-bg-6_2x.webp') 2x);
    }

    &--7 {
      background-image: image-set(url('/img/cases-bg-7.webp') 1x,
          url('/img/cases-bg-7_2x.webp') 2x);
    }

    &--8 {
      background-image: image-set(url('/img/cases-bg-8.webp') 1x,
          url('/img/cases-bg-8_2x.webp') 2x);
    }

    &--9 {
      background-image: image-set(url('/img/cases-bg-9.webp') 1x,
          url('/img/cases-bg-9_2x.webp') 2x);
    }

    &-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: auto;
      padding: 60px;
      width: 100%;

      @include tablet {
        padding: 40px;
      }

      @include mini-tablet {
        // flex-wrap: wrap;
        padding: 30px 25px;
      }
    }

    &-title {
      font-weight: 600;
      // font-size: 36px;
      align-self: center;
      font-size: clamp(1.25rem, 0.536rem + 1.905vw, 2.25rem);
      color: var(--fd-orange);
    }

    &-subtitle {
      font-weight: 400;
      // font-size: 48px;
      font-size: clamp(1.5rem, 0.429rem + 2.857vw, 3rem);
      color: var(--fd-orange);
    }

    &-list {
      display: flex;
      flex-direction: column;
      align-items: start;

      // @include mini-tablet {
      //   width: 100%;
      //   padding-top: 15px;
      // }

      &-item {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    &-text {
      padding: 10px 20px;
      border: 1px solid var(--fd-pale-orange);
      border-radius: 30px;
      background-color: var(--fd-graphite);

      @include mini-tablet {
        padding: 10px 15px;
        font-size: 14px;
      }

      @include mini-mobile {
        padding: 7px 10px;
      }
    }

    &-img {
      vertical-align: top;
    }
  }
}
