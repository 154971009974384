.work-stages {

  &__text {
    margin: 0;
    margin-bottom: 80px;
    width: 80%;
    font-weight: 500;
    // font-size: 24px;
    font-size: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
    letter-spacing: 0.05em;
    color: var(--white);

    & span {
      display: inline-block;
      margin-bottom: 15px;
    }

    @include mobile {
      margin-bottom: 40px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;

    @include small-tablet {
      gap: 15px;
    }

    @include small-mobile {
      gap: 10px;
    }

    &-item {
      grid-column: 4 span;

      @include small-tablet {
        grid-column: 6 span;
      }

      @include mini-mobile {
        grid-column: 12 span;
      }

      &--invisible {

        @include small-tablet {
          position: absolute;
          overflow: hidden;
          margin: -1px;
          border: 0;
          padding: 0;
          width: 1px;
          height: 1px;
          clip: rect(0 0 0 0);
        }
      }
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    // padding: 11.2% 10%;
    height: 100%;
    border: 1px solid var(--fd-pale-orange);
    border-radius: 25px;
    background-size: 30%;
    background-position: 7% 94%;
    background-repeat: no-repeat;
    overflow: hidden;
    backdrop-filter: blur(3px);
    transition: transform .3s ease-in-out;

    @include semi-tablet {
      border-radius: 25px;
    }

    @include mini-mobile {
      background-size: 19%;
    }

    &--invisible {
      backdrop-filter: none;
    }

    @include hover {


      &:not(.work-stages__card--invisible) {
        transform: scale(1.05);
        box-shadow: -1px 1px 19px 4px rgba(255, 151, 55, 1);
      }

      &>.work-stages__card-svg {
        animation: rotate 4s linear infinite;
      }
    }

    &-svg {
      position: absolute;
      right: -5%;
      bottom: -7%;

      @include tablet {
        width: 100%;
        max-width: 200px;
        height: auto;
      }
    }

    &--1 {
      background-image: image-set(url('../img/w-1.webp') 1x,
          url('../img/w-1_2x.webp') 2x);
    }

    &--2 {
      background-image: image-set(url('../img/w-2.webp') 1x,
          url('../img/w-2_2x.webp') 2x);
    }

    &--3 {
      background-image: image-set(url('../img/w-3.webp') 1x,
          url('../img/w-3_2x.webp') 2x);
    }

    &--4 {
      background-image: image-set(url('../img/w-4.webp') 1x,
          url('../img/w-4_2x.webp') 2x);
    }

    &--5 {
      background-image: image-set(url('../img/w-5.webp') 1x,
          url('../img/w-5_2x.webp') 2x);
    }

    &--6 {
      background-image: image-set(url('../img/w-6.webp') 1x,
          url('../img/w-6_2x.webp') 2x);
    }

    &--7 {
      background-image: image-set(url('../img/w-7.webp') 1x,
          url('../img/w-7_2x.webp') 2x);
    }

    &--8 {
      background-image: image-set(url('../img/w-8.webp') 1x,
          url('../img/w-8_2x.webp') 2x);
    }

    &--9 {
      background-image: image-set(url('../img/w-9.webp') 1x,
          url('../img/w-9_2x.webp') 2x);
    }

    &--invisible {
      border: none;

      @include small-tablet {
        position: absolute;
        overflow: hidden;
        margin: -1px;
        border: 0;
        padding: 0;
        width: 1px;
        height: 1px;
        clip: rect(0 0 0 0);
      }
    }

    &-title {
      margin: 0;
      margin-bottom: 10px;
      padding-right: 40px;
      padding-left: 40px;
      font-weight: 500;
      // font-size: 24px;
      font-size: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
      font-size: clamp(1.125rem, 0.768rem + 0.952vw, 1.625rem);
      color: var(--fd-pale-orange);

      @include small-mobile {
        padding-right: 10px;
        padding-left: 10px;
        margin-right: auto;
        margin-left: auto;
      }

      @include mini-mobile {
        width: 100%;
        padding-right: 40px;
        padding-left: 40px;
      }
    }

    &-text {
      margin: 0;
      margin-bottom: 56px;
      padding-right: 40px;
      padding-left: 40px;
      font-weight: 300;
      // font-size: 24px;
      font-size: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
      color: var(--white);

      @include tablet {
        margin-bottom: 40px;
      }

      @include small-mobile {
        padding-right: 10px;
        padding-left: 10px;
        margin-right: auto;
        margin-left: auto;
      }

      @include mini-mobile {
        padding-right: 40px;
        padding-left: 40px;
      }
    }
  }

  &__img {
    vertical-align: bottom;
    margin-bottom: 60px;

    @include frst {
      margin-bottom: 40px;
    }
  }
}

@keyframes rotate {
  0% {
    rotate: 0deg;
  }

  50% {
    rotate: 180deg;
  }

  100% {
    rotate: 360deg;
  }
}
