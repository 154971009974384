.offers {

  &__plan-list-item {

    // &:not(:last-child) {
    margin-bottom: 80px;
    // }
  }

  &__services-list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;
    margin-bottom: 80px;

    &-item {
      grid-column: 6 span;

      @include semi-tablet {
        grid-column: 12 span;
      }
    }
  }

}

