.text-lr {
  backdrop-filter: blur(3px)!important;

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  &__title {
    margin: 0;
    margin-bottom: 36px;
    font-weight: 500;
    // font-size: 73px;
    font-size: clamp(2.375rem, 0.679rem + 4.524vw, 4.75rem);
    line-height: 104%;
    color: var(--fd-orange);
    width: 100%;

    @include mini-tablet {
      margin-bottom: 18px;
      padding: 0 10px;
    }
  }

  &__left,
  &__right {
    width: calc(50% - 12px);

    @include mini-tablet {
      padding: 0 10px;
      width: 100%;
    }
  }

  &__left {
    padding-right: 40px;

    &--only {
      padding-right: 0;
      width: 75%;
    }

    @include mini-tablet {
      padding-right: 10px;
    }
  }

  &__text {
    font-weight: 400;
    // font-size: 32px;
    font-size: clamp(1.125rem, 0.5rem + 1.667vw, 2rem);
    color: var(--basic-white);
  }

}
