/* stylelint-disable */
@mixin big-desktop {
  @media (max-width: (1440px)) {
    @content;
  }
}

@mixin ser-frst {
  @media (max-width: (1250px)) {
    @content;
  }
}

@mixin frst {
  @media (max-width: (1200px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin semi-tablet {
  @media (max-width: (930px)) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: (768px)) {
    @content;
  }
}
// border-radius: 25px !important;


@mixin mini-tablet {
  @media (max-width: (624px)) {
    @content;
  }
}

@mixin big-mobile {
  @media (max-width: (589px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (576px)) {
    @content;
  }
}
// section-card-none

@mixin small-mobile {
  @media (max-width: (480px)) {
    @content;
  }
}

@mixin mini-mobile {
  @media (max-width: (400px)) {
    @content;
  }
}

@mixin last {
  @media (max-width: (350px)) {
    @content;
  }
}
// next wst-28 30 28px-title
