.work-stages-img {

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;

    @include small-tablet {
      gap: 12px;
    }

    @include mobile {
      gap: 24px;
    }

    &-item {
      grid-column: 6 span;

      @include mobile {
        grid-column: 12 span;
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    &-content {
      padding: 40px 60px;

      @include tablet {
        padding: 30px 30px;
      }

      @include big-mobile {
        padding: 20px 15px;
      }

      @include mobile {
        padding: 20px 30px;
      }

      @include last {
        padding: 15px 15px;
      }
    }

    &-title {
      margin-bottom: 20px;
      font-weight: 500;
      // font-size: 36px;
      font-size: clamp(1.5rem, 0.964rem + 1.429vw, 2.25rem);
      letter-spacing: 0.05em;
      color: var(--fd-orange);
    }

    &-text {
      position: relative;
      margin: 0;
      margin-bottom: 8px;
      padding-left: 15px;
      font-weight: 300;
      // font-size: 20px;
      // font-size: clamp(1rem, 0.821rem + 0.476vw, 1.25rem);
      font-size: clamp(1rem, 0.821rem + 0.476vw, 1.25rem);
      color: var(--basic-white);

      &::after {
        content: "";
        position: absolute;
        left: 2px;
        top: 10px;
        width: 5px;
        height: 5px;
        border-radius: 25px;
        background-color: var(--white);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
}
