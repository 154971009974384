.dictionary {

  @include small-mobile {
    padding-top: 50px;
  }

  &__article {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 60px;
    background: rgba(39, 46, 57, 0.45);
    backdrop-filter: blur(3px);
    border-radius: 25px;
    z-index: 1;

    @include small-tablet {
      padding: 15px;
      border-radius: 25px;
    }

    &-img {
      position: absolute;
      z-index: -1;
      right: -12%;
      top: -11%;
      width: 100%;
      max-width: 564px;
      height: auto;
      animation-name: fox-bounce;
      animation-duration: 20s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      animation-delay: -2s;

      @include small-tablet {
        top: -7%;
        max-width: 282px;
      }
    }

    &-title {
      margin: 0;
      // margin-bottom: 40px;
      font-weight: 400;
      // font-size: 110px;
      font-size: clamp(2.5rem, 1.25rem + 6.25vw, 6.875rem);
      letter-spacing: 0.05em;
      color: var(--fd-pale);

      &-descr {
        display: inline-block;
        padding-top: 10px;
        font-weight: 400;
        // font-size: 64px;
        font-size: clamp(1.75rem, 1.107rem + 3.214vw, 4rem);
        letter-spacing: 0.05em;
        color: var(--fd-orange);
      }
    }

    &-descr {
      margin: 0;
      margin-bottom: 40px;
      font-weight: 400;
      // font-size: 32px;
      font-size: clamp(1.375rem, 1.196rem + 0.893vw, 2rem);
      color: var(--basic-white);

      & b {
        color: var(--fd-orange);
      }
    }

    &-subtitle {
      margin-bottom: 30px;
      font-weight: 400;
      // font-size: 40px;
      font-size: clamp(1.5rem, 1.214rem + 1.429vw, 2.5rem);
      letter-spacing: 0.05em;
      color: var(--fd-orange);
    }

    &-cite {
      position: relative;
      margin: 0 40px;
      margin-bottom: 40px;
      font-style: italic;
      font-weight: 400;
      // font-size: 32px;
      font-size: clamp(1.375rem, 1.196rem + 0.893vw, 2rem);
      color: var(--fd-ocre);
      z-index: 1;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -5%;
        width: 47px;
        height: 38px;
        background: image-set(url('/img/cite.webp') 1x,
        url('/img/cite_2x.webp') 2x);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        z-index: -1;

        @include small-tablet {
          width: 24px;
          height: 19px;
          left: -9%;
        }
      }
    }

    &-svg {
      padding-top: 20px;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      max-width: 1040px;
      height: auto;
    }
  }

  &__link {
    font-style: italic;
    font-weight: 400;
    // font-size: 32px;
    font-size: clamp(1.375rem, 1.196rem + 0.893vw, 2rem);
    color: var(--fd-ocre);
    text-decoration: underline;
    transition: color .3s ease-in-out;

    @include hover {
      color: var(--fd-orange);
    }
  }
}

@keyframes fox-bounce {
  0% {
      transform: translateY(10px) translateX(10px) rotate(-5deg);
    }

    25% {
      transform: translateY(-10px) translateX(-10px) rotate(-10deg);
    }

    50% {
      transform: translateY(10px) translateX(10px) rotate(-20deg);
    }

    75% {
      transform: translateY(-10px) translateX(-10px) rotate(-10deg);
    }

    100% {
      transform: translateY(10px) translateX(10px) rotate(-5deg);
    }
  }
