.faq {

  &__list {
    display: flex;
    flex-direction: column;

    &-item {

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &-card {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      backdrop-filter: blur(3px);
      border: 1px solid var(--fd-orange);
      border-radius: 25px;

      &-intro-title {
        margin: 0;
        color: #fff;
        // font-size: 26px;
        font-size: clamp(1.25rem, 1.107rem + 0.714vw, 1.75rem);
        font-weight: 300;
        transition: all .3s ease-in-out;
      }

      &-content {
        display: none;

        &-text {
          margin: 0;
          margin-bottom: 20px;
          padding: 0 20px 20px;
          line-height: 1;
          font-weight: 300;
          font-size: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
          color: var(--white);
          transition: all .3s ease-in-out;
        }
      }

      &-link {
        font-weight: 300;
        font-size: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
        text-decoration: underline;
        color: var(--white);
      }
    }
  }

}

p {
  color: #f1f1f1;
}
