.custom-check {
  position: relative;
  padding-left: 50px;

  &__checkbox {
    position: absolute;
    margin-top: -6px;
    margin-left: -50px;
    width: 32px;
    height: 28px;
    border-radius: 30px;
    // border: 1px solid var(--fd-orange);
    // box-shadow: 0 0 0 1px var(--fd-orange);
    background-color: var(--white);
    transition: border-color .3s ease-in-out,
      opacity .3s ease-in-out,
      background-color .3s ease-in-out,
      box-shadow .3s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      height: 110%;
      width: 60%;
      margin: auto;
      // bottom: 50%;
      top: 25%;
      left: 65%;
      right: 0;
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
      border-right: 7px solid var(--fd-orange);
      border-bottom: 7px solid var(--fd-orange);
      opacity: 0;
    }
  }

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    &:checked+.custom-check__checkbox:after,
    &:checked+.custom-check__checkbox:before {
      opacity: 1;
    }

    // focused
    &:focus-visible+.custom-check__checkbox {
      outline: 2px solid var(--fd-orange);
    }

    outline-offset: 2px;
  }

  &:checked:focus-visible+.custom-check__checkbox {
    box-shadow:
      0 0 0 1px var(--fd-orange),
      0 0 0 2px var(--fd-orange);
  }

  // disabled
  &:disabled+.custom-check__checkbox {
    box-shadow: 0 0 0 1px var(--grey-color);
  }

  &:checked:disabled+.custom-check__checkbox {
    box-shadow: 0 0 0 1px var(--grey-color);
    background-color: var(--grey-color);
  }
}
