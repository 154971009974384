.services-cyber {

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;

    @include small-tablet {
      gap: 12px;
    }

    &-item {
      grid-column: 6 span;

      @include mobile {
        grid-column: 12 span;
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    padding: 10%;
    height: 100%;

    &-img {
      margin-bottom: 40px;
    }

    &-title {
      margin-bottom: 15px;
      font-weight: 500;
      // font-size: 36px;
      font-size: clamp(1.5rem, 0.964rem + 1.429vw, 2.25rem);
      color: var(--fd-orange);
    }

    &-text {
      margin-bottom: 60px;
      font-weight: 300;
      // font-size: 20px;
      font-size: clamp(1rem, 0.821rem + 0.476vw, 1.25rem);
      color: var(--basic-white);
    }

    &-btn {
      margin-top: auto;
      text-align: center;

      @include small-tablet {
        padding: 17px 60px;
        align-self: center;
      }

      @include mobile {
        // align-self: flex-start;
        padding: 17px 100px;
      }

      @include last {
        padding: 17px 60px;
      }
    }
  }

  & picture {
    display: flex;
    justify-content: center;
  }
}
