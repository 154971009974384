.partners-cyber {
  padding-top: 100px;
  padding-bottom: 100px;

  &__title {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    color: var(--fd-orange);
  }

  &__slide {
    position: relative;
    display: flex;
    justify-content: center;

    @include hover {

      & .partners-cyber__slide-img {
        opacity: 0;
      }

      & .partners-cyber__slide-img--active {
        opacity: 1;
        z-index: 2;
      }
    }

    &-img {
      transition: opacity .3s ease-in-out;

      @include tablet {
          opacity: 0;
      }

      &--active {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;

        @include tablet {
            opacity: 1;
        }
      }
    }
  }
}

