.header {
  // position: fixed;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  z-index: 100;


  &-graphite {
    background-color: var(--fd-graphite);
  }

  &__container {
    display: flex;
    align-items: end;
    margin: 0 auto;
    padding-top: 28px;
    padding-bottom: 28px;
    margin: 0 auto;
    width: 100%;
    max-width: var(--container-width);

    @include frst {
      flex-wrap: wrap;
    }

    @include small-tablet {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &__left {
    display: flex;
    align-items: end;
    padding-right: 15px;
    width: 35%;

    @include frst {
      width: 40%;
    }

    @include small-tablet {
      padding: 0;
      width: 100%;
    }
  }

  &__center {
    display: flex;
    align-items: end;
    justify-content: flex-end;
    margin-left: auto;

    @include frst {
      padding-top: 20px;
      order: 5;
      width: 100%;
      justify-content: flex-start;
    }

    @include small-tablet {
      justify-content: center;
      padding-bottom: 10px;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    width: 30%;

    @include frst {
      width: 50%;
      margin-left: auto;
    }

    @include small-tablet {
      width: 100%;
      justify-content: center;
      order: 6;
    }
  }

  &__btn {
    justify-self: flex-end;
    background-color: transparent!important;
    color: var(--fd-orange)!important;

    & span {
      white-space: nowrap;
    }

    @include last {
      padding: 17px 85px;
    }
  }

  &__logo {
    margin-right: auto;
    padding-right: 15px;

    & img {
      width: 100%;
      min-width: 140px;
      height: auto;
    }

    @include small-tablet {
      // order: 2;
      margin: 0;
      margin-right: auto;
    }
  }

  &__burger {
    margin-right: auto;

    @include hover {
      &::after {
        background-color: var(--fd-orange);
        transition: background-color .3s ease-in-out;
      }

      &::before {
        background-color: var(--fd-orange);
        transition: background-color .3s ease-in-out;
      }

      & .burger__line {
        background-color: var(--fd-orange);
        transition: background-color .3s ease-in-out;
      }
    }

    @include small-tablet {
      margin: 0;
    }
  }

  &__phone {
    grid-column: 3 span;
    text-align: end;
    margin-right: 10px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.05em;
    color: var(--white);

    @include small-tablet {
      order: 2;
    }
  }

  &__planet {
    position: absolute;
    top: -810px;
    left: 490px;
    border-radius: 100%;
    width: 300px;
    height: 300px;
    background-color: var(--white);
    box-shadow: inset 17px 26px 58px 23px rgba(0, 0, 0, 1);
    background-image: url('/img/saturn.webp');
    background-size: auto 100%;
    background-repeat: repeat-x;
    transform: rotate(-19deg);
    opacity: 0;
    transition: all 1s ease-in-out;

    @include small-tablet {
      left: 250px;
      top: -300px;
    }

    @include small-mobile {
      left: 150px;
    }
  }

  &__modal {
    display: flex;
    align-items: flex-start;
    top: 10px;
    max-width: 1440px;
    // border-radius: 25px;
    // backdrop-filter: blur(7px);
    // background-color: #13131350;
    z-index: 999999;
    transition: opacity .3s ease-in-out;
    opacity: 0;

  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;

    & svg {

      @include small-tablet {
        display: none;
      }
    }
  }

  &__nav {
    display: flex;
    width: 100%;
    padding-bottom: 50px;

    &-list {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 25px;

      @include small-tablet {
        gap: 12px;
      }

      &-item {
        grid-column: 6 span;

        &:nth-child(1) {
          order: 3;
          grid-column: 6 span;

          @include mini-tablet {
            grid-column: 12 span;
          }
        }

        &:nth-child(2) {
          order: 1;
        }

        &:nth-child(3) {
          order: 2;
        }

        &:nth-child(4) {
          grid-column: 7/6 span;
          order: 4;

          @include mini-tablet {
            grid-column: 12 span;
          }
        }

        &:nth-child(5) {
          order: 5;
        }

        &:nth-child(6) {
          order: 6;
        }

        &:nth-child(7) {
          order: 7;
        }

        &:nth-child(8) {
          order: 8;
        }

        &:nth-child(9) {
          order: 9;
        }

        &:nth-child(10) {
          order: 10;
        }

        @include mini-tablet {
          grid-column: 12 span;
        }
      }

      &-card {
        display: flex;
        flex-direction: column;
        // justify-content: space-around;
        align-items: flex-start;
        gap: 15px;
        padding: 15px;
        height: 100%;
        border-radius: 25px;
        overflow: visible;

        @include small-tablet {
          border-radius: 15px !important;
        }

        &-right {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &-link {
      position: relative;
      font-weight: 300;
      // margin-bottom: 7px;
      font-size: 20px;
      letter-spacing: 0.05em;
      color: var(--fd-pale);

      @include small-tablet {
        font-size: 18px;
      }


      &:not(:last-child) {
        margin-bottom: 7px;
      }

      &--main {
        margin-bottom: 0 !important;
        font-size: 24px;
        font-weight: 500;

        @include small-tablet {
          font-size: 20px;
        }

        @include mini-tablet {
          font-size: 18px;
        }
      }
    }
  }
}
