.accordion {

  &__intro {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    @media (min-width:1024px) {

      @include hover {
        letter-spacing: 2px;
      }
    }
  }

  &__icon {
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    transition: transform .3s ease-in-out;

    &::after {
      position: absolute;
      content: '';
      right: 50%;
      top: 13px;
      height: 3px;
      width: 30px;
      background-color: var(--fd-orange);
      border-radius: 10px;
      transform: translate(50%);
    }

    &::before {
      position: absolute;
      content: '';
      right: 50%;
      top: 0;
      width: 3px;
      height: 30px;
      background-color: var(--fd-orange);
      border-radius: 10px;
      transform: translate(50%);

    }
  }

  &__content {
    overflow: hidden;
    transition: all 0.25s ease-out;
    opacity: 0;
    display: none;
  }

  &.accordion__active .accordion__content {
    opacity: 1;
    display: flex;
    transition: all .3s ease-in-out;

    & .faq__list-card-cantent-text {
      cursor: pointer;
    }
  }
}

.accordion__icon.icon-active {
  perspective: 100px;
    transform: rotate(225deg);
}
