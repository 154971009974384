.cases {

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;

    @include small-tablet {
      gap: 12px;
    }

    &-item {
      grid-column: 6 span;
      height: 100%;


      @include big-mobile {
        grid-column: 12 span;
      }
    }
  }

  &__card {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 728px;
    perspective: 1000px;
    transform-style: preserve-3d;
    cursor: pointer;

    @include frst {
      min-height: 659px;
    }

    @include tablet {
      min-height: 590px;
    }

    @include small-tablet {
      min-height: 553px;
    }

    @include mini-tablet {
      min-height: 545px;
    }

    @include mini-tablet {
      min-height: 620px;
    }

    @include small-mobile {
      min-height: 540px;
    }

    @include last {
      min-height: 530px;
    }

    &-img {
      border-radius: 23px 23px 0 0;

      @include small-tablet {
        border-radius: 23px 23px 0 0;
      }
    }

    &-svg {
      width: 100%;
      max-width: 46px;
      height: auto;

      @include small-tablet {
        max-width: 40px;
      }

      @include mobile {
        max-width: 46px;
      }
    }

    &-front,
    &-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all .3s ease-in-out;
      // backface-visibility: hidden !important;
      backdrop-filter: blur(15px);
      background: rgba(39, 46, 57, 1);

      &-btn {
        display: none;
        position: absolute;
        top: 30px;
        right: 30px;

        @include tablet {
          display: block;
        }

        @include small-tablet {
          top: 15px;
          right: 15px;
        }
      }
    }

    &-back {
      z-index: -1;
      opacity: 0;
    }

    @media (min-width:1024px) {

      @include hover {

        & .cases__card-front {
          z-index: -1;
          opacity: 0;
          transition: transform 1s ease-in-out, opacity .2s ease-in-out;
        }

        & .cases__card-back {
          z-index: 1;
          opacity: 1;
          transition: transform 1s ease-in-out, opacity .2s ease-in-out;
        }
      }
    }

    &-content {
      padding: 24px 8.6%;

      @include frst {
        padding: 7% 7%;
      }

      &-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        max-height: 132px;

        @include small-tablet {
          flex-direction: column;
          max-height: unset;
          min-height: 170px;
        }

        @include big-mobile {
          flex-direction: row;

        }

        &-item {
          position: relative;
          padding-left: 14px;
          width: 45%;

          @include semi-tablet {
            width: 50%;
          }

          @include small-tablet {
            width: 100%;
          }

          @include big-mobile {
            width: 50%;
          }

          @include small-mobile {
            width: 100%;
          }

          ::after {
            content: "";
            position: absolute;
            left: 2px;
            top: 9px;
            width: 5px;
            height: 5px;
            border-radius: 25px;
            background-color: var(--white);
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
      }

      &-text {
        margin: 0;
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 16px;
        color: var(--white);
      }
    }

    &-title {
      margin-bottom: 33px;
      font-weight: 600;
      // font-size: 55px;
      font-size: clamp(1.563rem, 1.027rem + 2.679vw, 3.438rem);
      color: var(--fd-orange);

      @include small-tablet {
        margin-bottom: 15px;
      }

      &-descr {
        font-weight: 400;
        // font-size: 24px;
        font-size: clamp(1.125rem, 1.018rem + 0.536vw, 1.5rem);
        letter-spacing: 0.05em;
        color: var(--white);
      }
    }

    &-back {
      display: flex;
      flex-direction: column;
      padding: 10% 10%;

      @include frst {
        padding: 7% 7%;
      }

      @include semi-tablet {
        padding: 7% 5%;
      }

      @include small-tablet {
        justify-content: space-between;
      }

      @include big-mobile {
        justify-content: space-around;
      }

      &-descr {
        margin-bottom: 22px;
        font-weight: 400;
        // font-size: 24px;
        font-size: clamp(1.125rem, 1.018rem + 0.536vw, 1.5rem);
        letter-spacing: 0.05em;
        color: var(--fd-pale-orange);
      }

      &-list {

        // @include big-mobile {
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: space-between;
        // }

        &-item {

          &:not(:last-child) {
            margin-bottom: 29px;

            @include tablet {
              margin-bottom: 15px;
            }

            @include big-mobile {
              margin-bottom: 30px;
            }
          }
        }
      }

      &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18.7px 60px;
        border: 1px solid var(--fd-pale-orange);
        border-radius: 25px;
        // gap: 24px;

        @include tablet {
          padding: 15px 39px;
        }

        @include small-tablet {
          flex-direction: column;
          padding: 15px;
          border-radius: 25px;
        }

        &--left {
          padding-right: 10px;
          width: calc(50% - 12px);
          font-weight: 500;
          // font-size: 45px;
          font-size: clamp(1.5rem, 1.125rem + 1.875vw, 2.813rem);
          color: var(--fd-pale-orange);
          text-transform: uppercase;

          @include small-tablet {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            width: 100%;
          }
        }

        &--small {
          font-weight: 400;
          // font-size: 20px;
          font-size: clamp(1.125rem, 1.089rem + 0.179vw, 1.25rem);
          letter-spacing: 0.05em;
          color: #e7a779;
          text-transform: lowercase;

          @include small-tablet {
            padding-left: 10px;
          }
        }

        &--right {
          font-family: var(--font-family);
          width: calc(50% - 12px);
          font-weight: 400;
          font-size: 18px;
          letter-spacing: 0.05em;
          color: var(--white);

          @include small-tablet {
            width: 100%;
          }
        }
      }
    }
  }

}

.cases__card-front.active {
  z-index: -1;
  opacity: 0;
  transition: transform 1s ease-in-out, opacity .3s ease-in-out;
}

.cases__card-back.active {
  z-index: 1;
  opacity: 1;
  transition: transform 1s ease-in-out, opacity .3s ease-in-out;
}

