.contacts {
  &__content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 7.1% 0;
    gap: 24px;

    @include small-tablet {
    padding: 7.1% 20px;
    }
  }

  &__title {

    @include tablet {
      grid-column: 1 span;
    }

    @include small-tablet {
      grid-column: 12 span;
    }
  }

  &__list {
    grid-column: 4/5 span;

    @include tablet {
      grid-column: 2/6 span;
    }

    @include small-tablet {
      grid-column: 1/7 span;
    }

    @include mini-tablet {
      grid-column: 12 span;
    }

    &--social {
      grid-column: 9/4 span;

      @include tablet {
        grid-column: 8/5 span;
      }

      @include mini-tablet {
        grid-column: 12 span;
      }
    }
  }

  &__link {
    display: flex;
    margin-bottom: 70px;

    @include small-tablet {
      margin-bottom: 40px;
    }

    &-text {
      font-weight: 500;
      // font-size: 36px;
      font-size: clamp(1.375rem, 1.125rem + 1.25vw, 2.25rem);
      letter-spacing: 0.05em;
      color: var(--fd-orange);

      &--location {
        padding-top: 8px;
        width: 70%;
        font-weight: 400;
        // font-size: 24px;
        font-size: clamp(1.25rem, 1.179rem + 0.357vw, 1.5rem);

        @include mobile {
          width: 100%;
        }
      }
    }

    &-descr {
      font-family: var(--font-family);
      font-weight: 400;
      // font-size: 20px;
      font-size: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
      letter-spacing: 0.05em;
      color: var(--white);
    }
  }

  &__svg {
    align-self: flex-start;
    margin-top: 5px;
    margin-right: 15px;
    width: 100%;
    max-width: 40px;
    height: auto;

    @include semi-tablet {
      max-width: 30px;
    }

    @include mini-tablet {
      width: 20px;
    }
  }

  &__descr {
    grid-column: 4/8 span;
    font-family: var(--font-family);
    font-weight: 500;
    // font-size: 20px;
    font-size: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
    letter-spacing: 0.05em;
    color: var(--fd-orange);

    @include tablet {
      grid-column: 3/8 span;
    }

    @include small-tablet {
      grid-column: 2/8 span;
    }

    @include mini-tablet {
      grid-column: 12 span;
    }

    &--white {
      font-weight: 400;
      // font-size: 18px;
      font-size: clamp(0.875rem, 0.804rem + 0.357vw, 1.125rem);
      color: var(--white);
    }
  }
}

.social-list {

  &__link {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-family: var(--font-family);
    font-weight: 400;
    // font-size: 24px;
    font-size: clamp(1.25rem, 1.179rem + 0.357vw, 1.5rem);
    letter-spacing: 0.05em;
    color: var(--light-color);
  }

  &__svg {
    margin-right: 35px;

    @include semi-tablet {
      margin-right: 15px;
      width: 30px;
    }

    @include mini-tablet {
      width: 20px;
    }
  }
}
