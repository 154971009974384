.not-found {

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-bottom: -300px;
    padding-top: 25px;
    padding-bottom: 0;
  }

  &__img {
    width: 100%;
    max-width: 1110px;
    height: auto;
  }

  &__text {
    font-weight: 400;
    // font-size: 36px;
    font-size: clamp(1.25rem, 0.536rem + 1.905vw, 2.25rem);
    letter-spacing: 0.05em;
    text-align: center;
    width: 70%;
    color: var(--fd-pale);
  }
}

.not-404 .footer {
  padding-top: 0;

  &__container {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
