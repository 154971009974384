.text-blur {
  backdrop-filter: blur(3px);

  &__title {
    margin: 0;
    margin-bottom: 60px;
    font-weight: 500;
    // font-size: 73px;
    font-size: clamp(2rem, 1.268rem + 3.661vw, 4.563rem);
    line-height: 104%;
    color: var(--fd-orange);

    @include mini-tablet {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__text {
    margin: 0;
    margin-bottom: 50px;
    font-weight: 400;
    // font-size: 32px;
    font-size: clamp(1rem, 0.286rem + 1.905vw, 2rem);
    color: var(--basic-white);
    width: 100%;
  }

  &__list {

    &-item {

      &:not(:last-child) {
        margin-bottom: 40px;

        @include mini-tablet {
          margin-bottom: 60px;
        }
      }
    }
  }

  &__card {
    display: flex;
    gap: 24px;

    @include mini-tablet {
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
    }

    &-title {
      margin: 0;
      font-weight: 500;
      // font-size: 36px;
      font-size: clamp(1.5rem, 0.964rem + 1.429vw, 2.25rem);
      line-height: 111%;
      color: var(--fd-orange);
      width: calc(35% - 12px);

      @include mini-tablet {
        width: 100%;
      }
    }

    &-text {
      margin: 0;
      font-weight: 400;
      // font-size: 32px;
      font-size: clamp(1rem, 0.286rem + 1.905vw, 2rem);
      color: var(--basic-white);
      width: calc(65% - 12px);

      @include mini-tablet {
        width: 100%;
      }
    }
  }

  &__link {
    font-weight: 300;
    // font-size: 32px;
    font-size: clamp(1rem, 0.286rem + 1.905vw, 2rem);
    color: var(--white);
    text-decoration: underline;
    transition: color .3s ease-in-out;

    @include hover {
      color: var(--fd-orange);
    }
  }
}
