.hero-section {

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;

    @include tablet {
      &.hero-restaurant__container {
        padding-top: 180px;
      }
    }

    @include big-mobile {
      padding-top: 340px;

      &.seo__container {
        padding-top: 500px;
      }

      &.hero-soprovozhdenie__container,
      &.hero-dentistry__container,
      &.hero-cyber-security__container {
        padding-top: 530px;
      }

      &.hero-audit__container,
      .hero-corporation__container,
      &.hero-builders__container {
        padding-top: 430px;
      }

      &.hero-restaurant__container {
        padding-top: 280px;
      }
    }

    @include small-mobile {

      &.seo__container {
        padding-top: 410px;
      }

      &.hero-soprovozhdenie__container,
      &.hero-dentistry__container,
      .hero-cyber-security__container {
        padding-top: 430px;
      }

      &.hero-restaurant__container {
        padding-top: 480px;
      }
    }

    @include mini-mobile {
      padding-top: 240px;

      &.seo__container,
      &.hero-soprovozhdenie__container,
      &.hero-corporation__container,
      &.hero-cyber-security__container {
        padding-top: 370px;
      }
    }

    @include last {

      &.hero-fitness__container {
        padding-top: 270px;
      }

      &.hero-cyber-security__container {
        padding-top: 320px;
      }
    }
  }

  &__list {
    margin-bottom: 82px;
  }

  &__text {
    margin: 0;
    font-weight: 400;
    // font-size: 36px;
    font-size: clamp(1.563rem, 1.366rem + 0.982vw, 2.25rem);
    letter-spacing: 0.05em;
    color: var(--fd-pale);

    position: relative;
    padding-left: 20px;

    &::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 50%;
      width: 5px;
      height: 5px;
      border-radius: 25px;
      background-color: var(--fd-pale);
      transform: translateY(-50%);
    }
  }

  &__paragraph {
    margin: 0;
    margin-bottom: 75px;
    width: 53%;
    font-weight: 400;
    // font-size: 36px;
    font-size: clamp(1.563rem, 1.366rem + 0.982vw, 2.25rem);
    letter-spacing: 0.05em;
    color: var(--fd-pale);

    &--corporation {
      width: 53%;
    }

    &--cyber-security {
      width: 53%;
    }

    &--cyber-incident-management {
      width: 53%;
    }

    &--app-security {
      width: 53%;
    }

    &--protection {
      width: 53%;
    }

    &--stress-testing {
      width: 53%;
    }

    &--penetration-testing {
      width: 53%;
    }

    &--grade {
      width: 53%;
    }

    &--intelligence {
      width: 53%;
    }

    &--smm {
      width: 50%;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__btn {
    align-self: flex-start;
    background-color: var(--fd-orange) !important;
    color: var(--dark) !important;

    @include last {
      padding: 17px 88px;
      justify-self: center;
    }
  }

  &__img {
    position: absolute;
    top: 8%;
    right: -33%;
    z-index: -1;

    @include semi-tablet {
      top: 22%;
    }

    @include big-mobile {
      top: 8%;
      right: 0;
      transform: scale(1.3);
    }

    &--seo {
      right: -22%;
      top: 12%;

      @include frst {
        right: -24%;
      }

      @include semi-tablet {
        right: -30%;
      }

      @include big-mobile {
        top: 8%;
        right: 0;
      }
    }

    &--context {
      right: -24%;
      top: 0%;

      @include semi-tablet {
        top: 4%;
      }

      @include mini-tablet {
        top: 10%;
      }

      @include big-mobile {
        top: 0%;
        right: 0;
      }

      @include small-mobile {
        top: 5%;
      }

      @include mini-mobile {
        top: 0%;
      }
    }

    &--soprovozhdenie {
      right: -22%;
      top: 9%;

      @include tablet {
        right: -27%;
        top: 4%;
      }

      @include semi-tablet {
        right: -30%;
        top: 4%;
      }

      @include mini-tablet {
        top: 8%;
      }

      @include big-mobile {
        transform: scale(1.0);
        right: -4%;
      }

      @include small-mobile {
        right: -5%;
      }
    }

    &--audit {
      right: -22%;
      top: 9%;

      @include big-mobile {
        transform: scale(1.0);
        right: 0%;
      }

      @include small-mobile {
        top: 17%;
      }

      @include mini-mobile {
        top: 22%;
      }

      @include last {
        top: 23%;
      }
    }

    &--builders {
      right: -25%;
      top: 9%;

      @include big-mobile {
        top: 13%;
        right: 0%;
      }

      @include small-mobile {
        top: 16%;
      }

      @include mini-mobile {
        top: 20%;
      }
    }

    &--dentistry {
      right: -26%;

      @include mini-tablet {
        top: 25%;
      }

      @include big-mobile {
        // transform: scale(0.8);
        top: 15%;
        right: 0;
      }

      @include small-mobile {
        top: 12%;
      }

      @include mini-mobile {
        top: 19%;
      }

      @include last {
        top: 22%;
      }
    }

    &--fitness {
      right: -23%;


      @include last {
        top: 12%;
      }
    }

    &--restaurant {
      right: -23%;

      @include tablet {
        top: 2%;
        right: -33%;
      }

      @include small-tablet {
        top: 7%;
      }

      @include mini-tablet {
        top: 10%;
      }

      @include small-mobile {
        right: -3%;
        top: 18%;
      }

      @include mini-mobile {
        top: 23%;
      }

      @include last {
        top: 25%;
      }
    }

    &--shop {
      right: -24%;
    }

    &--corporation {
      right: -33%;

      @include semi-tablet {
        top: 12%;
      }

      @include small-tablet {
        top: 4%;
      }

      @include mini-tablet {
        top: 6%;
      }

      @include big-mobile {
        right: -4%;
        top: 3%;
        transform: scale(1.1);
      }

      @include small-mobile {
        top: 6%;
      }

      @include mini-mobile {
        top: 15%;
      }

      @include last {
        top: 17%;
      }
    }

    &--cyber-security {
      right: -20%;

      @include frst {
        right: -26%;
      }

      @include tablet {
        right: -30%;
      }

      @include semi-tablet {
        top: -2%;
      }

      @include small-tablet {
        top: 5%;
      }

      @include big-mobile {
        top: 10%;
        right: -3%;
        transform: scale(1);
      }

      @include mobile {
        top: 12%;
      }
    }

    &--cyber-incident-management {
      right: -18%;
    }

    &--app-security {
      right: -24%;
    }

    &--protection {
      right: -14%;
    }

    &--stress-testing {
      right: -19%;
    }

    &--penetration-testing {
      right: -19%;
    }

    &--grade {
      right: -17%;
    }

    &--intelligence {
      right: -20%;
    }

    &--car-buisnes {
      right: -12%;
      transform: scale(1);


      @include big-mobile {
        transform: scale(1);
      }
    }

    &--smm {
      right: -15%;

      @include frst {
        right: -19%;
      }

      @include tablet {
        right: -27%;
      }
    }

    &--target {
      right: -14%;
    }

    &--tilda {
      top: -10%;
      right: -20%;

      @include big-mobile {
        top: 10%;
      }
    }

    &--promo {
      top: -10%;
      right: -8%;

      @include big-mobile {
        right: 0%;
      }
    }
  }


}

.hero-create {
  &__text {
    position: relative;
    padding-left: 20px;

    &::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 50%;
      width: 5px;
      height: 5px;
      border-radius: 25px;
      background-color: var(--fd-pale);
      transform: translateY(-50%);
    }
  }
}

.hero-builders__title,
.hero-dentistry__title,
.hero-restaurant__title,
.hero-shop__title,
.hero-corporation__title,
.hero-cyber-security__title,
.hero-fitness__title,
.hero-cyber-incident-management__title,
.hero-app-security__title,
.hero-protection__title,
.hero-stress-testing__title,
.hero-grade__title,
.hero-intelligence__title,
.hero-smm__title,
.hero-car-buisnes__title,
.hero-tilda__title {
  width: 68%;
}
