.price {

  &__card {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 31px 40px;
    // column-gap: 40px;

    @include small-tablet {
      padding: 15px 20px;
    }

    & picture {
      display: flex;
      flex-direction: column;

      @media (max-width:685px) {
        order: 9;
      }
    }

    @include hover {
      & .price__card-img--1 {
        animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
        transform: translate3d(0, 0, 0);
        perspective: 1000px;
      }

      & .price__card-img--2 {
        animation: wiggle-shake 0.3s cubic-bezier(.36, .07, .19, .97) both infinite;
        transform: translate3d(0, 0, 0);
        perspective: 1000px;
      }
    }

    &-top {
      width: 100%;
      margin-bottom: 59px;

      @include small-tablet {
        margin-bottom: 30px;
      }

      @include mini-mobile {
        margin-bottom: 60px;
      }
    }

    &-title {
      font-weight: 500;
      // font-size: 64px;
      font-size: clamp(2.125rem, 0.786rem + 3.571vw, 4rem);
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: var(--fd-orange);
    }

    &-text {
      margin: 0;
      width: 70%;
      font-weight: 400;
      // font-size: 24px;
      font-size: clamp(1rem, 0.643rem + 0.952vw, 1.5rem);
      letter-spacing: 0.05em;
      color: var(--fd-pale);

    }

    &-img {
      z-index: -1;
      width: 100%;
      height: auto;

      &--1 {
        max-width: 384px;
        position: absolute;
        right: 20px;
        top: -83px;

        @include frst {
          max-width: 284px;
          right: 40px;
          top: -93px;
        }

        @include semi-tablet {
          max-width: 230px;
        }

        @include small-tablet {
          max-width: 190px;
        }

        @include mobile {
          right: 15px;
          top: -99px;
        }

        @include mini-mobile {
          max-width: 120px;
          right: 15px;
          top: -50px;
        }

      }

      &--2 {
        max-width: 260px;
        margin-right: 40px;

        @include frst {
          position: relative;
          max-width: 200px;
          // left: -50px;
        }

        @include semi-tablet {
          margin-right: 20px;
          max-width: 150px;
          order: 100;
        }

        @include small-tablet {
          max-width: 100px;
        }

        @include small-tablet {
          position: absolute;
          right: 10%;
          bottom: 10%;
          margin-right: 0;
        }

        @include mini-mobile {
          max-width: 100px;
          bottom: 45%;
        }
      }
    }

    &-sub-title {
      margin-bottom: 3px;
      font-weight: 600;
      // font-size: 48px;
      font-size: clamp(2.125rem, 1.5rem + 1.667vw, 3rem);
      color: var(--fd-orange);

      &--small {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 24px;
        color: var(--fd-orange);
      }
    }

    &-offer {
      margin-bottom: 10px;
      font-weight: 500;
      // font-size: 64px;
      font-size: clamp(2rem, 0.571rem + 3.81vw, 4rem);
      color: var(--fd-pale);
    }
  }
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, -1px, -1px) rotate(0.5deg);
  }

  20%,
  80% {
    transform: translate3d(2px, 2px, 2px) rotate(-0.5deg);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, -2px, -2px) rotate(0.5deg);
  }

  40%,
  60% {
    transform: translate3d(2px, 2px, 2px);
  }
}

@keyframes wiggle-shake {
  0% {
    transform: translate(2px, 0) rotate(0.5deg);
  }

  50% {
    transform: translate(-2px, 0) rotate(-0.5deg);
  }

  100% {
    transform: translate(2px, 0) rotate(0.5deg);
  }
}
