.dropdown__btn {
  position: relative;
  background-color: transparent;
  padding: 15px 15px 0 15px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: var(--white);
  border: none;
  z-index: 10;
}

.dropdown__btn-svg {
  padding-left: 5px;
  width: 100%;
  max-width: 18px;
  height: auto;
}

.dropdown {
  position: relative;
  display: inline-block;
}


.dropdown__content {
  display: none;
  position: absolute;
  padding-top: 50px;
  min-width: 160px;
  top: -5px;
  border-radius: 25px;
  border: 1px solid var(--fd-orange);
  background-color: transparent;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  backdrop-filter: blur(13px);
}

.dropdown__btn-item {
  display: block;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: var(--basic-white);
  text-decoration: none;
}


.dropdown__content a:hover {
  // background-color: #ddd;
}


.dropdown:hover .dropdown__content {
  display: block;
}


.dropdown:hover .dropdown__btn {
  // background-color: var(--fd-graphite);
}
