.text-img-card {

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;

    @include small-tablet {
      gap: 12px;
    }

    &-item {
      grid-column: 4 span;

      @include tablet {
        grid-column: 12 span;

        &:nth-child(1) {
          grid-column: 6 span;
          order: 1;
        }

        &:nth-child(3) {
          grid-column: 7/6 span;
          order: 2;
        }

        &:nth-child(2) {
          order: 3;
        }
      }

      @include mini-mobile {
        grid-column: 12 span;

        &:nth-child(1) {
          grid-column: 12 span;
          order: 1;
        }

        &:nth-child(3) {
          grid-column: 12 span;
          order: 3;
        }

        &:nth-child(2) {
          order: 2;
        }
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 50px;
    padding: 11%;
    height: 100%;

    @include tablet {
      padding: 7%;
    }

    &-svg {
      margin-bottom: 24px;

      @include mobile {
        margin-bottom: 12px;
      }
    }

    &-text {
      margin: 0;
      font-weight: 300;
      // font-size: 27px;
      font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
      text-align: center;
      color: var(--basic-white);
    }
  }
}
