.plan-card {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  padding-top: 80px;
  padding-bottom: 80px;
  grid-auto-rows: auto;
  border: 2px solid var(--fd-orange);
  border-radius: 25px;
  backdrop-filter: blur(3px);

  @include small-tablet {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  @include mobile {
    gap: 0;
  }

  &__title {
    font-size: clamp(2.25rem, 0.929rem + 6.607vw, 6.875rem);

    @include tablet {
      grid-column: 1 span;
    }

    @include small-tablet {
      grid-column: 12 span;
    }
  }

  &__content {
    grid-column: 9 span;
    display: flex;
    gap: 80px;
    border: 1px solid var(--fd-orange);
    border-radius: 25px;
    padding: 44px 41px;
    background-color: #26292d9c;
    width: 100%;

    @include tablet {
      grid-column: 2/10 span;
      gap: 24px;
    }

    @include semi-tablet {
      flex-direction: column;
      align-items: center;
    }

    @include mobile {
      grid-column: 12 span;
      background-color: transparent;
      border: none;
    }

    @include small-mobile {
      padding: 20px;
    }
  }

  &__image {
    width: 100%;
    max-width: 368px;
    height: auto;
    border-radius: 23px;
    vertical-align: bottom;
  }

  &__content-list {
    width: calc(50% - 12px);

    @include semi-tablet {
      width: 80%;
    }

    @include small-tablet {
      width: 100%;
    }

    &-item {

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  &__content-text {
    display: inline-block;
    position: relative;
    padding-left: 20px;
    font-weight: 500;
    font-size: 24px;
    color: var(--white);

    &::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 15px;
      width: 5px;
      height: 5px;
      border-radius: 25px;
      background-color: var(--white);
      transform: translateY(-50%);
    }
  }

  &__time,
  &__price,
  &__center {
    position: relative;
    padding: 34px 38px;
    border: 1px solid var(--fd-voilet);
    border: 1px solid var(--fd-orange);
    border-radius: 25px;
    background-color: #26292d9c;

    min-height: 229px;

    @include mobile {
      min-height: unset;
      min-height: 170px;
      background-color: transparent;
      border: none;
    }

    @include small-mobile {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__time {

    @include mobile {
      padding-top: 40px;
      padding-bottom: 10px;
    }
  }

  &__price {

    @include mobile {
      padding-top: 0;
      padding-bottom: 60px;
    }
  }

  &__btn {
    padding: 17px 50px;
    font-weight: 500;
    // font-size: 36px;
    font-size: clamp(1rem, 0.643rem + 1.786vw, 2.25rem);
    letter-spacing: 0.05em;
    text-align: center;
  }

  &__time,
  &__price,
  &__center {
    grid-column: 3 span;
  }

  &__btn {
    grid-column: 3/9 span;
  }

  &__center {
    display: flex;
    align-items: center;

    &-svg {
      width: 100%;
      max-width: 282px;
      height: auto;
    }

    @include tablet {
      display: none;
    }
  }

  &__time,
  &__price {

    @include tablet {
      grid-column: 5 span;
    }

    @include mobile {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 24px;
      row-gap: 0;
    }
  }

  &__time {
    @include small-tablet {
      grid-column: 2/5 span;
    }

    @include mobile {
      grid-column: 12 span;
    }
  }

  &__price {

    @include small-tablet {
      grid-column: 5 span;
    }

    @include mobile {
      grid-column: 12 span;
    }
  }

  &__btn {

    @include tablet {
      padding: 50px;
      grid-column: 2/10 span;
    }

    @include mobile {
      padding: 40px;
    }
  }

  &__time-title,
  &__price-title {
    position: relative;
    margin-bottom: 22px;
    padding-top: 15px;
    font-weight: 600;
    font-size: 36px;
    letter-spacing: 0.05em;
    color: var(--white);
    z-index: 2;

    @include frst {
      margin-bottom: 40px;
    }

    @include mobile {
      margin-bottom: 20px;
    }

    @include mobile {
      grid-column: 4/8 span;
    }
  }

  &__time-text,
  &__price-text {
    position: relative;
    margin: 0;
    font-weight: 300;
    font-size: 24px;
    color: var(--white);
    z-index: 2;

    @include mobile {
      grid-column: 4/8 span;
    }
  }

  &__price {

    &-text {
      width: 76%;

      @include mobile {
        width: 100%;
      }
    }
  }

  &__img {
    position: absolute;
    right: 10%;
    top: 10%;
    z-index: 1;
    width: 100%;
    max-width: 80px;
    height: auto;

    @include small-tablet {
      max-width: 80px;
    }

    @include mobile {
      position: static;
      align-self: center;
      grid-column: 1/3 span;
      grid-row: 1/2 span;
    }
  }

  &-services {
    padding: 40px 40px;
    border: 1px solid var(--fd-dark-orange);
    border-radius: 25px;
    height: 100%;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;

    @include semi-tablet {
      justify-content: space-around;
    }

    @include small-mobile {
      padding: 40px 30px;
    }

    &__title {
      margin: 0;
      width: 89%;

      @include frst {
        width: 100%;
      }

      @include big-mobile {
        text-align: center;
      }
    }

    &__img {
      width: 50%;
      border-radius: 25px;
      width: 100%;
      max-width: 220px;
      height: auto;
    }

    &__list {
      width: 50%;

      @include frst {
        width: 100%;
      }

      @include semi-tablet {
        width: 50%;
      }

      @include big-mobile {
        width: 100%;
      }

      &-item {

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      &-text {
        position: relative;
        margin: 0;
        padding-left: 15px;
        font-weight: 500;
        font-size: 16px;
        color: var(--white);

        &::after {
          content: '';
          position: absolute;
          left: 2px;
          top: 8px;
          width: 5px;
          height: 5px;
          border-radius: 25px;
          background-color: var(--white);
          transform: translateY(-50%);
        }
      }
    }

    &__offer {
      width: 89%;
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0.05em;
      color: var(--fd-orange);

      @include big-mobile {
        width: 100%;
      }

      & span {
        font-weight: 400;
        color: var(--white);
      }
    }

    &__btn {

      @include small-mobile {
        padding: 17px 50px;
      }
    }
  }
}
