.why-cyber {

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;

    @include small-tablet {
      gap: 12px;
    }

    &-item {
      grid-column: 6 span;

      @include mobile {
        grid-column: 12 span;
      }

      &--big {
        grid-column: 12 span;
      }
    }
  }

  &__card {
    padding: 9%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;

    @include mobile {
      padding: 5%;
    }


    &--1 {
      background-image: image-set(url('/img/why-cyber-1.webp') 1x,
      url('/img/why-cyber-1_2x.webp') 2x);
    }

    &--2 {
      background-image: image-set(url('/img/why-cyber-2.webp') 1x,
      url('/img/why-cyber-2_2x.webp') 2x);
    }

    &--3 {
      background-image: image-set(url('/img/why-cyber-3.webp') 1x,
      url('/img/why-cyber-3_2x.webp') 2x);

      @media (max-width:880px) {
        background-size: cover;
      }

      @media (max-width:576px) {
        // background-size: contain;
      }
    }

    &-title {
      margin-bottom: 40px;
      font-weight: 700;
      // font-size: 230px;
      font-size: clamp(7.188rem, 2.054rem + 13.69vw, 14.375rem);
      color: var(--fd-pale-orange);
      text-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.55);

      @include mobile {
        margin-bottom: 20px;
      }
    }

    &-descr {
      font-weight: 500;
      // font-size: 48px;
      font-size: clamp(1.5rem, 0.429rem + 2.857vw, 3rem);
      line-height: 110%;
      letter-spacing: 0.05em;
      color: var(--fd-pale);
    }
  }
}

