.form {
  position: relative;
  margin-bottom: -250px;
  padding-bottom: 100px;
  z-index: 5;

  &__container {
    padding-top: 200px;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    @include mini-tablet {
      padding-top: 100px;
    }
  }

  &__title {
    writing-mode: vertical-lr;
    transform: rotate(180deg);

    @include small-tablet {
      grid-column: 1 span;
    }

    @include mini-mobile {
      grid-column: 12 span;
      margin-bottom: 20px;
      writing-mode: unset;
      transform: unset;
    }

  }

  &__form {
    grid-column: 4/7 span;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include small-tablet {
      grid-column: 3/9 span;
    }

    @include mini-mobile {
      grid-column: 12 span;
      }
  }

  &__label {
    width: 100%;

    &-checkbox {
      width: unset;
      font-weight: 400;
      // font-size: 15px;
      font-size: clamp(0.75rem, 0.696rem + 0.268vw, 0.938rem);
      letter-spacing: 0.05em;
      color: var(--white);
    }
  }

  &__input {
    margin-bottom: 40px;
    padding: 30px 40px;
    border-radius: 25px;
    width: 100%;
    font-weight: 400;
    // font-size: 30px;
    font-size: clamp(1.25rem, 1.071rem + 0.893vw, 1.875rem);
    letter-spacing: 0.05em;
    color: var(--fd-dark);
    border: 2px solid var(--white);
    transition: border-color .3s ease-in-out, color .3s ease-in-out;

    @include big-mobile {
      margin-bottom: 20px;
      padding: 15px 20px;
      border-radius: 25px;
    }

    &:focus {
      outline: none;
    }

    @include hover {
      border-color: var(--fd-orange);

      &::placeholder {
        color: var(--fd-orange);
        transition: color .3s ease-in-out;

      }
    }

    &:active {
      border: 2px dashed var(--fd-orange);
    }

    &::placeholder {
      font-weight: 400;
      // font-size: 30px;
      font-size: clamp(1.25rem, 1.071rem + 0.893vw, 1.875rem);
      letter-spacing: 0.05em;
      color: var(--fd-dark);
    }
  }

  &__btn {
    margin-bottom: 30px;
    padding: 30px;
    width: 100%;
    font-weight: 500;
    // font-size: 40px;
    font-size: clamp(1rem, 1.214rem + 1.429vw, 2.5rem);
    letter-spacing: 0.05em;
    text-align: center;
    color: var(--fd-graphite);
    background-color: var(--fd-orange);
    border: 2px solid var(--white);
    border-radius: 25px;
    transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out;

    @include big-mobile {
      padding: 15px;
      border-radius: 25px;
    }

    &:focus {
      outline: none;
    }

    @include hover {
      color: var(--fd-orange);
      background-color: var(--fd-graphite);
      border-color: var(--fd-orange);
    }

    &:active {
      background-color: var(--orange);
      border-color: var(--white);
      color: var(--white);
    }
  }

  &__link {
    font-weight: 600;
    // font-size: 15px;
    font-size: clamp(0.75rem, 0.696rem + 0.268vw, 0.938rem);
    letter-spacing: 0.05em;
    color: var(--white);
    text-decoration: underline;
  }
}

.form__input.focus-visible {
  border: 2px dashed var(--fd-orange);
}

.form__btn.focus-visible {
  outline: 2px solid var(--fd-orange);
  outline-offset: 3px;
}
