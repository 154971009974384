.footer {
  padding-top: 300px;
  background-image: image-set(url('../img/footer-bg.webp') 1x,
      url('../img/footer-bg_2x.webp') 2x);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: 100px;
  // background-color: #13181E;
  transition: all .3s linear;

  @include mini-tablet {
    padding-top: 150px;
  }

  &__container {
    display: flex;
    justify-content: space-between;

    @include mini-tablet {
      flex-direction: column;
    }
  }

  &__list {

    @include mini-tablet {
      margin-bottom: 40px;
    }

    &-item {

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    &-link {
      font-weight: 400;
      font-size: 24px;
      letter-spacing: 0.05em;
      color: var(--white);
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    @include mini-tablet {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__logo {
    display: flex;
    margin-bottom: 30px;
  }

  &__logo>img {
    // margin-bottom: 30px;
    width: 100%;
    max-width: 140px;
    height: auto;
  }

  &__text {
    text-align: right;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    color: var(--white);

    @include mini-tablet {
      text-align: left;
    }
  }

  &__link {
    margin-bottom: 0;
    color: var(--fd-pale-orange);
  }
}
