html {
  box-sizing: border-box;
  background-color: #000000;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  color: var(--white);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  position: relative;
  // position: fixed;
  // top: 0;
  // right: 0;
  // width: 100%;
  // height: 100%;
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  position: relative;
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
  z-index: 100;



  &::before {
    background: image-set(url('/img/body-background.webp') 1x,
    url('/img/body-background_2x.webp') 2x);
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: -1;
  }
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.mb-25 {
  margin-bottom: 25px;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  padding-top: 100px;
  padding-bottom: 100px;
  max-width: var(--container-width);

  @include small-mobile {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.orange-title {
  font-weight: 500;
  font-size: clamp(1rem, -0.018rem + 5.089vw, 4.563rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}

.hero-title {
  font-weight: 500;
  font-size: 110px;
  font-size: clamp(2.25rem, 0.929rem + 6.607vw, 6.875rem);
  line-height: 109%;
  color: var(--fd-pale);
}

.section-title {
  margin: 0;
  margin-bottom: 60px;
  font-weight: 400;
  font-size: clamp(2.3rem, -0.679rem + 8.393vw, 6.875rem);
  color: var(--fd-pale);

  @include small-tablet {
    margin-bottom: 30px;
  }
}

.middle-title {
  font-weight: 600;
  // font-size: 73px;
  font-size: clamp(2rem, 1.268rem + 3.661vw, 4.563rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}

.horizontal-title {
  grid-column: 2 span;
  grid-row: 2 span;
  letter-spacing: 0.05em;
  text-align: center;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-weight: 600;
  // font-size: 110px;
  font-size: clamp(2.25rem, 0.929rem + 6.607vw, 6.875rem);


  @include small-tablet {
    writing-mode: unset;
    transform: unset;
  }
}

.card-container {
  border-radius: 25px;
  border: 2px solid var(--fd-orange);
  border-radius: 25px;
  backdrop-filter: blur(3px);
  background: rgba(39, 46, 57, 0.35);
  border-radius: 25px!important;

  @include small-tablet {
    border-radius: 25px !important;
  }
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  // padding: 25px 25px 0px 25px;
  padding: 50px 30px;
  padding-top: 75px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // height: 100vh;
  width: 100vw;
  background: #131313;
  background-image: image-set(url(/img/body-background.webp) 1x, url(/img/body-background_2x.webp) 2x);
  background-size: 100%;
  // background-attachment: fixed;
  z-index: -10;
  opacity: 0;
  overflow-x: hidden;


  @include mini-tablet {
    padding-top: 50px;
  }

  @include mini-mobile {
    padding: 50px 15px;
  }
}

.overlay.active {
  z-index: 9999;
  opacity: 1;
}

.overlay.active .header__planet {
  width: 1500px;
  height: 1500px;
  opacity: 1;

  @include small-tablet {
    width: 750px;
    height: 750px;
  }
}

.overlay.active .header__modal {
  opacity: 1;
}

.link-orange {
  transition: color .3s ease-in-out;

  &:focus {
    outline: none;
  }

  @include hover {
    color: var(--fd-orange);

    & svg path {
      fill: var(--fd-orange);
      transition: fill .3s ease-in-out;
    }
  }

  &:active {
    color: var(--orange);
  }
}

.inside-link {
  font-weight: 300;
  // font-size: 48px;
  font-size: clamp(1.375rem, 0.214rem + 3.095vw, 3rem);
  color: var(--white);
  text-decoration: underline;
  transition: color .3s ease-in-out;

  @include hover {
    color: var(--fd-orange);
  }
}

.link-white {
  transition: color .3s ease-in-out;

  &:focus {
    outline: none;
  }

  @include hover {
    color: var(--white);
  }

  &:active {
    color: var(--orange);
  }
}


.link-orange.focus-visible {
  outline: 1px solid var(--fd-orange);
  border-radius: 3px 3px 3px 3px;
  outline-offset: 3px;
}

.close {
  $size: 60px;
  $borderSize: 3px;
  $borderColor: #000000;
  $speed: .5s;
  width: $size;
  height: $size;
  position: relative;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0 0 20px -5px rgba(255, 151, 55, .5);
  transition: .25s ease-in-out;
  cursor: pointer;
  animation: fade-in $speed ease-out .25s both;

  @keyframes fade-in {
    from {
      opacity: 0;
      transform: scale(1.1)
    }
  }

  .circle {
    path {
      stroke: $borderColor;
      fill: none;
      stroke-width: $borderSize/2;
      stroke-linecap: round;
      animation: progress $speed ease-out .25s both;

      @keyframes progress {
        from {
          stroke-dasharray: 0 100;
        }
      }
    }
  }

  span {
    display: block;
    width: ($size/4) - 2px;
    height: $borderSize;
    background: $borderColor;
    box-shadow: 0 0 20px -5px rgba(255, 151, 55, .5);
    border-radius: 20px;
    position: absolute;
    $padding: $size/3;
    transition: .25s ease-in-out;
    animation: slide-in $speed ease-in-out .25s both;

    @keyframes slide-in {
      from {
        width: 0;
      }
    }

    &:nth-child(1) {
      top: ($padding - ($borderSize/2));
      left: $padding;
      transform: rotate(45deg);
      transform-origin: top left;
    }

    &:nth-child(2) {
      top: ($padding - ($borderSize/2));
      right: $padding;
      transform: rotate(-45deg);
      transform-origin: top right;
    }

    &:nth-child(3) {
      bottom: ($padding - ($borderSize/2));
      left: $padding;
      transform: rotate(-45deg);
      transform-origin: bottom left;
    }

    &:nth-child(4) {
      bottom: ($padding - ($borderSize/2));
      right: $padding;
      transform: rotate(45deg);
      transform-origin: bottom right;
    }
  }

  &:hover {
    background-color: transparent;

    span {
      background-color: var(--fd-orange);
      width: ($size/4);
    }

    .circle {
      path {
        stroke: var(--fd-orange);
        transition: stroke .3s ease-in-out;
      }
    }
  }
}

#canvasOne {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

@mixin sp-layout {
  @media screen and (max-width: 750px) {
    @content;
  }
}

.planet-canvas {
  position: absolute;
  right: 0;
  top: 50px;
}

// comets start
#comets {
  position: absolute;
  top: -150px;
  width: 100vw;
  text-align: left;
  height: 100%;
  min-height: 900px;
  overflow: hidden;
}

#comets i {
  display: inline-block;
  width: 250px;
  height: 150px;
  position: absolute;
  border-radius: 5% 40% 70%;
  box-shadow: inset 0px 0px 1px #294b67;
  border: 1px solid #333;
  z-index: 1;
  background-color: #fff;
  opacity: .7;
  -webkit-animation: falling 10s 0s infinite;
  -webkit-animation-timing-function: ease-in;
}

#comets>img {
  display: inline-block;
  width: 47px;
  height: 116px;
  position: absolute;
  z-index: 1;
  -webkit-animation: falling 10s 10s infinite;
  -webkit-animation-timing-function: ease-in;
  transform: rotate(180deg);
}

#comets i:nth-child(1) {
  left: 50vw;
  height: 73px;
  width: 3px;
  background-color: #fff;
}

#comets i:nth-child(3) {
  height: 11px;
  width: 3px;
  -webkit-animation: falling3 8s 3s infinite;
  left: 10vw;
  background-color: #fff;
}

#comets i:nth-child(2) {
  -webkit-animation: falling2 6s 1s infinite;
  left: 30vw;
  height: 70px;
  width: 4px;
  background-color: #fff;

}

#comets>img {
  -webkit-animation: falling4 7s infinite;
  left: 30vw;
  height: 119px;
  width: 47px;
  // background-color: #fff;

}

@-webkit-keyframes falling4 {

  0% {
    -webkit-transform: translate3d(100px, 0px, 0px) rotate(160deg);
  }

  50% {
    -webkit-transform:
      translate3d(450px, 900px, 0) rotate(160deg);
    opacity: 0;
  }

  100% {
    -webkit-transform:
      translate3d(450px, 900px, 0) rotate(160deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling {

  0% {
    -webkit-transform: translate3d(100px, 0px, 0px) rotate(160deg);
  }

  3% {
    -webkit-transform:
      translate3d(450px, 900px, 0) rotate(160deg);
    opacity: 0;
  }

  100% {
    -webkit-transform:
      translate3d(450px, 900px, 0) rotate(160deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(150deg);
  }

  10% {
    -webkit-transform: translate3d(430px, 640px, 0) rotate(150deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate3d(430px, 640px, 0) rotate(150deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling2 {
  0% {
    -webkit-transform: translate3d(100px, 0, 0) rotate(130deg);
  }

  15% {
    -webkit-transform: translate3d(800px, 580px, 0) rotate(130deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate3d(800px, 680px, 0) rotate(180deg);
    opacity: 0;
  }
}

// comets end

// kens burn start
.body-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  -ms-animation: move 80s infinite ease;
  -webkit-animation: move 80s infinite ease;
  -moz-animation: move 80s infinite ease;
  position: absolute;
}


@-webkit-keyframes move {
  0% {
    // -webkit-transform-origin: bottom left;
    // -moz-transform-origin: bottom left;
    // -ms-transform-origin: bottom left;
    // -o-transform-origin: bottom left;
    // transform-origin: bottom left;
    transform: scale(1.0);
    -ms-transform: scale(1.0);
    /* IE 9 */

    -webkit-transform: scale(1.0);
    /* Safari and Chrome */

    -o-transform: scale(1.0);
    /* Opera */

    -moz-transform: scale(1.0);
    /* Firefox */
  }

  50% {
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    /* IE 9 */

    -webkit-transform: scale(1.1);
    /* Safari and Chrome */

    -o-transform: scale(1.1);
    /* Opera */

    -moz-transform: scale(1.1);
    /* Firefox */
  }

  100% {
    // -webkit-transform-origin: bottom left;
    // -moz-transform-origin: bottom left;
    // -ms-transform-origin: bottom left;
    // -o-transform-origin: bottom left;
    // transform-origin: bottom left;
    transform: scale(1.0);
    -ms-transform: scale(1.0);
    /* IE 9 */

    -webkit-transform: scale(1.0);
    /* Safari and Chrome */

    -o-transform: scale(1.0);
    /* Opera */

    -moz-transform: scale(1.0);
    /* Firefox */
  }
}

// kens burn end
