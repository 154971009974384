.gototop {
  position: fixed;
  display: block;
  bottom: 7%;
  right: 50px;
  width: 60px;
  height: 106px;
  margin-right: -30px;
  outline: none;
  opacity: 0;
  transition: opacity 0.3s ease, margin-right 0.5s ease-out;
  cursor: pointer;
  z-index: 999;

  @include hover {
    animation: shake-uptotop 0.5s ease infinite;
  }

  @include big-desktop {
    right: 30px;
  }

  @include small-tablet {
    right: 15px;
    width: 40px;
    height: 71px;
  }

  &--visible {
    transition: opacity 0.3s ease, margin-right 0.5s ease-out;
    margin-right: 0px;
    opacity: 1;
  }

  &__img {
    width: 100%;
    max-width: 60px;
    height: auto;

    @include small-tablet {
      max-width: 40px;
    }
  }

  &-fire__img {
    position: absolute;
    right: 49%;
    bottom: 0%;
    transform: translateX(49%);
    z-index: -1;
    opacity: 0;

    @include small-tablet {
      max-width: 17px;
      right: 47%;
    }

    &--visible {
      animation: takeoff .3s linear infinite;
    }
  }
}

@keyframes takeoff {
  0% {
    opacity: 1;
    transform: translateY(0%) translateX(49%) rotate(0deg);
  }

  50% {
    opacity: 1;
    transform: translateY(50%) translateX(49%) rotate(-1deg);
  }

  100% {
    opacity: 1;
    transform: translateY(80%) translateX(49%) rotate(1deg);
  }
}


@keyframes shake-uptotop {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
