.burger {
  --burger-width: 90px;
  --burger-height: 40px;
  --burger-line-height: 5px;

  position: relative;
  border: none;
  margin-right: 10px;
  padding: 0;
  width: var(--burger-width);
  height: var(--burger-height);
  color: var(--white);
  background-color: transparent;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: var(--burger-line-height);
    background-color: currentColor;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;

    @include small-tablet {
      left: unset;
      right: 0;
    }
  }

  &::before {
    top: 0;
    width: 50%;
  }

  &::after {
    top: calc(100% - var(--burger-line-height));
  }

@include hover {

  &__line {
    background-color: var(--fd-orange);
  }
}

  &__line {
    position: absolute;
    left: 0;
    top: 50%;
    width: 70%;
    height: var(--burger-line-height);
    background-color: currentColor;
    transform: translateY(-50%);
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;

    @include small-tablet {
      left: unset;
      right: 0;
    }
  }

  &--active {
    &::before {
      top: 50%;
      transform: rotate(45deg);
      transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
    }

    &::after {
      top: 50%;
      transform: rotate(-45deg);
      transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
    }

    .burger__line {
      transform: scale(0);
      transition: transform 0.3s ease-in-out;
    }
  }
}
