.text {
position: relative;
z-index: 2;

  &__container {
    padding-top: 200px;

    @include small-mobile {
      padding-top: 100px;
    }
  }

  &__text {
    margin: 0;
    font-weight: 300;
    // font-size: 48px;
    font-size: clamp(1.375rem, 0.214rem + 3.095vw, 3rem);
    color: var(--white);

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__link {
    font-weight: 300;
    // font-size: 48px;
    font-size: clamp(1.375rem, 0.214rem + 3.095vw, 3rem);
    color: var(--white);
    text-decoration: underline;
    transition: color .3s ease-in-out;

    @include hover {
      color: var(--fd-orange);
    }
  }
}
