.odds {

  &__content {
    padding: 80px 0;

    @include mobile {
      padding: 40px 0;
      border: none;
      backdrop-filter: unset;
      background-color: transparent;
    }

    @include mini-mobile {
      padding: 20px 0;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;
    row-gap: 60px;

    @include small-mobile {
      row-gap: 40px;
    }

    &-item {
      grid-column: 2/10 span;

      @include mobile {
        grid-column: 12 span;
        border: 2px solid var(--fd-orange);
        border-radius: 25px;
      }
    }

    &-title {
      margin-bottom: 5px;
      font-weight: 600;
      // font-size: 36px;
      font-size: clamp(1.125rem, 0.321rem + 2.143vw, 2.25rem);
      letter-spacing: 0.05em;
      color: var(--fd-pale-orange);
    }

    &-descr {
      font-weight: 300;
      // font-size: 24px;
      font-size: clamp(1rem, 0.643rem + 0.952vw, 1.5rem);
      color: var(--basic-white);
    }
  }

  &__link {
    margin: 0;
    margin-bottom: 60px;
    font-weight: 300;
    // font-size: 27px;
    font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
    color: var(--basic-white);
    text-decoration: underline;
  }

  &__card {
    display: flex;
    padding: 68px 68px;
    border: none;
    gap: 24px;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: right bottom;

    @include small-tablet {
      flex-direction: column;
      padding: 34px;
    }

    @include big-mobile {
      row-gap: 20px;
    }

    @include mini-mobile {
      padding: 15px;
    }

    &-lite {
      padding: 80px;
      flex-direction: column;
      gap: unset;
      justify-content: unset;

      @include tablet {
        padding: 60px;
      }

      @include small-tablet {
        padding: 40px;
      }

      @include small-mobile {
        padding: 40px 20px;
      }

      & .odds__card-list-text {
        width: 55%;

        @include tablet {
          width: 65%;
        }

        @include small-mobile {
          width: 100%;
        }
      }

      &--1 {
        background-image: image-set(url('/img/proposal-lite-1.webp') 1x,
            url('/img/proposal-lite-1_2x.webp') 2x);
      }

      &--2 {
        background-image: image-set(url('/img/proposal-lite-2.webp') 1x,
            url('/img/proposal-lite-2_2x.webp') 2x);
      }

      &--3 {
        background-image: image-set(url('/img/proposal-lite-3.webp') 1x,
            url('/img/proposal-lite-3_2x.webp') 2x);
      }

      &--4 {
        background-image: image-set(url('/img/proposal-lite-4.webp') 1x,
            url('/img/proposal-lite-4_2x.webp') 2x);
      }

      &--5 {
        background-image: image-set(url('/img/proposal-lite-5.webp') 1x,
            url('/img/proposal-lite-5_2x.webp') 2x);
      }

      &--6 {
        background-image: image-set(url('/img/proposal-lite-6.webp') 1x,
            url('/img/proposal-lite-6_2x.webp') 2x);
      }

      &--7 {
        background-image: image-set(url('/img/proposal-lite-7.webp') 1x,
            url('/img/proposal-lite-7_2x.webp') 2x);
      }

      &--8 {
        background-image: image-set(url('/img/proposal-lite-8.webp') 1x,
            url('/img/proposal-lite-8_2x.webp') 2x);
      }

    }


    &-title {
      margin-bottom: 47px;
      font-size: clamp(1.75rem, -0.259rem + 5.357vw, 4.563rem);
      line-height: 104%;

      @include small-tablet {
        margin-bottom: 23px;
      }
    }

    &-text {
      margin: 0;
      margin-bottom: 60px;
      font-weight: 300;
      // font-size: 27px;
      font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
      color: var(--basic-white);
    }

    &-left {
      width: 70%;

      @include small-tablet {
        display: contents;
      }
    }

    &-right {
      width: 30%;

      @include small-tablet {
        display: contents;
      }
    }

    &-btn {

      @include small-tablet {
        order: 9;
      }
    }

    &-list {
      margin-bottom: 60px;

      @include small-tablet {
        margin-bottom: 30px;
      }

      &-item {

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        &--small {

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          @include small-tablet {
            margin-bottom: 30px;
          }
        }
      }

      &-text {
        margin: 0;
        font-weight: 300;
        font-size: 27px;
        font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
        color: var(--basic-white);
      }

      &-link {
        color: var(--white);
        text-decoration: underline;
      }
    }
  }
}


// .proposal-corporation {

//   & .proposal__list {

//     @include mobile {
//       row-gap: 40px;
//     }
//   }
// }
