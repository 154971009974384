.about {

  &__content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 30px;
    padding: 5% 8.6%;
  }

  &__title {
    grid-column: 12 span;
  }

  &__list {
    grid-column: 6 span;

    @include small-tablet {
      grid-column: 12 span;
    }

    &-item {
      width: 85%;
      margin-bottom: 40px;

      @include small-tablet {
      margin-bottom: 20px;
        width: 100%;

      }

      @include small-mobile {
      //  width: 75%;
      }
    }

    &-text {
      display: inline-block;
      margin: 0;
      font-weight: 500;
      // font-size: 24px;
      font-size: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
      color: var(--white);

      @include small-tablet {

      }
    }
  }


}
