.getting {

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;

    @include tablet {
      gap: 14px;
    }

    &-item {
      grid-column: 4 span;

      @include mini-tablet {

        &:nth-child(1),
        &:nth-child(2) {
          grid-column: 6 span;
        }

        &:nth-child(3) {
          grid-column: 12 span;

          &>.getting__card {
            flex-direction: row !important;
          }
        }
      }

      &--big {
        grid-column: 6 span;
      }
    }
  }

  &__card {
    padding: 8% 6%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    height: 100%;

    @include tablet {
      gap: 14px;
    }

    @include small-tablet {
      &:not(.getting__list-item--big>.getting__card) {
        flex-direction: column;
        text-align: center;
      }

      .getting-target .getting__list-item--big {
        grid-column: 12 span !important;
      }
    }

    @include big-mobile {
      flex-direction: column;
      text-align: center;
    }

    &-svg {
      width: 100%;
      max-width: 95px;
      height: auto;

      @include small-tablet {
        max-width: 60px;
      }
    }

    &-text {
      margin: 0;
      font-weight: 300;
      // font-size: 27px;
      font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
      color: var(--basic-white);
    }
  }
}


@include mini-tablet {

  .getting-corporation .getting__list-item--big,
  .getting-shop .getting__list-item--big {

    &:not(:nth-child(-n+2)) {
      grid-column: 12 span;

      &>.getting__card {
        flex-direction: row;
      }
    }
  }

  .getting-target .getting__list-item--big {
    grid-column: 6 span!important;

    &>.getting__card {
      flex-direction: column!important;
    }
  }
}

@include small-mobile {

  .getting-soprovozhdenie .getting__list-item--big {
    grid-column: 12 span;

    &>.getting__card {
      flex-direction: row;
    }
  }
}

@include mini-mobile {

  .getting-corporation .getting__list-item--big,
  .getting-shop .getting__list-item--big {
    grid-column: 12 span;

    &>.getting__card {
      flex-direction: row;
    }
  }
}

.getting-smm .getting__list-item {
  grid-column: 4 span;

  @include semi-tablet {
    grid-column: 6 span;
  }

  @include mini-tablet {
    &>.getting__card {
      flex-direction: column !important;
    }
  }

  @include mini-mobile {
    grid-column: 12 span;

    &>.getting__card {
      flex-direction: row !important;
    }
  }
}

@include mini-mobile {
  
  .getting.getting-target>.getting__container .getting__list-item--big {
    grid-column: 12 span!important;

    &>.getting__card {
      flex-direction: row!important;
    }
  }
}
