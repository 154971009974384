// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Gilroy", sans-serif;
  --content-width: 1420px;
  --container-offset: 10px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --fd-graphite: #13181e;
	--fd-dark: #2b2b2b;
	--dark-blue: #272e39;
	--fd-voilet: #46367c;
	--fd-dark-orange: #8a4200;
	--fd-orange: #ff9737;
	--fd-pale-orange: #d39964;
	--fd-ocre: #887a6e;
	--fd-pale: #fff3e9;
	--white: #fff;
	--megalight-grey: #f8f8f8;
	--orange: #ff7510;
	--dark: #303030;
	--light-grey: #bfbfbf;
	--superlight-grey: #dedede;
  --gradient: linear-gradient(347deg, #fd9637 0%, #46367c 100%);
  --glow-color: 	hsl(29, 100%, 61%);


}
