.hero {
  padding-top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &__container {
    position: relative;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
    margin-bottom: 70px;
    padding-top: 150px;
    padding-bottom: 200px;
    font-family: var(--font-family);
    font-weight: 600;
    // font-size: clamp(1rem, -0.679rem + 8.393vw, 6.875rem);
    font-size: clamp(2.25rem, 0.929rem + 6.607vw, 6.875rem);
    line-height: 109%;
    color: var(--fd-pale);

    @include frst {
      padding-top: 0;
    }
    @include small-tablet {
      padding-bottom: 100px;
    }
  }

  &__stock {
    display: flex;
    gap: 25px;
    border-radius: 25px;
    transform-style: preserve-3d;

    @include mini-tablet {
      justify-content: center;
      flex-direction: column;
    }

    &-img {
      border: 2px solid var(--fd-voilet);
      border-radius: 25px;
      overflow: hidden;
      transition: transform .3s ease-in-out;

      & img {
        vertical-align: middle;
        height: 100%;
      }

      @include mini-tablet {
        align-self: center;
        width: 55%;
      }
    }

    &-content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding: 50px 15px;
      width: 100%;
      border: 2px solid var(--fd-orange);
      border-radius: 25px;
      backdrop-filter: blur(3px);
      transition: transform .3s ease-in-out;
      transform-style: preserve-3d;
      perspective: 1000px;

      &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        // font-size: clamp(1rem, -0.518rem + 7.589vw, 6.313rem);
        font-size: clamp(2rem, 0.768rem + 6.161vw, 6.313rem);
        text-transform: uppercase;
        transform: translateZ(20px);

        @include small-tablet {
          flex-direction: column;
        }

        &--small {
          padding-left: 45px;
          font-weight: 400;
          font-size: clamp(1.5rem, 0.643rem + 1.786vw, 2.25rem);
          text-transform: none;
          transform: translateZ(20px);
          width: 59%;

          @include tablet {
            width: 50%;
          }

          @include small-tablet {
            width: 100%;
            padding-left: 25px;
          }

          @include mini-tablet {
            padding-left: 0;
            text-align: center;
          }
        }

        @include small-tablet {
          margin-bottom: 10px;
        }
      }

      &-descr {
        font-weight: 300;
        font-size: clamp(2.07rem, -0.518rem + 7.589vw, 6.313rem);
        color: var(--fd-pale);
        transform: translateZ(20px);
      }
    }
  }

  &__planet {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 500px;
    width: 100px;
    background-image: url('/img/hero-background.webp');
  }
}

//progressBar

// .progress {
//   position: fixed;
//   top: 0px;
//   // background-color: #ffebd3;
//   width: 100vw;
//   height: 3px;
//   filter: blur(3px);
//   z-index: 10000000000000000;

//   .fill {
//     background: linear-gradient(45deg,
//         #ff0000 0%,
//         #ff9a00 10%,
//         #d0de21 20%,
//         #4fdc4a 30%,
//         #3fdad8 40%,
//         #2fc9e2 50%,
//         #1c7fee 60%,
//         #5f15f2 70%,
//         #ba0cf8 80%,
//         #fb07d9 90%,
//         #ff0000 100%) repeat 0% 0% / 300% 100%;
//     height: 100%;
//     animation: rgb 6s linear infinite;
//   }
// };

// @keyframes rgb {
//   0% {background-position: 0% 50%;}
//   50% {background-position: 100% 50%;}
//   100% {background-position: 0% 50%;}
// }

//cursor
// .small{
//   position: absolute;
//   width: 6px;
//   height: 6px;
//   border: 2px solid #fff;
//   border-radius: 50%;
// }
// .big{
//   position: absolute;
//   width: 50px;
//   height: 50px;
//   background-color: white;
//   border-radius: 50%;
//   margin-bottom: 20rem;
//   animation-name: stretch;
//             animation-duration: 2.0s;
//             animation-timing-function: ease-out;
//             animation-direction: alternate;
//             animation-iteration-count: infinite;
//             animation-play-state: running;
// }


// @keyframes stretch {
//             0% {

//                 opacity: 0.2;
//                 background-color: green;
//                 border-radius: 100%;
//             }

//             50% {
//                 background-color: orange;
//             }

//             100% {
//                 //transform: scale(2.0);
//                 background-color: red;
//             }
//         }


// body {
//   cursor: url('/img/comet.png'), auto;
//   // mix-blend-mode: difference;
//  }


// .mscursor-cursor {
//   pointer-events: none;
//   position: fixed;
//   display: block;
//   border-radius: 0;
//   top: 0;
//   left: 0;
//   z-index: 9999999999999999999999;
// }
// .mscursor-difference{
//   mix-blend-mode: difference;
// }
// .mscursor-circle {
//     transform-origin: center;
//     position: absolute;
//     display: block;
//     width: 26px;
//     height: 26px;
//     border-radius: 20px;
// }
// .mscursor-border-transform{
//   animation: border-transform 3s linear infinite;
// }
// @keyframes border-transform {
//   0%,
//   100% {
//     border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
//   }
//   14% {
//     border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
//   }
//   28% {
//     border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
//   }
//   42% {
//     border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
//   }
//   56% {
//     border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
//   }
//   70% {
//     border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
//   }
//   84% {
//     border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
//   }
// }
// .mscursor-scale{
//   transition: .5s ease;
//   transform: scale(1.2);
// }
// .mscursor-scale-outline{
//   transform:scale(1.9);
//   background-color: transparent !important;
// }

// .mscursor-nocursor{
//   cursor:none!important;
// }
