.brif {
  padding: 90px 122px;
  border: 1px solid var(--fd-dark-orange);
  border-radius: 25px;
  backdrop-filter: blur(3px);
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  @include tablet {
    padding: 60px;
  }

  @include small-tablet {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @include mobile {
    padding: 40px 30px;
  }

  &__title {
    width: 100%;

    @include mobile {
      text-align: center;
    }
  }

  & picture {
    width: calc(30% - 12px);

    @include small-tablet {
      width: unset;
    }
  }

  &__img {
    width: 100%;
    max-width: 294px;
    height: auto;
    border-radius: 25px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: calc(70% - 12px);

    @include small-tablet {
      width: 100%;
    }


    &:nth-child(2) {
      margin-bottom: auto;
    }
  }



  &__text {
    margin: 0;
    font-weight: 500;
    // font-size: 24px;
    font-size: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
    color: var(--white);

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &:nth-child(-1) {
      margin-bottom: auto;
    }
  }

  &__btn {
    margin-top: auto;
    align-self: flex-start;

    @include small-tablet {
      align-self: center;
    }

    @include mobile {
      padding: 17px 50px;
    }
  }
}
