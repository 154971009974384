.text-card {

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;

    @include mini-tablet {
      gap: 12px;
    }

    &-item {
      grid-column: 6 span;

      @include big-mobile {
        grid-column: 12 span;
      }
    }
  }

  &__card {
    padding: 10% 11%;
    height: 100%;

    @include big-mobile {
      padding: 7% 9%;
    }

    &-title {
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 36px;
      font-size: clamp(1.5rem, 0.964rem + 1.429vw, 2.25rem);
      // font-size: clamp(1.75rem, 1.393rem + 0.952vw, 2.25rem);
      line-height: 111%;
      color: var(--fd-orange);

      @include big-mobile {
        margin-bottom: 20px;
      font-size: clamp(1.75rem, 1.393rem + 0.952vw, 2.25rem);
      }

      @include last {
      font-size: clamp(1.5rem, 0.964rem + 1.429vw, 2.25rem);
      }
    }

    &-text {
      margin: 0;
      font-weight: 400;
      font-size: 20px;
      // font-size: clamp(1rem, 0.821rem + 0.476vw, 1.25rem);
      color: var(--basic-white);
    }
  }
}

.text-card-cyber-incident-management .text-card__title {

  @include last {
    font-size: 33px;
  }
}
