.documents {
  backdrop-filter: blur(3px);

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin: 0;
    margin-bottom: 60px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    // font-size: 73px;
    font-size: clamp(2rem, 1.268rem + 3.661vw, 4.563rem);
    line-height: 104%;
    color: var(--fd-orange);

    @include small-tablet {
      margin-bottom: 30px;
    }
  }

  &__splide {
    width: 80%;
  }
}

.documents .splide__arrow {
  height: 58px;
  width: 58px;
  background-color: transparent;

  @include small-tablet {
    height: 29px;
    width: 29px;
  }

  & svg {
    width: 58px;
    height: 58px;
    fill: var(--fd-orange);

    @include small-tablet {
      height: 29px;
      width: 29px;
    }
  }
}

.documents .splide__arrow--next {
  right: -7%;

  @include mini-mobile {
    right: -10%;
  }
  
  @include last {
    right: -15%;
  }
}

.documents .splide__arrow--prev {
  left: -7%;

  @include mini-mobile {
    left: -10%;
  }

  @include last {
    left: -15%;
  }
}

.documents .splide__pagination {
  bottom: -15%;
  // 8A4200
  // FF9737
}

.documents .splide__pagination__page {

  @include mobile {
    width: 4px;
    height: 4px;
  }
}

.documents .splide__pagination__page.is-active {
  background-color: var(--fd-orange);
}

.documents .splide__pagination__page {
  background-color: #8A4200;
}
