.why {

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;

    @include small-tablet {
      gap: 12px;
    }

    &-item {
      position: relative;
      grid-column: 6 span;


      @include mobile {
        grid-column: 12 span;
      }

      &--big {
        grid-column: 12 span;
        z-index: -1;
      }
    }
  }

  &__link {
    margin: 0;
    font-weight: 300;
    // font-size: 24px;
    font-size: clamp(1.125rem, 0.857rem + 0.714vw, 1.5rem);
    color: var(--basic-white);
    text-decoration: underline;
  }

  &__card {
    padding: 40px 50px;
    height: 100%;

    @include small-tablet {
      padding: 20px 25px;
    }

    &-title {
      position: relative;
      margin: 0;
      margin-bottom: 30px;
      font-weight: 500;
      // font-size: 48px;
      font-size: clamp(1.5rem, 0.429rem + 2.857vw, 3rem);
      line-height: 110%;
      letter-spacing: 0.05em;
      color: var(--fd-orange);
      z-index: 2;

      @include mobile {
        margin-bottom: 20px;
      }
    }

    &-text {
      margin: 0;
      font-weight: 300;
      // font-size: 24px;
      font-size: clamp(1.125rem, 0.857rem + 0.714vw, 1.5rem);
      color: var(--basic-white);

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}

.why-seo {

  & .why__list-item:nth-child(1),
  & .why__list-item:nth-child(2) {

    @include small-tablet {
      grid-column: 12 span;
    }
  }
}
