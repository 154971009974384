:root {
  --font-family: "Gilroy", sans-serif;
  --content-width: 1420px;
  --container-offset: 10px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --fd-graphite: #13181e;
  --fd-dark: #2b2b2b;
  --dark-blue: #272e39;
  --fd-voilet: #46367c;
  --fd-dark-orange: #8a4200;
  --fd-orange: #ff9737;
  --fd-pale-orange: #d39964;
  --fd-ocre: #887a6e;
  --fd-pale: #fff3e9;
  --white: #fff;
  --megalight-grey: #f8f8f8;
  --orange: #ff7510;
  --dark: #303030;
  --light-grey: #bfbfbf;
  --superlight-grey: #dedede;
  --gradient: linear-gradient(347deg, #fd9637 0%, #46367c 100%);
  --glow-color:	hsl(29, 100%, 61%);
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/../fonts/gilroyultralight.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/../fonts/gilroyregular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/../fonts/gilroymedium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/../fonts/gilroysemibold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/../fonts/gilroybold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
  background-color: #000000;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  color: var(--white);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  position: relative;
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  position: relative;
  overflow: hidden;
  z-index: 100;
}
.site-container::before {
  background: image-set(url("/img/body-background.webp") 1x, url("/img/body-background_2x.webp") 2x);
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -1;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.mb-25 {
  margin-bottom: 25px;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  padding-top: 100px;
  padding-bottom: 100px;
  max-width: var(--container-width);
}
@media (max-width: 480px) {
  .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.orange-title {
  font-weight: 500;
  font-size: clamp(1rem, -0.018rem + 5.089vw, 4.563rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}

.hero-title {
  font-weight: 500;
  font-size: 110px;
  font-size: clamp(2.25rem, 0.929rem + 6.607vw, 6.875rem);
  line-height: 109%;
  color: var(--fd-pale);
}

.section-title {
  margin: 0;
  margin-bottom: 60px;
  font-weight: 400;
  font-size: clamp(2.3rem, -0.679rem + 8.393vw, 6.875rem);
  color: var(--fd-pale);
}
@media (max-width: 768px) {
  .section-title {
    margin-bottom: 30px;
  }
}

.middle-title {
  font-weight: 600;
  font-size: clamp(2rem, 1.268rem + 3.661vw, 4.563rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}

.horizontal-title {
  grid-column: 2 span;
  grid-row: 2 span;
  letter-spacing: 0.05em;
  text-align: center;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-weight: 600;
  font-size: clamp(2.25rem, 0.929rem + 6.607vw, 6.875rem);
}
@media (max-width: 768px) {
  .horizontal-title {
    writing-mode: unset;
    transform: unset;
  }
}

.card-container {
  border-radius: 25px;
  border: 2px solid var(--fd-orange);
  border-radius: 25px;
  backdrop-filter: blur(3px);
  background: rgba(39, 46, 57, 0.35);
  border-radius: 25px !important;
}
@media (max-width: 768px) {
  .card-container {
    border-radius: 25px !important;
  }
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  padding: 50px 30px;
  padding-top: 75px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background: #131313;
  background-image: image-set(url(/img/body-background.webp) 1x, url(/img/body-background_2x.webp) 2x);
  background-size: 100%;
  z-index: -10;
  opacity: 0;
  overflow-x: hidden;
}
@media (max-width: 624px) {
  .overlay {
    padding-top: 50px;
  }
}
@media (max-width: 400px) {
  .overlay {
    padding: 50px 15px;
  }
}

.overlay.active {
  z-index: 9999;
  opacity: 1;
}

.overlay.active .header__planet {
  width: 1500px;
  height: 1500px;
  opacity: 1;
}
@media (max-width: 768px) {
  .overlay.active .header__planet {
    width: 750px;
    height: 750px;
  }
}

.overlay.active .header__modal {
  opacity: 1;
}

.link-orange {
  transition: color 0.3s ease-in-out;
}
.link-orange:focus {
  outline: none;
}
@media (any-hover: hover) {
  .link-orange:hover {
    color: var(--fd-orange);
  }
  .link-orange:hover svg path {
    fill: var(--fd-orange);
    transition: fill 0.3s ease-in-out;
  }
}
.link-orange:active {
  color: var(--orange);
}

.inside-link {
  font-weight: 300;
  font-size: clamp(1.375rem, 0.214rem + 3.095vw, 3rem);
  color: var(--white);
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .inside-link:hover {
    color: var(--fd-orange);
  }
}

.link-white {
  transition: color 0.3s ease-in-out;
}
.link-white:focus {
  outline: none;
}
@media (any-hover: hover) {
  .link-white:hover {
    color: var(--white);
  }
}
.link-white:active {
  color: var(--orange);
}

.link-orange.focus-visible {
  outline: 1px solid var(--fd-orange);
  border-radius: 3px 3px 3px 3px;
  outline-offset: 3px;
}

.close {
  width: 60px;
  height: 60px;
  position: relative;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0 0 20px -5px rgba(255, 151, 55, 0.5);
  transition: 0.25s ease-in-out;
  cursor: pointer;
  animation: fade-in 0.5s ease-out 0.25s both;
}
@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
}
.close .circle path {
  stroke: #000000;
  fill: none;
  stroke-width: 1.5px;
  stroke-linecap: round;
  animation: progress 0.5s ease-out 0.25s both;
}
@keyframes progress {
  from {
    stroke-dasharray: 0 100;
  }
}
.close span {
  display: block;
  width: 13px;
  height: 3px;
  background: #000000;
  box-shadow: 0 0 20px -5px rgba(255, 151, 55, 0.5);
  border-radius: 20px;
  position: absolute;
  transition: 0.25s ease-in-out;
  animation: slide-in 0.5s ease-in-out 0.25s both;
}
@keyframes slide-in {
  from {
    width: 0;
  }
}
.close span:nth-child(1) {
  top: 18.5px;
  left: 20px;
  transform: rotate(45deg);
  transform-origin: top left;
}
.close span:nth-child(2) {
  top: 18.5px;
  right: 20px;
  transform: rotate(-45deg);
  transform-origin: top right;
}
.close span:nth-child(3) {
  bottom: 18.5px;
  left: 20px;
  transform: rotate(-45deg);
  transform-origin: bottom left;
}
.close span:nth-child(4) {
  bottom: 18.5px;
  right: 20px;
  transform: rotate(45deg);
  transform-origin: bottom right;
}
.close:hover {
  background-color: transparent;
}
.close:hover span {
  background-color: var(--fd-orange);
  width: 15px;
}
.close:hover .circle path {
  stroke: var(--fd-orange);
  transition: stroke 0.3s ease-in-out;
}

#canvasOne {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.planet-canvas {
  position: absolute;
  right: 0;
  top: 50px;
}

#comets {
  position: absolute;
  top: -150px;
  width: 100vw;
  text-align: left;
  height: 100%;
  min-height: 900px;
  overflow: hidden;
}

#comets i {
  display: inline-block;
  width: 250px;
  height: 150px;
  position: absolute;
  border-radius: 5% 40% 70%;
  box-shadow: inset 0px 0px 1px #294b67;
  border: 1px solid #333;
  z-index: 1;
  background-color: #fff;
  opacity: 0.7;
  -webkit-animation: falling 10s 0s infinite;
  -webkit-animation-timing-function: ease-in;
}

#comets > img {
  display: inline-block;
  width: 47px;
  height: 116px;
  position: absolute;
  z-index: 1;
  -webkit-animation: falling 10s 10s infinite;
  -webkit-animation-timing-function: ease-in;
  transform: rotate(180deg);
}

#comets i:nth-child(1) {
  left: 50vw;
  height: 73px;
  width: 3px;
  background-color: #fff;
}

#comets i:nth-child(3) {
  height: 11px;
  width: 3px;
  -webkit-animation: falling3 8s 3s infinite;
  left: 10vw;
  background-color: #fff;
}

#comets i:nth-child(2) {
  -webkit-animation: falling2 6s 1s infinite;
  left: 30vw;
  height: 70px;
  width: 4px;
  background-color: #fff;
}

#comets > img {
  -webkit-animation: falling4 7s infinite;
  left: 30vw;
  height: 119px;
  width: 47px;
}

@-webkit-keyframes falling4 {
  0% {
    -webkit-transform: translate3d(100px, 0px, 0px) rotate(160deg);
  }
  50% {
    -webkit-transform: translate3d(450px, 900px, 0) rotate(160deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(450px, 900px, 0) rotate(160deg);
    opacity: 0;
  }
}
@-webkit-keyframes falling {
  0% {
    -webkit-transform: translate3d(100px, 0px, 0px) rotate(160deg);
  }
  3% {
    -webkit-transform: translate3d(450px, 900px, 0) rotate(160deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(450px, 900px, 0) rotate(160deg);
    opacity: 0;
  }
}
@-webkit-keyframes falling3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(150deg);
  }
  10% {
    -webkit-transform: translate3d(430px, 640px, 0) rotate(150deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(430px, 640px, 0) rotate(150deg);
    opacity: 0;
  }
}
@-webkit-keyframes falling2 {
  0% {
    -webkit-transform: translate3d(100px, 0, 0) rotate(130deg);
  }
  15% {
    -webkit-transform: translate3d(800px, 580px, 0) rotate(130deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(800px, 680px, 0) rotate(180deg);
    opacity: 0;
  }
}
.body-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  -ms-animation: move 80s infinite ease;
  -webkit-animation: move 80s infinite ease;
  -moz-animation: move 80s infinite ease;
  position: absolute;
}

@-webkit-keyframes move {
  0% {
    transform: scale(1);
    -ms-transform: scale(1);
    /* IE 9 */
    -webkit-transform: scale(1);
    /* Safari and Chrome */
    -o-transform: scale(1);
    /* Opera */
    -moz-transform: scale(1);
    /* Firefox */
  }
  50% {
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari and Chrome */
    -o-transform: scale(1.1);
    /* Opera */
    -moz-transform: scale(1.1);
    /* Firefox */
  }
  100% {
    transform: scale(1);
    -ms-transform: scale(1);
    /* IE 9 */
    -webkit-transform: scale(1);
    /* Safari and Chrome */
    -o-transform: scale(1);
    /* Opera */
    -moz-transform: scale(1);
    /* Firefox */
  }
}
.burger {
  --burger-width: 90px;
  --burger-height: 40px;
  --burger-line-height: 5px;
  position: relative;
  border: none;
  margin-right: 10px;
  padding: 0;
  width: var(--burger-width);
  height: var(--burger-height);
  color: var(--white);
  background-color: transparent;
  cursor: pointer;
}
.burger::before, .burger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .burger::before, .burger::after {
    left: unset;
    right: 0;
  }
}
.burger::before {
  top: 0;
  width: 50%;
}
.burger::after {
  top: calc(100% - var(--burger-line-height));
}
@media (any-hover: hover) {
  .burger:hover__line {
    background-color: var(--fd-orange);
  }
}
.burger__line {
  position: absolute;
  left: 0;
  top: 50%;
  width: 70%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .burger__line {
    left: unset;
    right: 0;
  }
}
.burger--active::before {
  top: 50%;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active::after {
  top: 50%;
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active .burger__line {
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}

.btn {
  position: relative;
  padding: 17px 105px;
  border-radius: 25px;
  border: 1px solid var(--fd-orange);
  color: var(--fd-orange);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: var(--fd-orange);
  color: var(--dark);
}
@media (max-width: 768px) {
  .btn {
    border-radius: 25px;
  }
}
@media (any-hover: hover) {
  .btn:hover {
    box-shadow: -1px 1px 19px 4px rgb(255, 151, 55);
    background-color: var(--fd-orange);
    color: var(--dark);
  }
}

.dropdown__btn {
  position: relative;
  background-color: transparent;
  padding: 15px 15px 0 15px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: var(--white);
  border: none;
  z-index: 10;
}

.dropdown__btn-svg {
  padding-left: 5px;
  width: 100%;
  max-width: 18px;
  height: auto;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown__content {
  display: none;
  position: absolute;
  padding-top: 50px;
  min-width: 160px;
  top: -5px;
  border-radius: 25px;
  border: 1px solid var(--fd-orange);
  background-color: transparent;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  backdrop-filter: blur(13px);
}

.dropdown__btn-item {
  display: block;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: var(--basic-white);
  text-decoration: none;
}

.dropdown:hover .dropdown__content {
  display: block;
}

.custom-check {
  position: relative;
  padding-left: 50px;
}
.custom-check__checkbox {
  position: absolute;
  margin-top: -6px;
  margin-left: -50px;
  width: 32px;
  height: 28px;
  border-radius: 30px;
  background-color: var(--white);
  transition: border-color 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.custom-check__checkbox::before {
  content: "";
  position: absolute;
  height: 110%;
  width: 60%;
  margin: auto;
  top: 25%;
  left: 65%;
  right: 0;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  border-right: 7px solid var(--fd-orange);
  border-bottom: 7px solid var(--fd-orange);
  opacity: 0;
}
.custom-check__input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  outline-offset: 2px;
}
.custom-check__input:checked + .custom-check__checkbox:after, .custom-check__input:checked + .custom-check__checkbox:before {
  opacity: 1;
}
.custom-check__input:focus-visible + .custom-check__checkbox {
  outline: 2px solid var(--fd-orange);
}
.custom-check:checked:focus-visible + .custom-check__checkbox {
  box-shadow: 0 0 0 1px var(--fd-orange), 0 0 0 2px var(--fd-orange);
}
.custom-check:disabled + .custom-check__checkbox {
  box-shadow: 0 0 0 1px var(--grey-color);
}
.custom-check:checked:disabled + .custom-check__checkbox {
  box-shadow: 0 0 0 1px var(--grey-color);
  background-color: var(--grey-color);
}

.header {
  z-index: 100;
}
.header-graphite {
  background-color: var(--fd-graphite);
}
.header__container {
  display: flex;
  align-items: end;
  margin: 0 auto;
  padding-top: 28px;
  padding-bottom: 28px;
  margin: 0 auto;
  width: 100%;
  max-width: var(--container-width);
}
@media (max-width: 1200px) {
  .header__container {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .header__container {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.header__left {
  display: flex;
  align-items: end;
  padding-right: 15px;
  width: 35%;
}
@media (max-width: 1200px) {
  .header__left {
    width: 40%;
  }
}
@media (max-width: 768px) {
  .header__left {
    padding: 0;
    width: 100%;
  }
}
.header__center {
  display: flex;
  align-items: end;
  justify-content: flex-end;
  margin-left: auto;
}
@media (max-width: 1200px) {
  .header__center {
    padding-top: 20px;
    order: 5;
    width: 100%;
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .header__center {
    justify-content: center;
    padding-bottom: 10px;
  }
}
.header__right {
  display: flex;
  justify-content: flex-end;
  width: 30%;
}
@media (max-width: 1200px) {
  .header__right {
    width: 50%;
    margin-left: auto;
  }
}
@media (max-width: 768px) {
  .header__right {
    width: 100%;
    justify-content: center;
    order: 6;
  }
}
.header__btn {
  justify-self: flex-end;
  background-color: transparent !important;
  color: var(--fd-orange) !important;
}
.header__btn span {
  white-space: nowrap;
}
@media (max-width: 350px) {
  .header__btn {
    padding: 17px 85px;
  }
}
.header__logo {
  margin-right: auto;
  padding-right: 15px;
}
.header__logo img {
  width: 100%;
  min-width: 140px;
  height: auto;
}
@media (max-width: 768px) {
  .header__logo {
    margin: 0;
    margin-right: auto;
  }
}
.header__burger {
  margin-right: auto;
}
@media (any-hover: hover) {
  .header__burger:hover::after {
    background-color: var(--fd-orange);
    transition: background-color 0.3s ease-in-out;
  }
  .header__burger:hover::before {
    background-color: var(--fd-orange);
    transition: background-color 0.3s ease-in-out;
  }
  .header__burger:hover .burger__line {
    background-color: var(--fd-orange);
    transition: background-color 0.3s ease-in-out;
  }
}
@media (max-width: 768px) {
  .header__burger {
    margin: 0;
  }
}
.header__phone {
  grid-column: 3 span;
  text-align: end;
  margin-right: 10px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: var(--white);
}
@media (max-width: 768px) {
  .header__phone {
    order: 2;
  }
}
.header__planet {
  position: absolute;
  top: -810px;
  left: 490px;
  border-radius: 100%;
  width: 300px;
  height: 300px;
  background-color: var(--white);
  box-shadow: inset 17px 26px 58px 23px rgb(0, 0, 0);
  background-image: url("/img/saturn.webp");
  background-size: auto 100%;
  background-repeat: repeat-x;
  transform: rotate(-19deg);
  opacity: 0;
  transition: all 1s ease-in-out;
}
@media (max-width: 768px) {
  .header__planet {
    left: 250px;
    top: -300px;
  }
}
@media (max-width: 480px) {
  .header__planet {
    left: 150px;
  }
}
.header__modal {
  display: flex;
  align-items: flex-start;
  top: 10px;
  max-width: 1440px;
  z-index: 999999;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
.header__close {
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (max-width: 768px) {
  .header__close svg {
    display: none;
  }
}
.header__nav {
  display: flex;
  width: 100%;
  padding-bottom: 50px;
}
.header__nav-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 25px;
}
@media (max-width: 768px) {
  .header__nav-list {
    gap: 12px;
  }
}
.header__nav-list-item {
  grid-column: 6 span;
}
.header__nav-list-item:nth-child(1) {
  order: 3;
  grid-column: 6 span;
}
@media (max-width: 624px) {
  .header__nav-list-item:nth-child(1) {
    grid-column: 12 span;
  }
}
.header__nav-list-item:nth-child(2) {
  order: 1;
}
.header__nav-list-item:nth-child(3) {
  order: 2;
}
.header__nav-list-item:nth-child(4) {
  grid-column: 7/6 span;
  order: 4;
}
@media (max-width: 624px) {
  .header__nav-list-item:nth-child(4) {
    grid-column: 12 span;
  }
}
.header__nav-list-item:nth-child(5) {
  order: 5;
}
.header__nav-list-item:nth-child(6) {
  order: 6;
}
.header__nav-list-item:nth-child(7) {
  order: 7;
}
.header__nav-list-item:nth-child(8) {
  order: 8;
}
.header__nav-list-item:nth-child(9) {
  order: 9;
}
.header__nav-list-item:nth-child(10) {
  order: 10;
}
@media (max-width: 624px) {
  .header__nav-list-item {
    grid-column: 12 span;
  }
}
.header__nav-list-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 15px;
  height: 100%;
  border-radius: 25px;
  overflow: visible;
}
@media (max-width: 768px) {
  .header__nav-list-card {
    border-radius: 15px !important;
  }
}
.header__nav-list-card-right {
  display: flex;
  flex-direction: column;
}
.header__nav-link {
  position: relative;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: var(--fd-pale);
}
@media (max-width: 768px) {
  .header__nav-link {
    font-size: 18px;
  }
}
.header__nav-link:not(:last-child) {
  margin-bottom: 7px;
}
.header__nav-link--main {
  margin-bottom: 0 !important;
  font-size: 24px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .header__nav-link--main {
    font-size: 20px;
  }
}
@media (max-width: 624px) {
  .header__nav-link--main {
    font-size: 18px;
  }
}

.hero {
  padding-top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.hero__container {
  position: relative;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hero__title {
  margin: 0;
  margin-bottom: 70px;
  padding-top: 150px;
  padding-bottom: 200px;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: clamp(2.25rem, 0.929rem + 6.607vw, 6.875rem);
  line-height: 109%;
  color: var(--fd-pale);
}
@media (max-width: 1200px) {
  .hero__title {
    padding-top: 0;
  }
}
@media (max-width: 768px) {
  .hero__title {
    padding-bottom: 100px;
  }
}
.hero__stock {
  display: flex;
  gap: 25px;
  border-radius: 25px;
  transform-style: preserve-3d;
}
@media (max-width: 624px) {
  .hero__stock {
    justify-content: center;
    flex-direction: column;
  }
}
.hero__stock-img {
  border: 2px solid var(--fd-voilet);
  border-radius: 25px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}
.hero__stock-img img {
  vertical-align: middle;
  height: 100%;
}
@media (max-width: 624px) {
  .hero__stock-img {
    align-self: center;
    width: 55%;
  }
}
.hero__stock-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 50px 15px;
  width: 100%;
  border: 2px solid var(--fd-orange);
  border-radius: 25px;
  backdrop-filter: blur(3px);
  transition: transform 0.3s ease-in-out;
  transform-style: preserve-3d;
  perspective: 1000px;
}
.hero__stock-content-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: clamp(2rem, 0.768rem + 6.161vw, 6.313rem);
  text-transform: uppercase;
  transform: translateZ(20px);
}
@media (max-width: 768px) {
  .hero__stock-content-title {
    flex-direction: column;
  }
}
.hero__stock-content-title--small {
  padding-left: 45px;
  font-weight: 400;
  font-size: clamp(1.5rem, 0.643rem + 1.786vw, 2.25rem);
  text-transform: none;
  transform: translateZ(20px);
  width: 59%;
}
@media (max-width: 1024px) {
  .hero__stock-content-title--small {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .hero__stock-content-title--small {
    width: 100%;
    padding-left: 25px;
  }
}
@media (max-width: 624px) {
  .hero__stock-content-title--small {
    padding-left: 0;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .hero__stock-content-title {
    margin-bottom: 10px;
  }
}
.hero__stock-content-descr {
  font-weight: 300;
  font-size: clamp(2.07rem, -0.518rem + 7.589vw, 6.313rem);
  color: var(--fd-pale);
  transform: translateZ(20px);
}
.hero__planet {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 500px;
  width: 100px;
  background-image: url("/img/hero-background.webp");
}

.offers__plan-list-item {
  margin-bottom: 80px;
}
.offers__services-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  margin-bottom: 80px;
}
.offers__services-list-item {
  grid-column: 6 span;
}
@media (max-width: 930px) {
  .offers__services-list-item {
    grid-column: 12 span;
  }
}

.plan-card {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  padding-top: 80px;
  padding-bottom: 80px;
  grid-auto-rows: auto;
  border: 2px solid var(--fd-orange);
  border-radius: 25px;
  backdrop-filter: blur(3px);
}
@media (max-width: 768px) {
  .plan-card {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}
@media (max-width: 576px) {
  .plan-card {
    gap: 0;
  }
}
.plan-card__title {
  font-size: clamp(2.25rem, 0.929rem + 6.607vw, 6.875rem);
}
@media (max-width: 1024px) {
  .plan-card__title {
    grid-column: 1 span;
  }
}
@media (max-width: 768px) {
  .plan-card__title {
    grid-column: 12 span;
  }
}
.plan-card__content {
  grid-column: 9 span;
  display: flex;
  gap: 80px;
  border: 1px solid var(--fd-orange);
  border-radius: 25px;
  padding: 44px 41px;
  background-color: rgba(38, 41, 45, 0.6117647059);
  width: 100%;
}
@media (max-width: 1024px) {
  .plan-card__content {
    grid-column: 2/10 span;
    gap: 24px;
  }
}
@media (max-width: 930px) {
  .plan-card__content {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 576px) {
  .plan-card__content {
    grid-column: 12 span;
    background-color: transparent;
    border: none;
  }
}
@media (max-width: 480px) {
  .plan-card__content {
    padding: 20px;
  }
}
.plan-card__image {
  width: 100%;
  max-width: 368px;
  height: auto;
  border-radius: 23px;
  vertical-align: bottom;
}
.plan-card__content-list {
  width: calc(50% - 12px);
}
@media (max-width: 930px) {
  .plan-card__content-list {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .plan-card__content-list {
    width: 100%;
  }
}
.plan-card__content-list-item:not(:last-child) {
  margin-bottom: 15px;
}
.plan-card__content-text {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  font-weight: 500;
  font-size: 24px;
  color: var(--white);
}
.plan-card__content-text::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 15px;
  width: 5px;
  height: 5px;
  border-radius: 25px;
  background-color: var(--white);
  transform: translateY(-50%);
}
.plan-card__time, .plan-card__price, .plan-card__center {
  position: relative;
  padding: 34px 38px;
  border: 1px solid var(--fd-voilet);
  border: 1px solid var(--fd-orange);
  border-radius: 25px;
  background-color: rgba(38, 41, 45, 0.6117647059);
  min-height: 229px;
}
@media (max-width: 576px) {
  .plan-card__time, .plan-card__price, .plan-card__center {
    min-height: unset;
    min-height: 170px;
    background-color: transparent;
    border: none;
  }
}
@media (max-width: 480px) {
  .plan-card__time, .plan-card__price, .plan-card__center {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 576px) {
  .plan-card__time {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .plan-card__price {
    padding-top: 0;
    padding-bottom: 60px;
  }
}
.plan-card__btn {
  padding: 17px 50px;
  font-weight: 500;
  font-size: clamp(1rem, 0.643rem + 1.786vw, 2.25rem);
  letter-spacing: 0.05em;
  text-align: center;
}
.plan-card__time, .plan-card__price, .plan-card__center {
  grid-column: 3 span;
}
.plan-card__btn {
  grid-column: 3/9 span;
}
.plan-card__center {
  display: flex;
  align-items: center;
}
.plan-card__center-svg {
  width: 100%;
  max-width: 282px;
  height: auto;
}
@media (max-width: 1024px) {
  .plan-card__center {
    display: none;
  }
}
@media (max-width: 1024px) {
  .plan-card__time, .plan-card__price {
    grid-column: 5 span;
  }
}
@media (max-width: 576px) {
  .plan-card__time, .plan-card__price {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;
    row-gap: 0;
  }
}
@media (max-width: 768px) {
  .plan-card__time {
    grid-column: 2/5 span;
  }
}
@media (max-width: 576px) {
  .plan-card__time {
    grid-column: 12 span;
  }
}
@media (max-width: 768px) {
  .plan-card__price {
    grid-column: 5 span;
  }
}
@media (max-width: 576px) {
  .plan-card__price {
    grid-column: 12 span;
  }
}
@media (max-width: 1024px) {
  .plan-card__btn {
    padding: 50px;
    grid-column: 2/10 span;
  }
}
@media (max-width: 576px) {
  .plan-card__btn {
    padding: 40px;
  }
}
.plan-card__time-title, .plan-card__price-title {
  position: relative;
  margin-bottom: 22px;
  padding-top: 15px;
  font-weight: 600;
  font-size: 36px;
  letter-spacing: 0.05em;
  color: var(--white);
  z-index: 2;
}
@media (max-width: 1200px) {
  .plan-card__time-title, .plan-card__price-title {
    margin-bottom: 40px;
  }
}
@media (max-width: 576px) {
  .plan-card__time-title, .plan-card__price-title {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .plan-card__time-title, .plan-card__price-title {
    grid-column: 4/8 span;
  }
}
.plan-card__time-text, .plan-card__price-text {
  position: relative;
  margin: 0;
  font-weight: 300;
  font-size: 24px;
  color: var(--white);
  z-index: 2;
}
@media (max-width: 576px) {
  .plan-card__time-text, .plan-card__price-text {
    grid-column: 4/8 span;
  }
}
.plan-card__price-text {
  width: 76%;
}
@media (max-width: 576px) {
  .plan-card__price-text {
    width: 100%;
  }
}
.plan-card__img {
  position: absolute;
  right: 10%;
  top: 10%;
  z-index: 1;
  width: 100%;
  max-width: 80px;
  height: auto;
}
@media (max-width: 768px) {
  .plan-card__img {
    max-width: 80px;
  }
}
@media (max-width: 576px) {
  .plan-card__img {
    position: static;
    align-self: center;
    grid-column: 1/3 span;
    grid-row: 1/2 span;
  }
}
.plan-card-services {
  padding: 40px 40px;
  border: 1px solid var(--fd-dark-orange);
  border-radius: 25px;
  height: 100%;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}
@media (max-width: 930px) {
  .plan-card-services {
    justify-content: space-around;
  }
}
@media (max-width: 480px) {
  .plan-card-services {
    padding: 40px 30px;
  }
}
.plan-card-services__title {
  margin: 0;
  width: 89%;
}
@media (max-width: 1200px) {
  .plan-card-services__title {
    width: 100%;
  }
}
@media (max-width: 589px) {
  .plan-card-services__title {
    text-align: center;
  }
}
.plan-card-services__img {
  width: 50%;
  border-radius: 25px;
  width: 100%;
  max-width: 220px;
  height: auto;
}
.plan-card-services__list {
  width: 50%;
}
@media (max-width: 1200px) {
  .plan-card-services__list {
    width: 100%;
  }
}
@media (max-width: 930px) {
  .plan-card-services__list {
    width: 50%;
  }
}
@media (max-width: 589px) {
  .plan-card-services__list {
    width: 100%;
  }
}
.plan-card-services__list-item:not(:last-child) {
  margin-bottom: 15px;
}
.plan-card-services__list-text {
  position: relative;
  margin: 0;
  padding-left: 15px;
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
}
.plan-card-services__list-text::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 8px;
  width: 5px;
  height: 5px;
  border-radius: 25px;
  background-color: var(--white);
  transform: translateY(-50%);
}
.plan-card-services__offer {
  width: 89%;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}
@media (max-width: 589px) {
  .plan-card-services__offer {
    width: 100%;
  }
}
.plan-card-services__offer span {
  font-weight: 400;
  color: var(--white);
}
@media (max-width: 480px) {
  .plan-card-services__btn {
    padding: 17px 50px;
  }
}

.brif {
  padding: 90px 122px;
  border: 1px solid var(--fd-dark-orange);
  border-radius: 25px;
  backdrop-filter: blur(3px);
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
@media (max-width: 1024px) {
  .brif {
    padding: 60px;
  }
}
@media (max-width: 768px) {
  .brif {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .brif {
    padding: 40px 30px;
  }
}
.brif__title {
  width: 100%;
}
@media (max-width: 576px) {
  .brif__title {
    text-align: center;
  }
}
.brif picture {
  width: calc(30% - 12px);
}
@media (max-width: 768px) {
  .brif picture {
    width: unset;
  }
}
.brif__img {
  width: 100%;
  max-width: 294px;
  height: auto;
  border-radius: 25px;
}
.brif__right {
  display: flex;
  flex-direction: column;
  width: calc(70% - 12px);
}
@media (max-width: 768px) {
  .brif__right {
    width: 100%;
  }
}
.brif__right:nth-child(2) {
  margin-bottom: auto;
}
.brif__text {
  margin: 0;
  font-weight: 500;
  font-size: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
  color: var(--white);
}
.brif__text:not(:last-child) {
  margin-bottom: 15px;
}
.brif__text:nth-child(-1) {
  margin-bottom: auto;
}
.brif__btn {
  margin-top: auto;
  align-self: flex-start;
}
@media (max-width: 768px) {
  .brif__btn {
    align-self: center;
  }
}
@media (max-width: 576px) {
  .brif__btn {
    padding: 17px 50px;
  }
}

.work-stages__text {
  margin: 0;
  margin-bottom: 80px;
  width: 80%;
  font-weight: 500;
  font-size: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
  letter-spacing: 0.05em;
  color: var(--white);
}
.work-stages__text span {
  display: inline-block;
  margin-bottom: 15px;
}
@media (max-width: 576px) {
  .work-stages__text {
    margin-bottom: 40px;
  }
}
.work-stages__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 768px) {
  .work-stages__list {
    gap: 15px;
  }
}
@media (max-width: 480px) {
  .work-stages__list {
    gap: 10px;
  }
}
.work-stages__list-item {
  grid-column: 4 span;
}
@media (max-width: 768px) {
  .work-stages__list-item {
    grid-column: 6 span;
  }
}
@media (max-width: 400px) {
  .work-stages__list-item {
    grid-column: 12 span;
  }
}
@media (max-width: 768px) {
  .work-stages__list-item--invisible {
    position: absolute;
    overflow: hidden;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
  }
}
.work-stages__card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid var(--fd-pale-orange);
  border-radius: 25px;
  background-size: 30%;
  background-position: 7% 94%;
  background-repeat: no-repeat;
  overflow: hidden;
  backdrop-filter: blur(3px);
  transition: transform 0.3s ease-in-out;
}
@media (max-width: 930px) {
  .work-stages__card {
    border-radius: 25px;
  }
}
@media (max-width: 400px) {
  .work-stages__card {
    background-size: 19%;
  }
}
.work-stages__card--invisible {
  backdrop-filter: none;
}
@media (any-hover: hover) {
  .work-stages__card:hover:not(.work-stages__card--invisible) {
    transform: scale(1.05);
    box-shadow: -1px 1px 19px 4px rgb(255, 151, 55);
  }
  .work-stages__card:hover > .work-stages__card-svg {
    animation: rotate 4s linear infinite;
  }
}
.work-stages__card-svg {
  position: absolute;
  right: -5%;
  bottom: -7%;
}
@media (max-width: 1024px) {
  .work-stages__card-svg {
    width: 100%;
    max-width: 200px;
    height: auto;
  }
}
.work-stages__card--1 {
  background-image: image-set(url("../img/w-1.webp") 1x, url("../img/w-1_2x.webp") 2x);
}
.work-stages__card--2 {
  background-image: image-set(url("../img/w-2.webp") 1x, url("../img/w-2_2x.webp") 2x);
}
.work-stages__card--3 {
  background-image: image-set(url("../img/w-3.webp") 1x, url("../img/w-3_2x.webp") 2x);
}
.work-stages__card--4 {
  background-image: image-set(url("../img/w-4.webp") 1x, url("../img/w-4_2x.webp") 2x);
}
.work-stages__card--5 {
  background-image: image-set(url("../img/w-5.webp") 1x, url("../img/w-5_2x.webp") 2x);
}
.work-stages__card--6 {
  background-image: image-set(url("../img/w-6.webp") 1x, url("../img/w-6_2x.webp") 2x);
}
.work-stages__card--7 {
  background-image: image-set(url("../img/w-7.webp") 1x, url("../img/w-7_2x.webp") 2x);
}
.work-stages__card--8 {
  background-image: image-set(url("../img/w-8.webp") 1x, url("../img/w-8_2x.webp") 2x);
}
.work-stages__card--9 {
  background-image: image-set(url("../img/w-9.webp") 1x, url("../img/w-9_2x.webp") 2x);
}
.work-stages__card--invisible {
  border: none;
}
@media (max-width: 768px) {
  .work-stages__card--invisible {
    position: absolute;
    overflow: hidden;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
  }
}
.work-stages__card-title {
  margin: 0;
  margin-bottom: 10px;
  padding-right: 40px;
  padding-left: 40px;
  font-weight: 500;
  font-size: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
  font-size: clamp(1.125rem, 0.768rem + 0.952vw, 1.625rem);
  color: var(--fd-pale-orange);
}
@media (max-width: 480px) {
  .work-stages__card-title {
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 400px) {
  .work-stages__card-title {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
  }
}
.work-stages__card-text {
  margin: 0;
  margin-bottom: 56px;
  padding-right: 40px;
  padding-left: 40px;
  font-weight: 300;
  font-size: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
  color: var(--white);
}
@media (max-width: 1024px) {
  .work-stages__card-text {
    margin-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .work-stages__card-text {
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 400px) {
  .work-stages__card-text {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.work-stages__img {
  vertical-align: bottom;
  margin-bottom: 60px;
}
@media (max-width: 1200px) {
  .work-stages__img {
    margin-bottom: 40px;
  }
}

@keyframes rotate {
  0% {
    rotate: 0deg;
  }
  50% {
    rotate: 180deg;
  }
  100% {
    rotate: 360deg;
  }
}
.services__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 768px) {
  .services__list {
    gap: 12px;
  }
}
.services__list-item {
  grid-column: 6 span;
}
.services__list-item--small {
  grid-column: 3 span;
}
@media (max-width: 1250px) {
  .services__list-item--1 {
    grid-column: 4 span;
  }
  .services__list-item--2 {
    grid-column: 8 span;
  }
  .services__list-item--3 {
    grid-column: 4 span;
    order: 10;
  }
  .services__list-item--4 {
    grid-column: 12 span;
  }
  .services__list-item--5 {
    grid-column: 8 span;
  }
}
@media (max-width: 768px) {
  .services__list-item--1 {
    grid-column: 6 span;
    order: 1;
  }
  .services__list-item--2 {
    grid-column: 12 span;
    order: 3;
  }
  .services__list-item--3 {
    grid-column: 6 span;
    order: 2;
  }
  .services__list-item--4 {
    grid-column: 12 span;
    order: 4;
  }
  .services__list-item--5 {
    grid-column: 12 span;
    order: 5;
  }
}
.services__list-item--tail {
  position: relative;
  z-index: 10;
  transition: animation 1s ease-in-out;
}
@media (any-hover: hover) {
  .services__list-item--tail:hover .services__card-search {
    transform: scale(1.1) rotate(7deg);
  }
  .services__list-item--tail:hover .services__card-display {
    transform: scale(1.1) rotate(-7deg);
  }
  .services__list-item--tail:hover .services__card-fox-tail-main {
    animation: tail-shaking 1s ease infinite;
  }
}
.services__card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 35px;
  height: 100%;
}
@media (max-width: 400px) {
  .services__card {
    padding: 15px;
  }
}
@media (any-hover: hover) {
  .services__card--rocket:hover .services__card-rocket {
    animation: rocket-shaking 0.5s ease infinite;
  }
  .services__card--rocket:hover .services__card-fire {
    animation: fire-shaking 0.5s ease infinite;
  }
}
.services__card--tail {
  backdrop-filter: blur(80px);
}
@media (any-hover: hover) {
  .services__card--accom:hover .services__card-services-1 {
    animation: frst 3s ease infinite;
  }
  .services__card--accom:hover .services__card-services-2 {
    animation: scnd 3s ease infinite;
  }
  .services__card--accom:hover .services__card-services-3 {
    animation: thrd 3s ease infinite;
  }
}
.services__card--p0 {
  padding-bottom: 20px;
  overflow: hidden;
}
@media (any-hover: hover) {
  .services__card--p0:hover .services__card-direct {
    animation: move 10s ease infinite;
  }
}
.services__card-title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: clamp(1.5rem, 0.786rem + 3.571vw, 4rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}
.services__card-title--small {
  font-weight: 400;
  font-size: clamp(1.125rem, 0.804rem + 1.607vw, 2.25rem);
  letter-spacing: 0.05em;
  color: var(--fd-pale);
}
.services__card-picture-search {
  display: flex;
  justify-content: flex-end;
}
.services__card-img-wrap {
  display: flex;
  justify-content: center;
}
.services__card-rocket {
  transform: rotate(-45deg);
}
.services__card-rocket-wrap {
  position: absolute;
  bottom: 0%;
  right: 13%;
}
@media (max-width: 768px) {
  .services__card-rocket-wrap {
    max-width: 240px;
  }
}
.services__card-fire {
  position: absolute;
  bottom: -67%;
  right: -59%;
  transform: rotate(-45deg) scale(0.3);
  z-index: -1;
  opacity: 0;
}
.services__card-direct {
  position: relative;
  z-index: -1;
}
.services__card-fox-tail-main {
  position: absolute;
  top: 63%;
  right: 8%;
  z-index: -1;
  transform: rotate(42deg);
}
@media (max-width: 768px) {
  .services__card-fox-tail-main {
    max-width: 190px;
  }
}
@media (max-width: 480px) {
  .services__card-fox-tail-main {
    max-width: 140px;
  }
}
@media (max-width: 400px) {
  .services__card-fox-tail-main {
    max-width: 115px;
  }
}
@media (max-width: 350px) {
  .services__card-fox-tail-main {
    max-width: 105px;
  }
}
.services__card-search {
  transition: transform 0.3s ease-in-out;
}
@media (max-width: 400px) {
  .services__card-search {
    position: relative;
    max-width: 130px;
    right: -5%;
  }
}
.services__card-display {
  position: absolute;
  bottom: 6%;
  left: 9%;
  transition: transform 0.3s ease-in-out;
}
@media (max-width: 400px) {
  .services__card-display {
    bottom: -2%;
    left: 2%;
  }
}
.services__card-services-1 {
  position: relative;
  left: 30px;
}
.services__card-services-2 {
  position: relative;
}
.services__card-services-3 {
  position: relative;
  left: -30px;
}
.services__card-descr {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: clamp(1.375rem, 1.125rem + 1.25vw, 2.25rem);
  letter-spacing: 0.05em;
  color: var(--fd-pale);
}
@media (max-width: 400px) {
  .services__card-descr {
    flex-wrap: wrap;
  }
}
.services__card-text {
  display: flex;
  align-items: center;
  padding-left: 5px;
  font-weight: 400;
  font-size: clamp(1.375rem, 1.125rem + 1.25vw, 2.25rem);
  letter-spacing: 0.05em;
  color: var(--fd-pale);
}
.services__card-text--orange {
  font-weight: 500;
  font-size: clamp(2.313rem, 1.67rem + 3.214vw, 4.563rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}
.services__card-btn {
  position: relative;
  bottom: -4%;
  padding: 15px 30px;
  align-self: flex-end;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: clamp(1.5rem, 1.071rem + 2.143vw, 3rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
  border: 1px solid var(--fd-pale-orange);
  border-radius: 25px;
  box-shadow: inset 5px 5px 13px 0 rgba(72, 72, 72, 0.75), 8px 8px 8px 0 rgba(0, 0, 0, 0.75);
  transform: rotate(5deg);
  transition: transform 0.2s ease-in-out;
}
@media (any-hover: hover) {
  .services__card-btn:hover {
    box-shadow: inset 5px 5px 13px 0 rgba(72, 72, 72, 0.75), 8px 8px 8px 0 rgb(255, 151, 55);
    transform: rotate(0deg);
  }
}
@media (max-width: 350px) {
  .services__card-btn {
    padding: 10px 20px;
    bottom: -2%;
  }
}
.services__card-list {
  display: flex;
  justify-content: center;
  gap: 24px;
}
.services__card-list--1 {
  margin-bottom: 20px;
}
.services__card-list-item {
  position: relative;
}
.services__card-list-item--1 {
  position: relative;
  z-index: 1;
}
@media (any-hover: hover) {
  .services__card-list-item--1:hover .services__card-ya1-2 {
    opacity: 1;
    z-index: 2;
  }
}
.services__card-list-item--2 {
  position: relative;
  z-index: 1;
}
@media (any-hover: hover) {
  .services__card-list-item--2:hover .services__card-ya2-2 {
    opacity: 1;
    z-index: 2;
  }
}
.services__card-list-item--3 {
  position: relative;
  z-index: 1;
}
@media (any-hover: hover) {
  .services__card-list-item--3:hover .services__card-ya3-2 {
    opacity: 1;
    z-index: 2;
  }
}
.services__card-list-item--4 {
  position: relative;
  z-index: 1;
}
@media (any-hover: hover) {
  .services__card-list-item--4:hover .services__card-ya4-2 {
    opacity: 1;
    z-index: 2;
  }
}
.services__card-list-item--5 {
  position: relative;
  z-index: 1;
}
@media (any-hover: hover) {
  .services__card-list-item--5:hover .services__card-ya5-2 {
    opacity: 1;
    z-index: 2;
  }
}
.services__card-list-item--6 {
  position: relative;
  z-index: 1;
}
@media (any-hover: hover) {
  .services__card-list-item--6:hover .services__card-ya6-2 {
    opacity: 1;
    z-index: 2;
  }
}
.services__card-list-item--7 {
  position: relative;
  z-index: 1;
}
@media (any-hover: hover) {
  .services__card-list-item--7:hover .services__card-ya7-2 {
    opacity: 1;
    z-index: 2;
  }
}
.services__card-list-item--8 {
  position: relative;
  z-index: 1;
}
@media (any-hover: hover) {
  .services__card-list-item--8:hover .services__card-ya8-2 {
    opacity: 1;
    z-index: 2;
  }
}
.services__card-ya1-2, .services__card-ya2-2, .services__card-ya3-2, .services__card-ya4-2, .services__card-ya5-2, .services__card-ya6-2, .services__card-ya7-2, .services__card-ya8-2 {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  box-shadow: -6px 6px 14px 0 var(--fd-orange);
  box-shadow: 0px 0px 14px 8px var(--fd-orange);
  border-radius: 25px;
  z-index: -1;
  transition: opacity 0.3s ease-in-out;
}

@keyframes rocket-shaking {
  0% {
    transform: translate(0, 0) rotate(-45deg);
  }
  25% {
    transform: translate(-5px, -5px) rotate(-47deg);
  }
  50% {
    transform: translate(-10px, -10px) rotate(-45deg);
  }
  75% {
    transform: translate(-5px, -5px) rotate(-43deg);
  }
  100% {
    transform: translate(0, 0) rotate(-45deg);
  }
}
@keyframes fire-shaking {
  0% {
    opacity: 0.8;
    transform: translate(0, 0) rotate(-43deg) scale(0.3);
  }
  25% {
    opacity: 1;
    transform: translate(-5px, -5px) rotate(-41deg) scale(0.25);
  }
  50% {
    opacity: 0.7;
    transform: translate(-10px, -10px) rotate(-43deg) scale(0.3);
  }
  75% {
    opacity: 1;
    transform: translate(-5px, -5px) rotate(-41deg) scale(0.25);
  }
  100% {
    opacity: 0.9;
    transform: translate(0, 0) rotate(-43deg) scale(0.3);
  }
}
@keyframes tail-shaking {
  0% {
    transform: rotate(42deg) translateY(2.5px);
  }
  12% {
    transform: rotate(46deg) translateY(2.5px);
  }
  25% {
    transform: rotate(50deg) translateY(2.5px);
  }
  37% {
    transform: rotate(54deg) translateY(2.5px);
  }
  50% {
    transform: rotate(58deg) translateY(2.5px);
  }
  62% {
    transform: rotate(54deg) translateY(2.5px);
  }
  75% {
    transform: rotate(50deg) translateY(2.5px);
  }
  88% {
    transform: rotate(46deg) translateY(2.5px);
  }
  100% {
    transform: rotate(42deg) translateY(2.5px);
  }
}
@keyframes frst {
  25% {
    transform: rotate(-5deg) translateY(5px) scale(1.1);
  }
}
@keyframes scnd {
  50% {
    transform: rotate(3deg) translateY(5px) scale(1.1);
  }
}
@keyframes thrd {
  75% {
    transform: rotate(-10deg) translateY(5px) scale(1.1);
  }
}
@keyframes move {
  0% {
    transform-origin: bottom left;
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.cases__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 768px) {
  .cases__list {
    gap: 12px;
  }
}
.cases__list-item {
  grid-column: 6 span;
  height: 100%;
}
@media (max-width: 589px) {
  .cases__list-item {
    grid-column: 12 span;
  }
}
.cases__card {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 728px;
  perspective: 1000px;
  transform-style: preserve-3d;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .cases__card {
    min-height: 659px;
  }
}
@media (max-width: 1024px) {
  .cases__card {
    min-height: 590px;
  }
}
@media (max-width: 768px) {
  .cases__card {
    min-height: 553px;
  }
}
@media (max-width: 624px) {
  .cases__card {
    min-height: 545px;
  }
}
@media (max-width: 624px) {
  .cases__card {
    min-height: 620px;
  }
}
@media (max-width: 480px) {
  .cases__card {
    min-height: 540px;
  }
}
@media (max-width: 350px) {
  .cases__card {
    min-height: 530px;
  }
}
.cases__card-img {
  border-radius: 23px 23px 0 0;
}
@media (max-width: 768px) {
  .cases__card-img {
    border-radius: 23px 23px 0 0;
  }
}
.cases__card-svg {
  width: 100%;
  max-width: 46px;
  height: auto;
}
@media (max-width: 768px) {
  .cases__card-svg {
    max-width: 40px;
  }
}
@media (max-width: 576px) {
  .cases__card-svg {
    max-width: 46px;
  }
}
.cases__card-front, .cases__card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  backdrop-filter: blur(15px);
  background: rgb(39, 46, 57);
}
.cases__card-front-btn, .cases__card-back-btn {
  display: none;
  position: absolute;
  top: 30px;
  right: 30px;
}
@media (max-width: 1024px) {
  .cases__card-front-btn, .cases__card-back-btn {
    display: block;
  }
}
@media (max-width: 768px) {
  .cases__card-front-btn, .cases__card-back-btn {
    top: 15px;
    right: 15px;
  }
}
.cases__card-back {
  z-index: -1;
  opacity: 0;
}
@media (min-width: 1024px) and (any-hover: hover) {
  .cases__card:hover .cases__card-front {
    z-index: -1;
    opacity: 0;
    transition: transform 1s ease-in-out, opacity 0.2s ease-in-out;
  }
  .cases__card:hover .cases__card-back {
    z-index: 1;
    opacity: 1;
    transition: transform 1s ease-in-out, opacity 0.2s ease-in-out;
  }
}
.cases__card-content {
  padding: 24px 8.6%;
}
@media (max-width: 1200px) {
  .cases__card-content {
    padding: 7% 7%;
  }
}
.cases__card-content-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-height: 132px;
}
@media (max-width: 768px) {
  .cases__card-content-list {
    flex-direction: column;
    max-height: unset;
    min-height: 170px;
  }
}
@media (max-width: 589px) {
  .cases__card-content-list {
    flex-direction: row;
  }
}
.cases__card-content-list-item {
  position: relative;
  padding-left: 14px;
  width: 45%;
}
@media (max-width: 930px) {
  .cases__card-content-list-item {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .cases__card-content-list-item {
    width: 100%;
  }
}
@media (max-width: 589px) {
  .cases__card-content-list-item {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .cases__card-content-list-item {
    width: 100%;
  }
}
.cases__card-content-list-item ::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 9px;
  width: 5px;
  height: 5px;
  border-radius: 25px;
  background-color: var(--white);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cases__card-content-text {
  margin: 0;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
}
.cases__card-title {
  margin-bottom: 33px;
  font-weight: 600;
  font-size: clamp(1.563rem, 1.027rem + 2.679vw, 3.438rem);
  color: var(--fd-orange);
}
@media (max-width: 768px) {
  .cases__card-title {
    margin-bottom: 15px;
  }
}
.cases__card-title-descr {
  font-weight: 400;
  font-size: clamp(1.125rem, 1.018rem + 0.536vw, 1.5rem);
  letter-spacing: 0.05em;
  color: var(--white);
}
.cases__card-back {
  display: flex;
  flex-direction: column;
  padding: 10% 10%;
}
@media (max-width: 1200px) {
  .cases__card-back {
    padding: 7% 7%;
  }
}
@media (max-width: 930px) {
  .cases__card-back {
    padding: 7% 5%;
  }
}
@media (max-width: 768px) {
  .cases__card-back {
    justify-content: space-between;
  }
}
@media (max-width: 589px) {
  .cases__card-back {
    justify-content: space-around;
  }
}
.cases__card-back-descr {
  margin-bottom: 22px;
  font-weight: 400;
  font-size: clamp(1.125rem, 1.018rem + 0.536vw, 1.5rem);
  letter-spacing: 0.05em;
  color: var(--fd-pale-orange);
}
.cases__card-back-list-item:not(:last-child) {
  margin-bottom: 29px;
}
@media (max-width: 1024px) {
  .cases__card-back-list-item:not(:last-child) {
    margin-bottom: 15px;
  }
}
@media (max-width: 589px) {
  .cases__card-back-list-item:not(:last-child) {
    margin-bottom: 30px;
  }
}
.cases__card-back-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18.7px 60px;
  border: 1px solid var(--fd-pale-orange);
  border-radius: 25px;
}
@media (max-width: 1024px) {
  .cases__card-back-content {
    padding: 15px 39px;
  }
}
@media (max-width: 768px) {
  .cases__card-back-content {
    flex-direction: column;
    padding: 15px;
    border-radius: 25px;
  }
}
.cases__card-back-content--left {
  padding-right: 10px;
  width: calc(50% - 12px);
  font-weight: 500;
  font-size: clamp(1.5rem, 1.125rem + 1.875vw, 2.813rem);
  color: var(--fd-pale-orange);
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .cases__card-back-content--left {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
  }
}
.cases__card-back-content--small {
  font-weight: 400;
  font-size: clamp(1.125rem, 1.089rem + 0.179vw, 1.25rem);
  letter-spacing: 0.05em;
  color: #e7a779;
  text-transform: lowercase;
}
@media (max-width: 768px) {
  .cases__card-back-content--small {
    padding-left: 10px;
  }
}
.cases__card-back-content--right {
  font-family: var(--font-family);
  width: calc(50% - 12px);
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: var(--white);
}
@media (max-width: 768px) {
  .cases__card-back-content--right {
    width: 100%;
  }
}

.cases__card-front.active {
  z-index: -1;
  opacity: 0;
  transition: transform 1s ease-in-out, opacity 0.3s ease-in-out;
}

.cases__card-back.active {
  z-index: 1;
  opacity: 1;
  transition: transform 1s ease-in-out, opacity 0.3s ease-in-out;
}

.about__content {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 30px;
  padding: 5% 8.6%;
}
.about__title {
  grid-column: 12 span;
}
.about__list {
  grid-column: 6 span;
}
@media (max-width: 768px) {
  .about__list {
    grid-column: 12 span;
  }
}
.about__list-item {
  width: 85%;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .about__list-item {
    margin-bottom: 20px;
    width: 100%;
  }
}
.about__list-text {
  display: inline-block;
  margin: 0;
  font-weight: 500;
  font-size: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
  color: var(--white);
}
.contacts__content {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 7.1% 0;
  gap: 24px;
}
@media (max-width: 768px) {
  .contacts__content {
    padding: 7.1% 20px;
  }
}
@media (max-width: 1024px) {
  .contacts__title {
    grid-column: 1 span;
  }
}
@media (max-width: 768px) {
  .contacts__title {
    grid-column: 12 span;
  }
}
.contacts__list {
  grid-column: 4/5 span;
}
@media (max-width: 1024px) {
  .contacts__list {
    grid-column: 2/6 span;
  }
}
@media (max-width: 768px) {
  .contacts__list {
    grid-column: 1/7 span;
  }
}
@media (max-width: 624px) {
  .contacts__list {
    grid-column: 12 span;
  }
}
.contacts__list--social {
  grid-column: 9/4 span;
}
@media (max-width: 1024px) {
  .contacts__list--social {
    grid-column: 8/5 span;
  }
}
@media (max-width: 624px) {
  .contacts__list--social {
    grid-column: 12 span;
  }
}
.contacts__link {
  display: flex;
  margin-bottom: 70px;
}
@media (max-width: 768px) {
  .contacts__link {
    margin-bottom: 40px;
  }
}
.contacts__link-text {
  font-weight: 500;
  font-size: clamp(1.375rem, 1.125rem + 1.25vw, 2.25rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}
.contacts__link-text--location {
  padding-top: 8px;
  width: 70%;
  font-weight: 400;
  font-size: clamp(1.25rem, 1.179rem + 0.357vw, 1.5rem);
}
@media (max-width: 576px) {
  .contacts__link-text--location {
    width: 100%;
  }
}
.contacts__link-descr {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
  letter-spacing: 0.05em;
  color: var(--white);
}
.contacts__svg {
  align-self: flex-start;
  margin-top: 5px;
  margin-right: 15px;
  width: 100%;
  max-width: 40px;
  height: auto;
}
@media (max-width: 930px) {
  .contacts__svg {
    max-width: 30px;
  }
}
@media (max-width: 624px) {
  .contacts__svg {
    width: 20px;
  }
}
.contacts__descr {
  grid-column: 4/8 span;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}
@media (max-width: 1024px) {
  .contacts__descr {
    grid-column: 3/8 span;
  }
}
@media (max-width: 768px) {
  .contacts__descr {
    grid-column: 2/8 span;
  }
}
@media (max-width: 624px) {
  .contacts__descr {
    grid-column: 12 span;
  }
}
.contacts__descr--white {
  font-weight: 400;
  font-size: clamp(0.875rem, 0.804rem + 0.357vw, 1.125rem);
  color: var(--white);
}

.social-list__link {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(1.25rem, 1.179rem + 0.357vw, 1.5rem);
  letter-spacing: 0.05em;
  color: var(--light-color);
}
.social-list__svg {
  margin-right: 35px;
}
@media (max-width: 930px) {
  .social-list__svg {
    margin-right: 15px;
    width: 30px;
  }
}
@media (max-width: 624px) {
  .social-list__svg {
    width: 20px;
  }
}

.form {
  position: relative;
  margin-bottom: -250px;
  padding-bottom: 100px;
  z-index: 5;
}
.form__container {
  padding-top: 200px;
  padding-bottom: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
@media (max-width: 624px) {
  .form__container {
    padding-top: 100px;
  }
}
.form__title {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}
@media (max-width: 768px) {
  .form__title {
    grid-column: 1 span;
  }
}
@media (max-width: 400px) {
  .form__title {
    grid-column: 12 span;
    margin-bottom: 20px;
    writing-mode: unset;
    transform: unset;
  }
}
.form__form {
  grid-column: 4/7 span;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .form__form {
    grid-column: 3/9 span;
  }
}
@media (max-width: 400px) {
  .form__form {
    grid-column: 12 span;
  }
}
.form__label {
  width: 100%;
}
.form__label-checkbox {
  width: unset;
  font-weight: 400;
  font-size: clamp(0.75rem, 0.696rem + 0.268vw, 0.938rem);
  letter-spacing: 0.05em;
  color: var(--white);
}
.form__input {
  margin-bottom: 40px;
  padding: 30px 40px;
  border-radius: 25px;
  width: 100%;
  font-weight: 400;
  font-size: clamp(1.25rem, 1.071rem + 0.893vw, 1.875rem);
  letter-spacing: 0.05em;
  color: var(--fd-dark);
  border: 2px solid var(--white);
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
@media (max-width: 589px) {
  .form__input {
    margin-bottom: 20px;
    padding: 15px 20px;
    border-radius: 25px;
  }
}
.form__input:focus {
  outline: none;
}
@media (any-hover: hover) {
  .form__input:hover {
    border-color: var(--fd-orange);
  }
  .form__input:hover::placeholder {
    color: var(--fd-orange);
    transition: color 0.3s ease-in-out;
  }
}
.form__input:active {
  border: 2px dashed var(--fd-orange);
}
.form__input::placeholder {
  font-weight: 400;
  font-size: clamp(1.25rem, 1.071rem + 0.893vw, 1.875rem);
  letter-spacing: 0.05em;
  color: var(--fd-dark);
}
.form__btn {
  margin-bottom: 30px;
  padding: 30px;
  width: 100%;
  font-weight: 500;
  font-size: clamp(1rem, 1.214rem + 1.429vw, 2.5rem);
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--fd-graphite);
  background-color: var(--fd-orange);
  border: 2px solid var(--white);
  border-radius: 25px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
@media (max-width: 589px) {
  .form__btn {
    padding: 15px;
    border-radius: 25px;
  }
}
.form__btn:focus {
  outline: none;
}
@media (any-hover: hover) {
  .form__btn:hover {
    color: var(--fd-orange);
    background-color: var(--fd-graphite);
    border-color: var(--fd-orange);
  }
}
.form__btn:active {
  background-color: var(--orange);
  border-color: var(--white);
  color: var(--white);
}
.form__link {
  font-weight: 600;
  font-size: clamp(0.75rem, 0.696rem + 0.268vw, 0.938rem);
  letter-spacing: 0.05em;
  color: var(--white);
  text-decoration: underline;
}

.form__input.focus-visible {
  border: 2px dashed var(--fd-orange);
}

.form__btn.focus-visible {
  outline: 2px solid var(--fd-orange);
  outline-offset: 3px;
}

.footer {
  padding-top: 300px;
  background-image: image-set(url("../img/footer-bg.webp") 1x, url("../img/footer-bg_2x.webp") 2x);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: 100px;
  transition: all 0.3s linear;
}
@media (max-width: 624px) {
  .footer {
    padding-top: 150px;
  }
}
.footer__container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 624px) {
  .footer__container {
    flex-direction: column;
  }
}
@media (max-width: 624px) {
  .footer__list {
    margin-bottom: 40px;
  }
}
.footer__list-item:not(:last-child) {
  margin-bottom: 15px;
}
.footer__list-link {
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.05em;
  color: var(--white);
}
.footer__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
@media (max-width: 624px) {
  .footer__right {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.footer__logo {
  display: flex;
  margin-bottom: 30px;
}
.footer__logo > img {
  width: 100%;
  max-width: 140px;
  height: auto;
}
.footer__text {
  text-align: right;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
}
@media (max-width: 624px) {
  .footer__text {
    text-align: left;
  }
}
.footer__link {
  margin-bottom: 0;
  color: var(--fd-pale-orange);
}

.hero-section__container {
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .hero-section__container.hero-restaurant__container {
    padding-top: 180px;
  }
}
@media (max-width: 589px) {
  .hero-section__container {
    padding-top: 340px;
  }
  .hero-section__container.seo__container {
    padding-top: 500px;
  }
  .hero-section__container.hero-soprovozhdenie__container, .hero-section__container.hero-dentistry__container, .hero-section__container.hero-cyber-security__container {
    padding-top: 530px;
  }
  .hero-section__container.hero-audit__container,
  .hero-section__container .hero-corporation__container, .hero-section__container.hero-builders__container {
    padding-top: 430px;
  }
  .hero-section__container.hero-restaurant__container {
    padding-top: 280px;
  }
}
@media (max-width: 480px) {
  .hero-section__container.seo__container {
    padding-top: 410px;
  }
  .hero-section__container.hero-soprovozhdenie__container, .hero-section__container.hero-dentistry__container,
  .hero-section__container .hero-cyber-security__container {
    padding-top: 430px;
  }
  .hero-section__container.hero-restaurant__container {
    padding-top: 480px;
  }
}
@media (max-width: 400px) {
  .hero-section__container {
    padding-top: 240px;
  }
  .hero-section__container.seo__container, .hero-section__container.hero-soprovozhdenie__container, .hero-section__container.hero-corporation__container, .hero-section__container.hero-cyber-security__container {
    padding-top: 370px;
  }
}
@media (max-width: 350px) {
  .hero-section__container.hero-fitness__container {
    padding-top: 270px;
  }
  .hero-section__container.hero-cyber-security__container {
    padding-top: 320px;
  }
}
.hero-section__list {
  margin-bottom: 82px;
}
.hero-section__text {
  margin: 0;
  font-weight: 400;
  font-size: clamp(1.563rem, 1.366rem + 0.982vw, 2.25rem);
  letter-spacing: 0.05em;
  color: var(--fd-pale);
  position: relative;
  padding-left: 20px;
}
.hero-section__text::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 50%;
  width: 5px;
  height: 5px;
  border-radius: 25px;
  background-color: var(--fd-pale);
  transform: translateY(-50%);
}
.hero-section__paragraph {
  margin: 0;
  margin-bottom: 75px;
  width: 53%;
  font-weight: 400;
  font-size: clamp(1.563rem, 1.366rem + 0.982vw, 2.25rem);
  letter-spacing: 0.05em;
  color: var(--fd-pale);
}
.hero-section__paragraph--corporation {
  width: 53%;
}
.hero-section__paragraph--cyber-security {
  width: 53%;
}
.hero-section__paragraph--cyber-incident-management {
  width: 53%;
}
.hero-section__paragraph--app-security {
  width: 53%;
}
.hero-section__paragraph--protection {
  width: 53%;
}
.hero-section__paragraph--stress-testing {
  width: 53%;
}
.hero-section__paragraph--penetration-testing {
  width: 53%;
}
.hero-section__paragraph--grade {
  width: 53%;
}
.hero-section__paragraph--intelligence {
  width: 53%;
}
.hero-section__paragraph--smm {
  width: 50%;
}
@media (max-width: 576px) {
  .hero-section__paragraph {
    width: 100%;
  }
}
.hero-section__btn {
  align-self: flex-start;
  background-color: var(--fd-orange) !important;
  color: var(--dark) !important;
}
@media (max-width: 350px) {
  .hero-section__btn {
    padding: 17px 88px;
    justify-self: center;
  }
}
.hero-section__img {
  position: absolute;
  top: 8%;
  right: -33%;
  z-index: -1;
}
@media (max-width: 930px) {
  .hero-section__img {
    top: 22%;
  }
}
@media (max-width: 589px) {
  .hero-section__img {
    top: 8%;
    right: 0;
    transform: scale(1.3);
  }
}
.hero-section__img--seo {
  right: -22%;
  top: 12%;
}
@media (max-width: 1200px) {
  .hero-section__img--seo {
    right: -24%;
  }
}
@media (max-width: 930px) {
  .hero-section__img--seo {
    right: -30%;
  }
}
@media (max-width: 589px) {
  .hero-section__img--seo {
    top: 8%;
    right: 0;
  }
}
.hero-section__img--context {
  right: -24%;
  top: 0%;
}
@media (max-width: 930px) {
  .hero-section__img--context {
    top: 4%;
  }
}
@media (max-width: 624px) {
  .hero-section__img--context {
    top: 10%;
  }
}
@media (max-width: 589px) {
  .hero-section__img--context {
    top: 0%;
    right: 0;
  }
}
@media (max-width: 480px) {
  .hero-section__img--context {
    top: 5%;
  }
}
@media (max-width: 400px) {
  .hero-section__img--context {
    top: 0%;
  }
}
.hero-section__img--soprovozhdenie {
  right: -22%;
  top: 9%;
}
@media (max-width: 1024px) {
  .hero-section__img--soprovozhdenie {
    right: -27%;
    top: 4%;
  }
}
@media (max-width: 930px) {
  .hero-section__img--soprovozhdenie {
    right: -30%;
    top: 4%;
  }
}
@media (max-width: 624px) {
  .hero-section__img--soprovozhdenie {
    top: 8%;
  }
}
@media (max-width: 589px) {
  .hero-section__img--soprovozhdenie {
    transform: scale(1);
    right: -4%;
  }
}
@media (max-width: 480px) {
  .hero-section__img--soprovozhdenie {
    right: -5%;
  }
}
.hero-section__img--audit {
  right: -22%;
  top: 9%;
}
@media (max-width: 589px) {
  .hero-section__img--audit {
    transform: scale(1);
    right: 0%;
  }
}
@media (max-width: 480px) {
  .hero-section__img--audit {
    top: 17%;
  }
}
@media (max-width: 400px) {
  .hero-section__img--audit {
    top: 22%;
  }
}
@media (max-width: 350px) {
  .hero-section__img--audit {
    top: 23%;
  }
}
.hero-section__img--builders {
  right: -25%;
  top: 9%;
}
@media (max-width: 589px) {
  .hero-section__img--builders {
    top: 13%;
    right: 0%;
  }
}
@media (max-width: 480px) {
  .hero-section__img--builders {
    top: 16%;
  }
}
@media (max-width: 400px) {
  .hero-section__img--builders {
    top: 20%;
  }
}
.hero-section__img--dentistry {
  right: -26%;
}
@media (max-width: 624px) {
  .hero-section__img--dentistry {
    top: 25%;
  }
}
@media (max-width: 589px) {
  .hero-section__img--dentistry {
    top: 15%;
    right: 0;
  }
}
@media (max-width: 480px) {
  .hero-section__img--dentistry {
    top: 12%;
  }
}
@media (max-width: 400px) {
  .hero-section__img--dentistry {
    top: 19%;
  }
}
@media (max-width: 350px) {
  .hero-section__img--dentistry {
    top: 22%;
  }
}
.hero-section__img--fitness {
  right: -23%;
}
@media (max-width: 350px) {
  .hero-section__img--fitness {
    top: 12%;
  }
}
.hero-section__img--restaurant {
  right: -23%;
}
@media (max-width: 1024px) {
  .hero-section__img--restaurant {
    top: 2%;
    right: -33%;
  }
}
@media (max-width: 768px) {
  .hero-section__img--restaurant {
    top: 7%;
  }
}
@media (max-width: 624px) {
  .hero-section__img--restaurant {
    top: 10%;
  }
}
@media (max-width: 480px) {
  .hero-section__img--restaurant {
    right: -3%;
    top: 18%;
  }
}
@media (max-width: 400px) {
  .hero-section__img--restaurant {
    top: 23%;
  }
}
@media (max-width: 350px) {
  .hero-section__img--restaurant {
    top: 25%;
  }
}
.hero-section__img--shop {
  right: -24%;
}
.hero-section__img--corporation {
  right: -33%;
}
@media (max-width: 930px) {
  .hero-section__img--corporation {
    top: 12%;
  }
}
@media (max-width: 768px) {
  .hero-section__img--corporation {
    top: 4%;
  }
}
@media (max-width: 624px) {
  .hero-section__img--corporation {
    top: 6%;
  }
}
@media (max-width: 589px) {
  .hero-section__img--corporation {
    right: -4%;
    top: 3%;
    transform: scale(1.1);
  }
}
@media (max-width: 480px) {
  .hero-section__img--corporation {
    top: 6%;
  }
}
@media (max-width: 400px) {
  .hero-section__img--corporation {
    top: 15%;
  }
}
@media (max-width: 350px) {
  .hero-section__img--corporation {
    top: 17%;
  }
}
.hero-section__img--cyber-security {
  right: -20%;
}
@media (max-width: 1200px) {
  .hero-section__img--cyber-security {
    right: -26%;
  }
}
@media (max-width: 1024px) {
  .hero-section__img--cyber-security {
    right: -30%;
  }
}
@media (max-width: 930px) {
  .hero-section__img--cyber-security {
    top: -2%;
  }
}
@media (max-width: 768px) {
  .hero-section__img--cyber-security {
    top: 5%;
  }
}
@media (max-width: 589px) {
  .hero-section__img--cyber-security {
    top: 10%;
    right: -3%;
    transform: scale(1);
  }
}
@media (max-width: 576px) {
  .hero-section__img--cyber-security {
    top: 12%;
  }
}
.hero-section__img--cyber-incident-management {
  right: -18%;
}
.hero-section__img--app-security {
  right: -24%;
}
.hero-section__img--protection {
  right: -14%;
}
.hero-section__img--stress-testing {
  right: -19%;
}
.hero-section__img--penetration-testing {
  right: -19%;
}
.hero-section__img--grade {
  right: -17%;
}
.hero-section__img--intelligence {
  right: -20%;
}
.hero-section__img--car-buisnes {
  right: -12%;
  transform: scale(1);
}
@media (max-width: 589px) {
  .hero-section__img--car-buisnes {
    transform: scale(1);
  }
}
.hero-section__img--smm {
  right: -15%;
}
@media (max-width: 1200px) {
  .hero-section__img--smm {
    right: -19%;
  }
}
@media (max-width: 1024px) {
  .hero-section__img--smm {
    right: -27%;
  }
}
.hero-section__img--target {
  right: -14%;
}
.hero-section__img--tilda {
  top: -10%;
  right: -20%;
}
@media (max-width: 589px) {
  .hero-section__img--tilda {
    top: 10%;
  }
}
.hero-section__img--promo {
  top: -10%;
  right: -8%;
}
@media (max-width: 589px) {
  .hero-section__img--promo {
    right: 0%;
  }
}

.hero-create__text {
  position: relative;
  padding-left: 20px;
}
.hero-create__text::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 50%;
  width: 5px;
  height: 5px;
  border-radius: 25px;
  background-color: var(--fd-pale);
  transform: translateY(-50%);
}

.hero-builders__title,
.hero-dentistry__title,
.hero-restaurant__title,
.hero-shop__title,
.hero-corporation__title,
.hero-cyber-security__title,
.hero-fitness__title,
.hero-cyber-incident-management__title,
.hero-app-security__title,
.hero-protection__title,
.hero-stress-testing__title,
.hero-grade__title,
.hero-intelligence__title,
.hero-smm__title,
.hero-car-buisnes__title,
.hero-tilda__title {
  width: 68%;
}

.text {
  position: relative;
  z-index: 2;
}
.text__container {
  padding-top: 200px;
}
@media (max-width: 480px) {
  .text__container {
    padding-top: 100px;
  }
}
.text__text {
  margin: 0;
  font-weight: 300;
  font-size: clamp(1.375rem, 0.214rem + 3.095vw, 3rem);
  color: var(--white);
}
.text__text:not(:last-child) {
  margin-bottom: 20px;
}
.text__link {
  font-weight: 300;
  font-size: clamp(1.375rem, 0.214rem + 3.095vw, 3rem);
  color: var(--white);
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .text__link:hover {
    color: var(--fd-orange);
  }
}

.getting__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 1024px) {
  .getting__list {
    gap: 14px;
  }
}
.getting__list-item {
  grid-column: 4 span;
}
@media (max-width: 624px) {
  .getting__list-item:nth-child(1), .getting__list-item:nth-child(2) {
    grid-column: 6 span;
  }
  .getting__list-item:nth-child(3) {
    grid-column: 12 span;
  }
  .getting__list-item:nth-child(3) > .getting__card {
    flex-direction: row !important;
  }
}
.getting__list-item--big {
  grid-column: 6 span;
}
.getting__card {
  padding: 8% 6%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  height: 100%;
}
@media (max-width: 1024px) {
  .getting__card {
    gap: 14px;
  }
}
@media (max-width: 768px) {
  .getting__card:not(.getting__list-item--big > .getting__card) {
    flex-direction: column;
    text-align: center;
  }
  .getting__card .getting-target .getting__list-item--big {
    grid-column: 12 span !important;
  }
}
@media (max-width: 589px) {
  .getting__card {
    flex-direction: column;
    text-align: center;
  }
}
.getting__card-svg {
  width: 100%;
  max-width: 95px;
  height: auto;
}
@media (max-width: 768px) {
  .getting__card-svg {
    max-width: 60px;
  }
}
.getting__card-text {
  margin: 0;
  font-weight: 300;
  font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
  color: var(--basic-white);
}

@media (max-width: 624px) {
  .getting-corporation .getting__list-item--big:not(:nth-child(-n+2)),
  .getting-shop .getting__list-item--big:not(:nth-child(-n+2)) {
    grid-column: 12 span;
  }
  .getting-corporation .getting__list-item--big:not(:nth-child(-n+2)) > .getting__card,
  .getting-shop .getting__list-item--big:not(:nth-child(-n+2)) > .getting__card {
    flex-direction: row;
  }
  .getting-target .getting__list-item--big {
    grid-column: 6 span !important;
  }
  .getting-target .getting__list-item--big > .getting__card {
    flex-direction: column !important;
  }
}
@media (max-width: 480px) {
  .getting-soprovozhdenie .getting__list-item--big {
    grid-column: 12 span;
  }
  .getting-soprovozhdenie .getting__list-item--big > .getting__card {
    flex-direction: row;
  }
}
@media (max-width: 400px) {
  .getting-corporation .getting__list-item--big,
  .getting-shop .getting__list-item--big {
    grid-column: 12 span;
  }
  .getting-corporation .getting__list-item--big > .getting__card,
  .getting-shop .getting__list-item--big > .getting__card {
    flex-direction: row;
  }
}
.getting-smm .getting__list-item {
  grid-column: 4 span;
}
@media (max-width: 930px) {
  .getting-smm .getting__list-item {
    grid-column: 6 span;
  }
}
@media (max-width: 624px) {
  .getting-smm .getting__list-item > .getting__card {
    flex-direction: column !important;
  }
}
@media (max-width: 400px) {
  .getting-smm .getting__list-item {
    grid-column: 12 span;
  }
  .getting-smm .getting__list-item > .getting__card {
    flex-direction: row !important;
  }
}

@media (max-width: 400px) {
  .getting.getting-target > .getting__container .getting__list-item--big {
    grid-column: 12 span !important;
  }
  .getting.getting-target > .getting__container .getting__list-item--big > .getting__card {
    flex-direction: row !important;
  }
}
.price__card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 31px 40px;
}
@media (max-width: 768px) {
  .price__card {
    padding: 15px 20px;
  }
}
.price__card picture {
  display: flex;
  flex-direction: column;
}
@media (max-width: 685px) {
  .price__card picture {
    order: 9;
  }
}
@media (any-hover: hover) {
  .price__card:hover .price__card-img--1 {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  .price__card:hover .price__card-img--2 {
    animation: wiggle-shake 0.3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
}
.price__card-top {
  width: 100%;
  margin-bottom: 59px;
}
@media (max-width: 768px) {
  .price__card-top {
    margin-bottom: 30px;
  }
}
@media (max-width: 400px) {
  .price__card-top {
    margin-bottom: 60px;
  }
}
.price__card-title {
  font-weight: 500;
  font-size: clamp(2.125rem, 0.786rem + 3.571vw, 4rem);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--fd-orange);
}
.price__card-text {
  margin: 0;
  width: 70%;
  font-weight: 400;
  font-size: clamp(1rem, 0.643rem + 0.952vw, 1.5rem);
  letter-spacing: 0.05em;
  color: var(--fd-pale);
}
.price__card-img {
  z-index: -1;
  width: 100%;
  height: auto;
}
.price__card-img--1 {
  max-width: 384px;
  position: absolute;
  right: 20px;
  top: -83px;
}
@media (max-width: 1200px) {
  .price__card-img--1 {
    max-width: 284px;
    right: 40px;
    top: -93px;
  }
}
@media (max-width: 930px) {
  .price__card-img--1 {
    max-width: 230px;
  }
}
@media (max-width: 768px) {
  .price__card-img--1 {
    max-width: 190px;
  }
}
@media (max-width: 576px) {
  .price__card-img--1 {
    right: 15px;
    top: -99px;
  }
}
@media (max-width: 400px) {
  .price__card-img--1 {
    max-width: 120px;
    right: 15px;
    top: -50px;
  }
}
.price__card-img--2 {
  max-width: 260px;
  margin-right: 40px;
}
@media (max-width: 1200px) {
  .price__card-img--2 {
    position: relative;
    max-width: 200px;
  }
}
@media (max-width: 930px) {
  .price__card-img--2 {
    margin-right: 20px;
    max-width: 150px;
    order: 100;
  }
}
@media (max-width: 768px) {
  .price__card-img--2 {
    max-width: 100px;
  }
}
@media (max-width: 768px) {
  .price__card-img--2 {
    position: absolute;
    right: 10%;
    bottom: 10%;
    margin-right: 0;
  }
}
@media (max-width: 400px) {
  .price__card-img--2 {
    max-width: 100px;
    bottom: 45%;
  }
}
.price__card-sub-title {
  margin-bottom: 3px;
  font-weight: 600;
  font-size: clamp(2.125rem, 1.5rem + 1.667vw, 3rem);
  color: var(--fd-orange);
}
.price__card-sub-title--small {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 24px;
  color: var(--fd-orange);
}
.price__card-offer {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: clamp(2rem, 0.571rem + 3.81vw, 4rem);
  color: var(--fd-pale);
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, -1px, -1px) rotate(0.5deg);
  }
  20%, 80% {
    transform: translate3d(2px, 2px, 2px) rotate(-0.5deg);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, -2px, -2px) rotate(0.5deg);
  }
  40%, 60% {
    transform: translate3d(2px, 2px, 2px);
  }
}
@keyframes wiggle-shake {
  0% {
    transform: translate(2px, 0) rotate(0.5deg);
  }
  50% {
    transform: translate(-2px, 0) rotate(-0.5deg);
  }
  100% {
    transform: translate(2px, 0) rotate(0.5deg);
  }
}
.why__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 768px) {
  .why__list {
    gap: 12px;
  }
}
.why__list-item {
  position: relative;
  grid-column: 6 span;
}
@media (max-width: 576px) {
  .why__list-item {
    grid-column: 12 span;
  }
}
.why__list-item--big {
  grid-column: 12 span;
  z-index: -1;
}
.why__link {
  margin: 0;
  font-weight: 300;
  font-size: clamp(1.125rem, 0.857rem + 0.714vw, 1.5rem);
  color: var(--basic-white);
  text-decoration: underline;
}
.why__card {
  padding: 40px 50px;
  height: 100%;
}
@media (max-width: 768px) {
  .why__card {
    padding: 20px 25px;
  }
}
.why__card-title {
  position: relative;
  margin: 0;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: clamp(1.5rem, 0.429rem + 2.857vw, 3rem);
  line-height: 110%;
  letter-spacing: 0.05em;
  color: var(--fd-orange);
  z-index: 2;
}
@media (max-width: 576px) {
  .why__card-title {
    margin-bottom: 20px;
  }
}
.why__card-text {
  margin: 0;
  font-weight: 300;
  font-size: clamp(1.125rem, 0.857rem + 0.714vw, 1.5rem);
  color: var(--basic-white);
}
.why__card-text:not(:last-child) {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .why-seo .why__list-item:nth-child(1), .why-seo .why__list-item:nth-child(2) {
    grid-column: 12 span;
  }
}

.proposal__content {
  padding: 80px 0;
}
@media (max-width: 576px) {
  .proposal__content {
    padding: 40px 0;
    border: none;
    backdrop-filter: unset;
    background-color: transparent;
  }
}
@media (max-width: 400px) {
  .proposal__content {
    padding: 20px 0;
  }
}
.proposal__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 24px;
  row-gap: 60px;
}
@media (max-width: 480px) {
  .proposal__list {
    row-gap: 40px;
  }
}
.proposal__list-item {
  grid-column: 2/10 span;
}
@media (max-width: 576px) {
  .proposal__list-item {
    grid-column: 12 span;
    border: 2px solid var(--fd-orange);
    border-radius: 25px;
  }
}
.proposal__list-title {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: clamp(1.125rem, 0.321rem + 2.143vw, 2.25rem);
  letter-spacing: 0.05em;
  color: var(--fd-pale-orange);
}
.proposal__list-descr {
  font-weight: 300;
  font-size: clamp(1rem, 0.643rem + 0.952vw, 1.5rem);
  color: var(--basic-white);
}
.proposal__card {
  display: flex;
  padding: 68px 68px;
  border: none;
  gap: 24px;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-position: right bottom;
}
@media (max-width: 768px) {
  .proposal__card {
    flex-direction: column;
    padding: 34px;
  }
}
@media (max-width: 589px) {
  .proposal__card {
    row-gap: 20px;
  }
}
@media (max-width: 400px) {
  .proposal__card {
    padding: 15px;
  }
}
.proposal__card--1 {
  background-image: image-set(url("/img/proposal-1.webp") 1x, url("/img/proposal-1_2x.webp") 2x);
}
.proposal__card--2 {
  background-image: image-set(url("/img/proposal-2.webp") 1x, url("/img/proposal-2_2x.webp") 2x);
}
.proposal__card--3 {
  background-image: image-set(url("/img/proposal-3.webp") 1x, url("/img/proposal-3_2x.webp") 2x);
}
.proposal__card--4 {
  background-image: image-set(url("/img/proposal-4.webp") 1x, url("/img/proposal-4_2x.webp") 2x);
}
.proposal__card-lite {
  padding: 80px;
  flex-direction: column;
  gap: unset;
  justify-content: unset;
}
@media (max-width: 1024px) {
  .proposal__card-lite {
    padding: 60px;
  }
}
@media (max-width: 768px) {
  .proposal__card-lite {
    padding: 40px;
  }
}
@media (max-width: 480px) {
  .proposal__card-lite {
    padding: 40px 20px;
  }
}
.proposal__card-lite .proposal__card-list-text {
  width: 55%;
}
@media (max-width: 1024px) {
  .proposal__card-lite .proposal__card-list-text {
    width: 65%;
  }
}
@media (max-width: 480px) {
  .proposal__card-lite .proposal__card-list-text {
    width: 100%;
  }
}
.proposal__card-lite--1 {
  background-image: image-set(url("/img/proposal-lite-1.webp") 1x, url("/img/proposal-lite-1_2x.webp") 2x);
}
.proposal__card-lite--2 {
  background-image: image-set(url("/img/proposal-lite-2.webp") 1x, url("/img/proposal-lite-2_2x.webp") 2x);
}
.proposal__card-lite--3 {
  background-image: image-set(url("/img/proposal-lite-3.webp") 1x, url("/img/proposal-lite-3_2x.webp") 2x);
}
.proposal__card-lite--4 {
  background-image: image-set(url("/img/proposal-lite-4.webp") 1x, url("/img/proposal-lite-4_2x.webp") 2x);
}
.proposal__card-title {
  margin: 0;
  margin-bottom: 47px;
  font-size: clamp(1.75rem, -0.259rem + 5.357vw, 4.563rem);
  line-height: 104%;
}
@media (max-width: 768px) {
  .proposal__card-title {
    margin-bottom: 23px;
  }
}
.proposal__card-text {
  margin: 0;
  margin-bottom: 60px;
  font-weight: 300;
  font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
  color: var(--basic-white);
}
.proposal__card-left {
  width: 70%;
}
@media (max-width: 768px) {
  .proposal__card-left {
    display: contents;
  }
}
.proposal__card-right {
  width: 30%;
}
@media (max-width: 768px) {
  .proposal__card-right {
    display: contents;
  }
}
@media (max-width: 768px) {
  .proposal__card-btn {
    order: 9;
  }
}
.proposal__card-list {
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .proposal__card-list {
    margin-bottom: 30px;
  }
}
.proposal__card-list-item:not(:last-child) {
  margin-bottom: 15px;
}
.proposal__card-list-item--small:not(:last-child) {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .proposal__card-list-item--small {
    margin-bottom: 30px;
  }
}
.proposal__card-list-text {
  margin: 0;
  font-weight: 300;
  font-size: 27px;
  font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
  color: var(--basic-white);
}
.proposal__card-list-text b {
  color: var(--fd-orange);
}

@media (max-width: 576px) {
  .proposal-corporation .proposal__list {
    row-gap: 40px;
  }
}

.cases-big__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 930px) {
  .cases-big__list {
    gap: 12px;
  }
}
.cases-big__list-item {
  border-radius: 25px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .cases-big__list-item {
    border-radius: 25px;
  }
}
.cases-big__list-item--horizontal {
  grid-column: 12 span;
}
.cases-big__list-item--normal {
  grid-column: 6 span;
}
@media (max-width: 930px) {
  .cases-big__list-item--normal:nth-child(6), .cases-big__list-item--normal:nth-child(7) {
    grid-column: 12 span;
  }
}
@media (max-width: 576px) {
  .cases-big__list-item--normal {
    grid-column: 12 span;
  }
}
.cases-big__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1200px) {
  .cases-big__card {
    padding-bottom: 0;
  }
}
.cases-big__card--pb0 {
  padding-bottom: 0;
}
.cases-big__card--1 {
  background-image: image-set(url("/img/cases-bg-1.webp") 1x, url("/img/cases-bg-1_2x.webp") 2x);
}
.cases-big__card--2 {
  background-image: image-set(url("/img/cases-bg-2.webp") 1x, url("/img/cases-bg-2_2x.webp") 2x);
}
.cases-big__card--3 {
  background-image: image-set(url("/img/cases-bg-3.webp") 1x, url("/img/cases-bg-3_2x.webp") 2x);
}
.cases-big__card--4 {
  background-image: image-set(url("/img/cases-bg-4.webp") 1x, url("/img/cases-bg-4_2x.webp") 2x);
}
.cases-big__card--5 {
  background-image: image-set(url("/img/cases-bg-5.webp") 1x, url("/img/cases-bg-5_2x.webp") 2x);
}
.cases-big__card--6 {
  background-image: image-set(url("/img/cases-bg-6.webp") 1x, url("/img/cases-bg-6_2x.webp") 2x);
}
.cases-big__card--7 {
  background-image: image-set(url("/img/cases-bg-7.webp") 1x, url("/img/cases-bg-7_2x.webp") 2x);
}
.cases-big__card--8 {
  background-image: image-set(url("/img/cases-bg-8.webp") 1x, url("/img/cases-bg-8_2x.webp") 2x);
}
.cases-big__card--9 {
  background-image: image-set(url("/img/cases-bg-9.webp") 1x, url("/img/cases-bg-9_2x.webp") 2x);
}
.cases-big__card-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: auto;
  padding: 60px;
  width: 100%;
}
@media (max-width: 1024px) {
  .cases-big__card-top {
    padding: 40px;
  }
}
@media (max-width: 624px) {
  .cases-big__card-top {
    padding: 30px 25px;
  }
}
.cases-big__card-title {
  font-weight: 600;
  align-self: center;
  font-size: clamp(1.25rem, 0.536rem + 1.905vw, 2.25rem);
  color: var(--fd-orange);
}
.cases-big__card-subtitle {
  font-weight: 400;
  font-size: clamp(1.5rem, 0.429rem + 2.857vw, 3rem);
  color: var(--fd-orange);
}
.cases-big__card-list {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.cases-big__card-list-item:not(:last-child) {
  margin-bottom: 10px;
}
.cases-big__card-text {
  padding: 10px 20px;
  border: 1px solid var(--fd-pale-orange);
  border-radius: 30px;
  background-color: var(--fd-graphite);
}
@media (max-width: 624px) {
  .cases-big__card-text {
    padding: 10px 15px;
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .cases-big__card-text {
    padding: 7px 10px;
  }
}
.cases-big__card-img {
  vertical-align: top;
}

.cases-big__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 930px) {
  .cases-big__list {
    gap: 12px;
  }
}
.cases-big__list-item {
  border-radius: 25px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .cases-big__list-item {
    border-radius: 25px;
  }
}
.cases-big__list-item--horizontal {
  grid-column: 12 span;
}
.cases-big__list-item--normal {
  grid-column: 6 span;
}
@media (max-width: 930px) {
  .cases-big__list-item--normal:nth-child(6), .cases-big__list-item--normal:nth-child(7) {
    grid-column: 12 span;
  }
}
@media (max-width: 576px) {
  .cases-big__list-item--normal {
    grid-column: 12 span;
  }
}
.cases-big__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1200px) {
  .cases-big__card {
    padding-bottom: 0;
  }
}
.cases-big__card--pb0 {
  padding-bottom: 0;
}
.cases-big__card--1 {
  background-image: image-set(url("/img/cases-bg-1.webp") 1x, url("/img/cases-bg-1_2x.webp") 2x);
}
.cases-big__card--2 {
  background-image: image-set(url("/img/cases-bg-2.webp") 1x, url("/img/cases-bg-2_2x.webp") 2x);
}
.cases-big__card--3 {
  background-image: image-set(url("/img/cases-bg-3.webp") 1x, url("/img/cases-bg-3_2x.webp") 2x);
}
.cases-big__card--4 {
  background-image: image-set(url("/img/cases-bg-4.webp") 1x, url("/img/cases-bg-4_2x.webp") 2x);
}
.cases-big__card--5 {
  background-image: image-set(url("/img/cases-bg-5.webp") 1x, url("/img/cases-bg-5_2x.webp") 2x);
}
.cases-big__card--6 {
  background-image: image-set(url("/img/cases-bg-6.webp") 1x, url("/img/cases-bg-6_2x.webp") 2x);
}
.cases-big__card--7 {
  background-image: image-set(url("/img/cases-bg-7.webp") 1x, url("/img/cases-bg-7_2x.webp") 2x);
}
.cases-big__card--8 {
  background-image: image-set(url("/img/cases-bg-8.webp") 1x, url("/img/cases-bg-8_2x.webp") 2x);
}
.cases-big__card--9 {
  background-image: image-set(url("/img/cases-bg-9.webp") 1x, url("/img/cases-bg-9_2x.webp") 2x);
}
.cases-big__card-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: auto;
  padding: 60px;
  width: 100%;
}
@media (max-width: 1024px) {
  .cases-big__card-top {
    padding: 40px;
  }
}
@media (max-width: 624px) {
  .cases-big__card-top {
    padding: 30px 25px;
  }
}
.cases-big__card-title {
  font-weight: 600;
  align-self: center;
  font-size: clamp(1.25rem, 0.536rem + 1.905vw, 2.25rem);
  color: var(--fd-orange);
}
.cases-big__card-subtitle {
  font-weight: 400;
  font-size: clamp(1.5rem, 0.429rem + 2.857vw, 3rem);
  color: var(--fd-orange);
}
.cases-big__card-list {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.cases-big__card-list-item:not(:last-child) {
  margin-bottom: 10px;
}
.cases-big__card-text {
  padding: 10px 20px;
  border: 1px solid var(--fd-pale-orange);
  border-radius: 30px;
  background-color: var(--fd-graphite);
}
@media (max-width: 624px) {
  .cases-big__card-text {
    padding: 10px 15px;
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .cases-big__card-text {
    padding: 7px 10px;
  }
}
.cases-big__card-img {
  vertical-align: top;
}

.services-cyber__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 768px) {
  .services-cyber__list {
    gap: 12px;
  }
}
.services-cyber__list-item {
  grid-column: 6 span;
}
@media (max-width: 576px) {
  .services-cyber__list-item {
    grid-column: 12 span;
  }
}
.services-cyber__card {
  display: flex;
  flex-direction: column;
  padding: 10%;
  height: 100%;
}
.services-cyber__card-img {
  margin-bottom: 40px;
}
.services-cyber__card-title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: clamp(1.5rem, 0.964rem + 1.429vw, 2.25rem);
  color: var(--fd-orange);
}
.services-cyber__card-text {
  margin-bottom: 60px;
  font-weight: 300;
  font-size: clamp(1rem, 0.821rem + 0.476vw, 1.25rem);
  color: var(--basic-white);
}
.services-cyber__card-btn {
  margin-top: auto;
  text-align: center;
}
@media (max-width: 768px) {
  .services-cyber__card-btn {
    padding: 17px 60px;
    align-self: center;
  }
}
@media (max-width: 576px) {
  .services-cyber__card-btn {
    padding: 17px 100px;
  }
}
@media (max-width: 350px) {
  .services-cyber__card-btn {
    padding: 17px 60px;
  }
}
.services-cyber picture {
  display: flex;
  justify-content: center;
}

.why-cyber__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 768px) {
  .why-cyber__list {
    gap: 12px;
  }
}
.why-cyber__list-item {
  grid-column: 6 span;
}
@media (max-width: 576px) {
  .why-cyber__list-item {
    grid-column: 12 span;
  }
}
.why-cyber__list-item--big {
  grid-column: 12 span;
}
.why-cyber__card {
  padding: 9%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
}
@media (max-width: 576px) {
  .why-cyber__card {
    padding: 5%;
  }
}
.why-cyber__card--1 {
  background-image: image-set(url("/img/why-cyber-1.webp") 1x, url("/img/why-cyber-1_2x.webp") 2x);
}
.why-cyber__card--2 {
  background-image: image-set(url("/img/why-cyber-2.webp") 1x, url("/img/why-cyber-2_2x.webp") 2x);
}
.why-cyber__card--3 {
  background-image: image-set(url("/img/why-cyber-3.webp") 1x, url("/img/why-cyber-3_2x.webp") 2x);
}
@media (max-width: 880px) {
  .why-cyber__card--3 {
    background-size: cover;
  }
}
.why-cyber__card-title {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: clamp(7.188rem, 2.054rem + 13.69vw, 14.375rem);
  color: var(--fd-pale-orange);
  text-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.55);
}
@media (max-width: 576px) {
  .why-cyber__card-title {
    margin-bottom: 20px;
  }
}
.why-cyber__card-descr {
  font-weight: 500;
  font-size: clamp(1.5rem, 0.429rem + 2.857vw, 3rem);
  line-height: 110%;
  letter-spacing: 0.05em;
  color: var(--fd-pale);
}

.partners-cyber {
  padding-top: 100px;
  padding-bottom: 100px;
}
.partners-cyber__title {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  color: var(--fd-orange);
}
.partners-cyber__slide {
  position: relative;
  display: flex;
  justify-content: center;
}
@media (any-hover: hover) {
  .partners-cyber__slide:hover .partners-cyber__slide-img {
    opacity: 0;
  }
  .partners-cyber__slide:hover .partners-cyber__slide-img--active {
    opacity: 1;
    z-index: 2;
  }
}
.partners-cyber__slide-img {
  transition: opacity 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .partners-cyber__slide-img {
    opacity: 0;
  }
}
.partners-cyber__slide-img--active {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}
@media (max-width: 1024px) {
  .partners-cyber__slide-img--active {
    opacity: 1;
  }
}

.text-blur {
  backdrop-filter: blur(3px);
}
.text-blur__title {
  margin: 0;
  margin-bottom: 60px;
  font-weight: 500;
  font-size: clamp(2rem, 1.268rem + 3.661vw, 4.563rem);
  line-height: 104%;
  color: var(--fd-orange);
}
@media (max-width: 624px) {
  .text-blur__title {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.text-blur__text {
  margin: 0;
  margin-bottom: 50px;
  font-weight: 400;
  font-size: clamp(1rem, 0.286rem + 1.905vw, 2rem);
  color: var(--basic-white);
  width: 100%;
}
.text-blur__list-item:not(:last-child) {
  margin-bottom: 40px;
}
@media (max-width: 624px) {
  .text-blur__list-item:not(:last-child) {
    margin-bottom: 60px;
  }
}
.text-blur__card {
  display: flex;
  gap: 24px;
}
@media (max-width: 624px) {
  .text-blur__card {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.text-blur__card-title {
  margin: 0;
  font-weight: 500;
  font-size: clamp(1.5rem, 0.964rem + 1.429vw, 2.25rem);
  line-height: 111%;
  color: var(--fd-orange);
  width: calc(35% - 12px);
}
@media (max-width: 624px) {
  .text-blur__card-title {
    width: 100%;
  }
}
.text-blur__card-text {
  margin: 0;
  font-weight: 400;
  font-size: clamp(1rem, 0.286rem + 1.905vw, 2rem);
  color: var(--basic-white);
  width: calc(65% - 12px);
}
@media (max-width: 624px) {
  .text-blur__card-text {
    width: 100%;
  }
}
.text-blur__link {
  font-weight: 300;
  font-size: clamp(1rem, 0.286rem + 1.905vw, 2rem);
  color: var(--white);
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .text-blur__link:hover {
    color: var(--fd-orange);
  }
}

.text-card__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 624px) {
  .text-card__list {
    gap: 12px;
  }
}
.text-card__list-item {
  grid-column: 6 span;
}
@media (max-width: 589px) {
  .text-card__list-item {
    grid-column: 12 span;
  }
}
.text-card__card {
  padding: 10% 11%;
  height: 100%;
}
@media (max-width: 589px) {
  .text-card__card {
    padding: 7% 9%;
  }
}
.text-card__card-title {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 36px;
  font-size: clamp(1.5rem, 0.964rem + 1.429vw, 2.25rem);
  line-height: 111%;
  color: var(--fd-orange);
}
@media (max-width: 589px) {
  .text-card__card-title {
    margin-bottom: 20px;
    font-size: clamp(1.75rem, 1.393rem + 0.952vw, 2.25rem);
  }
}
@media (max-width: 350px) {
  .text-card__card-title {
    font-size: clamp(1.5rem, 0.964rem + 1.429vw, 2.25rem);
  }
}
.text-card__card-text {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  color: var(--basic-white);
}

@media (max-width: 350px) {
  .text-card-cyber-incident-management .text-card__title {
    font-size: 33px;
  }
}

.text-img-card__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 768px) {
  .text-img-card__list {
    gap: 12px;
  }
}
.text-img-card__list-item {
  grid-column: 4 span;
}
@media (max-width: 1024px) {
  .text-img-card__list-item {
    grid-column: 12 span;
  }
  .text-img-card__list-item:nth-child(1) {
    grid-column: 6 span;
    order: 1;
  }
  .text-img-card__list-item:nth-child(3) {
    grid-column: 7/6 span;
    order: 2;
  }
  .text-img-card__list-item:nth-child(2) {
    order: 3;
  }
}
@media (max-width: 400px) {
  .text-img-card__list-item {
    grid-column: 12 span;
  }
  .text-img-card__list-item:nth-child(1) {
    grid-column: 12 span;
    order: 1;
  }
  .text-img-card__list-item:nth-child(3) {
    grid-column: 12 span;
    order: 3;
  }
  .text-img-card__list-item:nth-child(2) {
    order: 2;
  }
}
.text-img-card__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11%;
  height: 100%;
}
@media (max-width: 1024px) {
  .text-img-card__card {
    padding: 7%;
  }
}
.text-img-card__card-svg {
  margin-bottom: 24px;
}
@media (max-width: 576px) {
  .text-img-card__card-svg {
    margin-bottom: 12px;
  }
}
.text-img-card__card-text {
  margin: 0;
  font-weight: 300;
  font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
  text-align: center;
  color: var(--basic-white);
}

.text-lr {
  backdrop-filter: blur(3px) !important;
}
.text-lr__container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.text-lr__title {
  margin: 0;
  margin-bottom: 36px;
  font-weight: 500;
  font-size: clamp(2.375rem, 0.679rem + 4.524vw, 4.75rem);
  line-height: 104%;
  color: var(--fd-orange);
  width: 100%;
}
@media (max-width: 624px) {
  .text-lr__title {
    margin-bottom: 18px;
    padding: 0 10px;
  }
}
.text-lr__left, .text-lr__right {
  width: calc(50% - 12px);
}
@media (max-width: 624px) {
  .text-lr__left, .text-lr__right {
    padding: 0 10px;
    width: 100%;
  }
}
.text-lr__left {
  padding-right: 40px;
}
.text-lr__left--only {
  padding-right: 0;
  width: 75%;
}
@media (max-width: 624px) {
  .text-lr__left {
    padding-right: 10px;
  }
}
.text-lr__text {
  font-weight: 400;
  font-size: clamp(1.125rem, 0.5rem + 1.667vw, 2rem);
  color: var(--basic-white);
}

.documents {
  backdrop-filter: blur(3px);
}
.documents__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.documents__title {
  margin: 0;
  margin-bottom: 60px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: clamp(2rem, 1.268rem + 3.661vw, 4.563rem);
  line-height: 104%;
  color: var(--fd-orange);
}
@media (max-width: 768px) {
  .documents__title {
    margin-bottom: 30px;
  }
}
.documents__splide {
  width: 80%;
}

.documents .splide__arrow {
  height: 58px;
  width: 58px;
  background-color: transparent;
}
@media (max-width: 768px) {
  .documents .splide__arrow {
    height: 29px;
    width: 29px;
  }
}
.documents .splide__arrow svg {
  width: 58px;
  height: 58px;
  fill: var(--fd-orange);
}
@media (max-width: 768px) {
  .documents .splide__arrow svg {
    height: 29px;
    width: 29px;
  }
}

.documents .splide__arrow--next {
  right: -7%;
}
@media (max-width: 400px) {
  .documents .splide__arrow--next {
    right: -10%;
  }
}
@media (max-width: 350px) {
  .documents .splide__arrow--next {
    right: -15%;
  }
}

.documents .splide__arrow--prev {
  left: -7%;
}
@media (max-width: 400px) {
  .documents .splide__arrow--prev {
    left: -10%;
  }
}
@media (max-width: 350px) {
  .documents .splide__arrow--prev {
    left: -15%;
  }
}

.documents .splide__pagination {
  bottom: -15%;
}

@media (max-width: 576px) {
  .documents .splide__pagination__page {
    width: 4px;
    height: 4px;
  }
}

.documents .splide__pagination__page.is-active {
  background-color: var(--fd-orange);
}

.documents .splide__pagination__page {
  background-color: #8A4200;
}

.work-stages-img__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
@media (max-width: 768px) {
  .work-stages-img__list {
    gap: 12px;
  }
}
@media (max-width: 576px) {
  .work-stages-img__list {
    gap: 24px;
  }
}
.work-stages-img__list-item {
  grid-column: 6 span;
}
@media (max-width: 576px) {
  .work-stages-img__list-item {
    grid-column: 12 span;
  }
}
.work-stages-img__card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.work-stages-img__card-content {
  padding: 40px 60px;
}
@media (max-width: 1024px) {
  .work-stages-img__card-content {
    padding: 30px 30px;
  }
}
@media (max-width: 589px) {
  .work-stages-img__card-content {
    padding: 20px 15px;
  }
}
@media (max-width: 576px) {
  .work-stages-img__card-content {
    padding: 20px 30px;
  }
}
@media (max-width: 350px) {
  .work-stages-img__card-content {
    padding: 15px 15px;
  }
}
.work-stages-img__card-title {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: clamp(1.5rem, 0.964rem + 1.429vw, 2.25rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}
.work-stages-img__card-text {
  position: relative;
  margin: 0;
  margin-bottom: 8px;
  padding-left: 15px;
  font-weight: 300;
  font-size: clamp(1rem, 0.821rem + 0.476vw, 1.25rem);
  color: var(--basic-white);
}
.work-stages-img__card-text::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 10px;
  width: 5px;
  height: 5px;
  border-radius: 25px;
  background-color: var(--white);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.not-found__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 0;
}
.not-found__img {
  width: 100%;
  max-width: 1110px;
  height: auto;
}
.not-found__text {
  font-weight: 400;
  font-size: clamp(1.25rem, 0.536rem + 1.905vw, 2.25rem);
  letter-spacing: 0.05em;
  text-align: center;
  width: 70%;
  color: var(--fd-pale);
}

.not-404 .footer {
  padding-top: 0;
}
.not-404 .footer__container {
  padding-top: 80px;
  padding-bottom: 40px;
}

.popup {
  position: relative;
  z-index: 999999;
}
.popup--visible {
  display: block;
}
.popup__overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  background-image: image-set(url("/img/popup-request-bg.webp") 1x, url("/img/popup-request-bg_2x.webp") 2x);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: left top;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  perspective: 800px;
}
.popup__overlay--visible {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.popup__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: image-set(url("/img/popup-request-rock.webp") 1x, url("/img/popup-request-rock_2x.webp") 2x);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left bottom;
}
.popup__modal {
  display: flex;
  display: none;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 35px 50px;
  padding-right: 320px;
  backdrop-filter: blur(15px);
  background: rgba(39, 46, 57, 0.35);
  border: 1px solid var(--fd-dark-orange);
  border-radius: 25px;
  transform-style: preserve-3d;
}
@media (max-width: 768px) {
  .popup__modal {
    padding: 35px;
    border-radius: 25px;
  }
}
.popup__modal--visible {
  display: flex;
}
.popup__modal-close {
  position: absolute;
  right: 35px;
  top: 35px;
}
@media (max-width: 768px) {
  .popup__modal-close {
    right: 25px;
    top: 25px;
  }
}
@media (max-width: 768px) {
  .popup__modal-close svg {
    width: 15px;
    height: 15px;
  }
}
@media (any-hover: hover) {
  .popup__modal-close:hover svg path {
    fill: var(--fd-orange);
    transition: fill 0.3s ease-in-out;
  }
}
.popup__modal-content {
  display: flex;
  flex-direction: column;
}
.popup__modal-title {
  margin-bottom: 36px;
  max-width: 450px;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}
@media (max-width: 768px) {
  .popup__modal-title {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.05em;
    color: var(--fd-orange);
  }
}
.popup__modal-img {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
  vertical-align: bottom;
  width: 100%;
  max-width: 398px;
  height: auto;
}
@media (max-width: 768px) {
  .popup__modal-img {
    max-width: 122px;
    right: unset;
    left: -37px;
    top: -61px;
    animation-name: fox-bounce;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: -2s;
  }
}
@media (max-width: 350px) {
  .popup__modal-img {
    left: -20px;
  }
}
.popup__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
}
@media (max-width: 768px) {
  .popup__form {
    max-width: 232px;
  }
}
.popup__form-label {
  margin-bottom: 20px;
  width: 100%;
}
.popup__form-label:nth-child(2) {
  margin-bottom: 30px;
}
.popup__form-label-checkbox {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: var(--basic-white);
}
.popup__form-input {
  padding: 20px 30px;
  width: 100%;
  border: 1px solid var(--fd-orange);
  border-radius: 25px;
}
@media (max-width: 768px) {
  .popup__form-input {
    padding: 10px 15px;
  }
}
.popup__form-input::placeholder {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: var(--fd-dark);
}
@media (max-width: 768px) {
  .popup__form-input::placeholder {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.05em;
    color: var(--fd-dark);
  }
}
.popup__form-input:focus {
  outline: none;
}
@media (any-hover: hover) {
  .popup__form-input:hover {
    border-color: var(--fd-orange);
  }
  .popup__form-input:hover::placeholder {
    color: var(--fd-orange);
    transition: color 0.3s ease-in-out;
  }
}
.popup__form-input:active {
  border: 2px dashed var(--fd-orange);
}
.popup__form-link {
  font-weight: 500;
  text-decoration: underline;
  color: var(--basic-white);
}
.popup__form-btn {
  padding: 10px 8px;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.05em;
  border: 2px solid var(--fd-orange);
  border-radius: 25px;
  color: var(--fd-orange);
  background: var(--fd-graphite);
}
@media (max-width: 768px) {
  .popup__form-btn {
    font-size: 14px;
    box-shadow: -6px 6px 14px 0 rgba(0, 0, 0, 0.75);
    border: 1px solid var(--fd-orange);
  }
}
.popup__form-btn:focus {
  outline: none;
}
@media (any-hover: hover) {
  .popup__form-btn:hover {
    color: var(--fd-orange);
    background-color: var(--fd-graphite);
    border-color: var(--fd-orange);
  }
}
.popup__form-btn:active {
  background-color: var(--orange);
  border-color: var(--white);
  color: var(--white);
}

.popup .custom-check {
  padding-left: 40px;
}

.popup .custom-check__checkbox {
  position: absolute;
  margin-top: -6px;
  margin-left: -38px;
  width: 31px;
  height: 28px;
  border-radius: 30px;
  background-color: var(--white);
  transition: border-color 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .popup .custom-check__checkbox {
    margin-top: 3px;
    margin-left: -37px;
    width: 27px;
    height: 23px;
  }
}

.popup .custom-check__checkbox::before {
  content: "";
  position: absolute;
  height: 80%;
  width: 40%;
  margin: auto;
  top: 40%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  border-right: 4px solid var(--fd-graphite);
  border-bottom: 4px solid var(--fd-graphite);
  opacity: 0;
}
@media (max-width: 768px) {
  .popup .custom-check__checkbox::before {
    border-right: 3px solid var(--fd-graphite);
    border-bottom: 3px solid var(--fd-graphite);
  }
}

@media (max-width: 768px) {
  .popup__form-label-checkbox {
    font-weight: 400;
    font-size: 12px;
  }
}

.popup__form-input.focus-visible {
  border: 1px dashed var(--fd-orange);
}

.popup__form-btn.focus-visible {
  outline: 1px solid var(--fd-orange);
  outline-offset: 3px;
}

.gototop {
  position: fixed;
  display: block;
  bottom: 7%;
  right: 50px;
  width: 60px;
  height: 106px;
  margin-right: -30px;
  outline: none;
  opacity: 0;
  transition: opacity 0.3s ease, margin-right 0.5s ease-out;
  cursor: pointer;
  z-index: 999;
}
@media (any-hover: hover) {
  .gototop:hover {
    animation: shake-uptotop 0.5s ease infinite;
  }
}
@media (max-width: 1440px) {
  .gototop {
    right: 30px;
  }
}
@media (max-width: 768px) {
  .gototop {
    right: 15px;
    width: 40px;
    height: 71px;
  }
}
.gototop--visible {
  transition: opacity 0.3s ease, margin-right 0.5s ease-out;
  margin-right: 0px;
  opacity: 1;
}
.gototop__img {
  width: 100%;
  max-width: 60px;
  height: auto;
}
@media (max-width: 768px) {
  .gototop__img {
    max-width: 40px;
  }
}
.gototop-fire__img {
  position: absolute;
  right: 49%;
  bottom: 0%;
  transform: translateX(49%);
  z-index: -1;
  opacity: 0;
}
@media (max-width: 768px) {
  .gototop-fire__img {
    max-width: 17px;
    right: 47%;
  }
}
.gototop-fire__img--visible {
  animation: takeoff 0.3s linear infinite;
}

@keyframes takeoff {
  0% {
    opacity: 1;
    transform: translateY(0%) translateX(49%) rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: translateY(50%) translateX(49%) rotate(-1deg);
  }
  100% {
    opacity: 1;
    transform: translateY(80%) translateX(49%) rotate(1deg);
  }
}
@keyframes shake-uptotop {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.accordion__intro {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}
@media (min-width: 1024px) and (any-hover: hover) {
  .accordion__intro:hover {
    letter-spacing: 2px;
  }
}
.accordion__icon {
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease-in-out;
}
.accordion__icon::after {
  position: absolute;
  content: "";
  right: 50%;
  top: 13px;
  height: 3px;
  width: 30px;
  background-color: var(--fd-orange);
  border-radius: 10px;
  transform: translate(50%);
}
.accordion__icon::before {
  position: absolute;
  content: "";
  right: 50%;
  top: 0;
  width: 3px;
  height: 30px;
  background-color: var(--fd-orange);
  border-radius: 10px;
  transform: translate(50%);
}
.accordion__content {
  overflow: hidden;
  transition: all 0.25s ease-out;
  opacity: 0;
  display: none;
}
.accordion.accordion__active .accordion__content {
  opacity: 1;
  display: flex;
  transition: all 0.3s ease-in-out;
}
.accordion.accordion__active .accordion__content .faq__list-card-cantent-text {
  cursor: pointer;
}

.accordion__icon.icon-active {
  perspective: 100px;
  transform: rotate(225deg);
}

.faq__list {
  display: flex;
  flex-direction: column;
}
.faq__list-item:not(:last-child) {
  margin-bottom: 20px;
}
.faq__list-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  backdrop-filter: blur(3px);
  border: 1px solid var(--fd-orange);
  border-radius: 25px;
}
.faq__list-card-intro-title {
  margin: 0;
  color: #fff;
  font-size: clamp(1.25rem, 1.107rem + 0.714vw, 1.75rem);
  font-weight: 300;
  transition: all 0.3s ease-in-out;
}
.faq__list-card-content {
  display: none;
}
.faq__list-card-content-text {
  margin: 0;
  margin-bottom: 20px;
  padding: 0 20px 20px;
  line-height: 1;
  font-weight: 300;
  font-size: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
  color: var(--white);
  transition: all 0.3s ease-in-out;
}
.faq__list-card-link {
  font-weight: 300;
  font-size: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
  text-decoration: underline;
  color: var(--white);
}

p {
  color: #f1f1f1;
}

.odds__content {
  padding: 80px 0;
}
@media (max-width: 576px) {
  .odds__content {
    padding: 40px 0;
    border: none;
    backdrop-filter: unset;
    background-color: transparent;
  }
}
@media (max-width: 400px) {
  .odds__content {
    padding: 20px 0;
  }
}
.odds__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 24px;
  row-gap: 60px;
}
@media (max-width: 480px) {
  .odds__list {
    row-gap: 40px;
  }
}
.odds__list-item {
  grid-column: 2/10 span;
}
@media (max-width: 576px) {
  .odds__list-item {
    grid-column: 12 span;
    border: 2px solid var(--fd-orange);
    border-radius: 25px;
  }
}
.odds__list-title {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: clamp(1.125rem, 0.321rem + 2.143vw, 2.25rem);
  letter-spacing: 0.05em;
  color: var(--fd-pale-orange);
}
.odds__list-descr {
  font-weight: 300;
  font-size: clamp(1rem, 0.643rem + 0.952vw, 1.5rem);
  color: var(--basic-white);
}
.odds__link {
  margin: 0;
  margin-bottom: 60px;
  font-weight: 300;
  font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
  color: var(--basic-white);
  text-decoration: underline;
}
.odds__card {
  display: flex;
  padding: 68px 68px;
  border: none;
  gap: 24px;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-position: right bottom;
}
@media (max-width: 768px) {
  .odds__card {
    flex-direction: column;
    padding: 34px;
  }
}
@media (max-width: 589px) {
  .odds__card {
    row-gap: 20px;
  }
}
@media (max-width: 400px) {
  .odds__card {
    padding: 15px;
  }
}
.odds__card-lite {
  padding: 80px;
  flex-direction: column;
  gap: unset;
  justify-content: unset;
}
@media (max-width: 1024px) {
  .odds__card-lite {
    padding: 60px;
  }
}
@media (max-width: 768px) {
  .odds__card-lite {
    padding: 40px;
  }
}
@media (max-width: 480px) {
  .odds__card-lite {
    padding: 40px 20px;
  }
}
.odds__card-lite .odds__card-list-text {
  width: 55%;
}
@media (max-width: 1024px) {
  .odds__card-lite .odds__card-list-text {
    width: 65%;
  }
}
@media (max-width: 480px) {
  .odds__card-lite .odds__card-list-text {
    width: 100%;
  }
}
.odds__card-lite--1 {
  background-image: image-set(url("/img/proposal-lite-1.webp") 1x, url("/img/proposal-lite-1_2x.webp") 2x);
}
.odds__card-lite--2 {
  background-image: image-set(url("/img/proposal-lite-2.webp") 1x, url("/img/proposal-lite-2_2x.webp") 2x);
}
.odds__card-lite--3 {
  background-image: image-set(url("/img/proposal-lite-3.webp") 1x, url("/img/proposal-lite-3_2x.webp") 2x);
}
.odds__card-lite--4 {
  background-image: image-set(url("/img/proposal-lite-4.webp") 1x, url("/img/proposal-lite-4_2x.webp") 2x);
}
.odds__card-lite--5 {
  background-image: image-set(url("/img/proposal-lite-5.webp") 1x, url("/img/proposal-lite-5_2x.webp") 2x);
}
.odds__card-lite--6 {
  background-image: image-set(url("/img/proposal-lite-6.webp") 1x, url("/img/proposal-lite-6_2x.webp") 2x);
}
.odds__card-lite--7 {
  background-image: image-set(url("/img/proposal-lite-7.webp") 1x, url("/img/proposal-lite-7_2x.webp") 2x);
}
.odds__card-lite--8 {
  background-image: image-set(url("/img/proposal-lite-8.webp") 1x, url("/img/proposal-lite-8_2x.webp") 2x);
}
.odds__card-title {
  margin-bottom: 47px;
  font-size: clamp(1.75rem, -0.259rem + 5.357vw, 4.563rem);
  line-height: 104%;
}
@media (max-width: 768px) {
  .odds__card-title {
    margin-bottom: 23px;
  }
}
.odds__card-text {
  margin: 0;
  margin-bottom: 60px;
  font-weight: 300;
  font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
  color: var(--basic-white);
}
.odds__card-left {
  width: 70%;
}
@media (max-width: 768px) {
  .odds__card-left {
    display: contents;
  }
}
.odds__card-right {
  width: 30%;
}
@media (max-width: 768px) {
  .odds__card-right {
    display: contents;
  }
}
@media (max-width: 768px) {
  .odds__card-btn {
    order: 9;
  }
}
.odds__card-list {
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .odds__card-list {
    margin-bottom: 30px;
  }
}
.odds__card-list-item:not(:last-child) {
  margin-bottom: 15px;
}
.odds__card-list-item--small:not(:last-child) {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .odds__card-list-item--small {
    margin-bottom: 30px;
  }
}
.odds__card-list-text {
  margin: 0;
  font-weight: 300;
  font-size: 27px;
  font-size: clamp(1.125rem, 0.723rem + 1.071vw, 1.688rem);
  color: var(--basic-white);
}
.odds__card-list-link {
  color: var(--white);
  text-decoration: underline;
}

@media (max-width: 480px) {
  .dictionary {
    padding-top: 50px;
  }
}
.dictionary__article {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 60px;
  background: rgba(39, 46, 57, 0.45);
  backdrop-filter: blur(3px);
  border-radius: 25px;
  z-index: 1;
}
@media (max-width: 768px) {
  .dictionary__article {
    padding: 15px;
    border-radius: 25px;
  }
}
.dictionary__article-img {
  position: absolute;
  z-index: -1;
  right: -12%;
  top: -11%;
  width: 100%;
  max-width: 564px;
  height: auto;
  animation-name: fox-bounce;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: -2s;
}
@media (max-width: 768px) {
  .dictionary__article-img {
    top: -7%;
    max-width: 282px;
  }
}
.dictionary__article-title {
  margin: 0;
  font-weight: 400;
  font-size: clamp(2.5rem, 1.25rem + 6.25vw, 6.875rem);
  letter-spacing: 0.05em;
  color: var(--fd-pale);
}
.dictionary__article-title-descr {
  display: inline-block;
  padding-top: 10px;
  font-weight: 400;
  font-size: clamp(1.75rem, 1.107rem + 3.214vw, 4rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}
.dictionary__article-descr {
  margin: 0;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: clamp(1.375rem, 1.196rem + 0.893vw, 2rem);
  color: var(--basic-white);
}
.dictionary__article-descr b {
  color: var(--fd-orange);
}
.dictionary__article-subtitle {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: clamp(1.5rem, 1.214rem + 1.429vw, 2.5rem);
  letter-spacing: 0.05em;
  color: var(--fd-orange);
}
.dictionary__article-cite {
  position: relative;
  margin: 0 40px;
  margin-bottom: 40px;
  font-style: italic;
  font-weight: 400;
  font-size: clamp(1.375rem, 1.196rem + 0.893vw, 2rem);
  color: var(--fd-ocre);
  z-index: 1;
}
.dictionary__article-cite::after {
  content: "";
  position: absolute;
  top: 0;
  left: -5%;
  width: 47px;
  height: 38px;
  background: image-set(url("/img/cite.webp") 1x, url("/img/cite_2x.webp") 2x);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
@media (max-width: 768px) {
  .dictionary__article-cite::after {
    width: 24px;
    height: 19px;
    left: -9%;
  }
}
.dictionary__article-svg {
  padding-top: 20px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1040px;
  height: auto;
}
.dictionary__link {
  font-style: italic;
  font-weight: 400;
  font-size: clamp(1.375rem, 1.196rem + 0.893vw, 2rem);
  color: var(--fd-ocre);
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .dictionary__link:hover {
    color: var(--fd-orange);
  }
}

@keyframes fox-bounce {
  0% {
    transform: translateY(10px) translateX(10px) rotate(-5deg);
  }
  25% {
    transform: translateY(-10px) translateX(-10px) rotate(-10deg);
  }
  50% {
    transform: translateY(10px) translateX(10px) rotate(-20deg);
  }
  75% {
    transform: translateY(-10px) translateX(-10px) rotate(-10deg);
  }
  100% {
    transform: translateY(10px) translateX(10px) rotate(-5deg);
  }
}