.popup {
  position: relative;
  // background-image: image-set(url('/img/popup-request-bg.webp') 1x,
  // url('/img/popup-request-bg_2x.webp') 2x);
  // background-repeat: repeat-y;
  // background-size: cover;
  // background-position: top left;
  z-index: 999999;


  &--visible {
    display: block;
  }

  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    background-image: image-set(url('/img/popup-request-bg.webp') 1x,
        url('/img/popup-request-bg_2x.webp') 2x);
    background-repeat: repeat-x;
    background-size: cover;
    background-position: left top;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    perspective: 800px;

    &--visible {
      opacity: 1;
      visibility: visible;
      transition: all .3s ease-in-out;
    }
  }

  &__wrap {
    // position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: image-set(url('/img/popup-request-rock.webp') 1x,
        url('/img/popup-request-rock_2x.webp') 2x);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left bottom;
  }

  &__modal {
    display: flex;
    display: none;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 35px 50px;
    padding-right: 320px;
    backdrop-filter: blur(15px);
    background: rgba(39, 46, 57, 0.35);
    border: 1px solid var(--fd-dark-orange);
    border-radius: 25px;
    transform-style: preserve-3d;

    @include small-tablet {
      padding: 35px;
      border-radius: 25px;
    }


    &--visible {
      display: flex;
    }

    &-close {
      position: absolute;
      right: 35px;
      top: 35px;

      @include small-tablet {
        right: 25px;
        top: 25px;
      }

      & svg {

        @include small-tablet {
          width: 15px;
          height: 15px;
        }
      }

      @include hover {

        & svg path {
          fill: var(--fd-orange);
          transition: fill .3s ease-in-out;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
    }

    &-title {
      margin-bottom: 36px;
      max-width: 450px;
      font-weight: 500;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: 0.05em;
      color: var(--fd-orange);

      @include small-tablet {
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.05em;
        color: var(--fd-orange);
      }
    }

    &-img {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: -1;
      vertical-align: bottom;
      width: 100%;
      max-width: 398px;
      height: auto;

      @include small-tablet {
        max-width: 122px;
        right: unset;
        left: -37px;
        top: -61px;
        animation-name: fox-bounce;
        animation-duration: 20s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: -2s;
      }

      @include last {
        left: -20px;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 450px;

    @include small-tablet {
      max-width: 232px;
    }

    &-label {
      margin-bottom: 20px;
      width: 100%;

      &:nth-child(2) {
        margin-bottom: 30px;
      }

      &-checkbox {
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.05em;
        color: var(--basic-white);
      }
    }

    &-input {
      padding: 20px 30px;
      width: 100%;
      border: 1px solid var(--fd-orange);
      border-radius: 25px;

      @include small-tablet {
        padding: 10px 15px;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.05em;
        color: var(--fd-dark);

        @include small-tablet {
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 0.05em;
          color: var(--fd-dark);
        }
      }

      &:focus {
        outline: none;
      }

      @include hover {
        border-color: var(--fd-orange);

        &::placeholder {
          color: var(--fd-orange);
          transition: color .3s ease-in-out;

        }
      }

      &:active {
        border: 2px dashed var(--fd-orange);
      }
    }

    &-link {
      font-weight: 500;
      text-decoration: underline;
      color: var(--basic-white);
    }

    &-btn {
      padding: 10px 8px;
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.05em;
      border: 2px solid var(--fd-orange);
      border-radius: 25px;
      color: var(--fd-orange);
      background: var(--fd-graphite);

      @include small-tablet {
        font-size: 14px;
        box-shadow: -6px 6px 14px 0 rgba(0, 0, 0, 0.75);
        border: 1px solid var(--fd-orange);
      }

      &:focus {
        outline: none;
      }

      @include hover {
        color: var(--fd-orange);
        background-color: var(--fd-graphite);
        border-color: var(--fd-orange);
      }

      &:active {
        background-color: var(--orange);
        border-color: var(--white);
        color: var(--white);
      }
    }
  }
}

.popup .custom-check {
  padding-left: 40px;
}

.popup .custom-check__checkbox {
  position: absolute;
  margin-top: -6px;
  margin-left: -38px;
  width: 31px;
  height: 28px;
  border-radius: 30px;
  background-color: var(--white);
  transition: border-color 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  @include small-tablet {
    margin-top: 3px;
    margin-left: -37px;
    width: 27px;
    height: 23px;
  }
}

.popup .custom-check__checkbox::before {
  content: "";
  position: absolute;
  height: 80%;
  width: 40%;
  margin: auto;
  top: 40%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  border-right: 4px solid var(--fd-graphite);
  border-bottom: 4px solid var(--fd-graphite);
  opacity: 0;

  @include small-tablet {
    border-right: 3px solid var(--fd-graphite);
    border-bottom: 3px solid var(--fd-graphite);
  }
}

.popup__form-label-checkbox {


  @include small-tablet {
    font-weight: 400;
    font-size: 12px;
  }
}

.popup__form-input.focus-visible {
  border: 1px dashed var(--fd-orange);
}

.popup__form-btn.focus-visible {
  outline: 1px solid var(--fd-orange);
  outline-offset: 3px;
}
