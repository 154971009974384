// .btn {
//   position: relative;
//   --r: 50px;
//   --b: 1px;
//   padding: 17px 105px;
//   background: var(--gradient);
//   -webkit-background-clip: text;
//   background-clip: text;
//   -webkit-text-fill-color: transparent;
//   color: transparent;
//   border-radius: var(--r);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   z-index: 20;
//   text-decoration: none;

//   &::after {
//     content: "";
//     position: absolute;
//     z-index: 1;
//     inset: 0;
//     border: var(--b) solid transparent;
//     border-radius: var(--r);
//     background: inherit;
//     background-origin: border-box;
//     background-clip: border-box;
//     -webkit-mask:
//       var(--gradient) padding-box,
//       var(--gradient);
//     -webkit-mask-composite: xor;
//     mask-composite: exclude;
//     -webkit-mask-repeat: no-repeat;
//   }


//   &::before {
//     content: "";
//     position: absolute;
//     inset: 1px;
//     border-radius: 25px;
//     background: var(--fd-graphite);
//     z-index: -1;
//   }

//   & span {
//     position: relative;
//     display: block;
//     z-index: 25;
//     // background: var(--gradient);
//     // -webkit-background-clip: text;
//     // -webkit-text-fill-color: transparent;
//   }
// }
.btn {
  position: relative;
  padding: 17px 105px;
  border-radius: 25px;
  border: 1px solid var(--fd-orange);
  color: var(--fd-orange);
  transition: color .3s ease-in-out, background-color .3s ease-in-out, box-shadow .3s ease-in-out;
  // background-image: url('/img/planet-bg.jpg');
  // box-shadow: -1px 1px 19px 4px rgba(255,151,55,1);
  background-color: var(--fd-orange);
  color: var(--dark);


  @include small-tablet {
    border-radius: 25px;
  }

  @include hover {
    box-shadow: -1px 1px 19px 4px rgba(255,151,55,1);
    background-color: var(--fd-orange);
    color: var(--dark);
  }
}
